<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;">
    <div class="page-loader" *ngIf="loadershow">
        <img src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px" alt="">
    </div>

    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="padding-left:0px;">
        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10" style="padding-left:0px;">
            <app-page-header i18n-pageTitle="@@contacts_title" pageTitle="{{'quick_reply.title' | translate}}"
                EngTitle="quickreply"></app-page-header>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 plusButtons" style="padding-right: 0%;">
            <button id="trial_usersAddBtn" [ngClass]="addbutton==true ? 'btnDisable': 'commonButton'"
                (click)="addQuickReplyButton()" class="customButtonStyle commonButton">
                <em class="fa fa-plus"></em>
            </button>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;text-align: right;margin-bottom: 3%">
            <form class="search">
                <div class="search__wrapper">
                    <input id="trial_userSerch" type="text" [(ngModel)]="search"
                        (keyup)="searchQuickReplyUsers(($event.target['value']))" name=""
                        placeholder="{{'common.search_for' | translate}}" class="search__field "
                        name="add_new_variant_question">
                    <button id="trial_userSerchSub" type="submit" class="fa fa-search search__icon"></button>
                </div>
            </form>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px 0px 10px 0px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive" style="padding:0px;">
                <table aria-labelledby="contactList1" mat-table matSort class="contactTable"
                    [dataSource]="quickReplyList" style="overflow: hidden;">
                    <ng-container matColumnDef="Quick Reply">
                        <th id="title" mat-header-cell *matHeaderCellDef i18n="@@matTab_name"> {{'common.quick_reply' |
                            translate}}
                        </th>
                        <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.title}}">
                            {{element.title ? element.title : ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Added By">
                        <th id="creator_name" mat-header-cell *matHeaderCellDef i18n="@@matTab_userIdentifier">
                            {{'common.added_by' | translate}}</th>
                        <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.creator_name}}">
                            {{element.creator_name ? element.creator_name : ''}} </td>

                    </ng-container>

                    <ng-container matColumnDef="Last Modified">
                        <th id="lastmodified" mat-header-cell *matHeaderCellDef i18n="@@matTab_lastSeen">
                            {{'common.last_modified' |
                            translate}}
                        </th>
                        <td mat-cell style="cursor: pointer;" *matCellDef="let element" title="{{element.modified_on}}">
                            <span style="cursor:default;" title="{{element.modified_on}}">
                                {{element.created_on | date:dateFormat3}}</span>
                        </td>

                    </ng-container>
                    <ng-container matColumnDef="Status">
                        <th id="status" mat-header-cell *matHeaderCellDef i18n="@@matTab_lastSeen">{{'common.status' |
                            translate}}</th>
                        <td mat-cell *matCellDef="let element" style="cursor:default;">
                            <div  *ngIf="element.status === true">
                                <ui-switch size="small" style="margin-left: 10px;" class="uiSwitch" switchColor="white" defaultBoColor="#cfcfcf" [(ngModel)]="element.status" (change)="onSwitchChanged($event, element)">
                                </ui-switch>
                            </div>

                            <div  *ngIf="element.status === false">
                                <ui-switch size="small" style="margin-left: 10px;" class="uiSwitch" switchColor="white" defaultBoColor="#cfcfcf" [(ngModel)]="element.status" (change)="onSwitchChanged($event, element)">
                                </ui-switch>
                            </div>

                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{'highlight': selecteUser._id == row._id}" (click)="showQuickReplyDetails(row)" (keydown)="showQuickReplyDetails(row)"></tr>
                </table>
            </div>

            <div class="row row-bottom" style="text-align:center;" *ngIf="( !quickReplyList?.length) && !loadershow">
                <p class="no-msg" i18n="@@noRecordsfound">{{'common.no_records_found' | translate}}</p>
            </div>
            <app-pagination [collectionSize]="trialUsersTotal" [noPerPage]="itemsPerPage"  [(page)]="pagelist" [maxSize]="3"
                (pageChange)="pageChange($event)"></app-pagination>
        </div>

    </div>
    <div *ngIf="isOpen" class="col-lg-4 col-md-4 col-sm-4 col-xs-4 rightBar"
        [ngStyle]="{'display':checkWindowSize() ? 'none':'', 'width': (leftMargin !=='270px') ? '27%':''}"
        style="overflow-y:scroll;height: 93vh">
        <div style="text-align:justify;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="display:inline-flex;">
                <h4 class="col-lg-8 col-md-8 col-sm-8 col-xs-8 viewHeading" *ngIf="action == 'View'"
                    style="cursor:context-menu;">{{actionHeader}}</h4>
                <h4 class="col-lg-9 col-md-9 col-sm-9 col-xs-9" *ngIf="action !== 'View'" style="cursor:context-menu;">
                    {{action}}{{actionHeader}}</h4>
                <div *ngIf="action == 'View'" class="col-lg-3 col-md-3 col-sm-3 col-xs-3 actionButton"
                    style="float:right;padding:0px;top: 20px;">
                    <button id="quickreplyActionBtnLs"
                        class="commonButton customButtonStyle dropdown-toggle lang-dropdown"
                        style="width:100%;text-align:center;padding: 6px;display: inline-flex;font-size: 12px;font-family: 'WorkSansMedium', serif; height:30px;"
                        type="button" data-toggle="dropdown">
                        {{'common.ACTIONS' | translate}} &nbsp;&nbsp;<em class="fas fa-chevron-down"
                            style="margin-top: 4%;"></em>
                    </button>
                    <ul class="dropdown-menu">
                        <li style="width: 100%;" class="actionMenuStyle" (click)="update()" (keydown)="update()">
                            {{'common.edit' | translate}}
                        </li>
                        <li style="width: 100%;" class="actionMenuStyle" (click)="openConfirmationDialog(element)" (keydown)="openConfirmationDialog(element)">
                            {{'common.delete' | translate}}
                        </li>
                    </ul>
                </div>
            </div>
            <form class="form-body" [formGroup]="quickreplyForm" style="padding-bottom: 7% !important;">
                <div class="form-group" style="padding-bottom: 3%;">
                    <span style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;"> {{'common.title'
                        |
                        translate}}</span>
                    <span *ngIf="action == 'View'"><br /><span
                            style="font-size: 14px;font-weight:500;">{{selecteUser.title
                            ? selecteUser.title : ''}}</span></span>
                    <input id="trial_userNameIn" *ngIf="action == 'Create' || action == 'Update'"
                        type="text"
                        style="font-size: 14px;padding-top: 0px !important;height: 30px;padding-bottom: 0px !important;box-shadow:none !important;"
                        formControlName="quickreply" placeholder="Quick Reply" class="form-control" />
                </div>

                <div class="form-group" style="padding-bottom: 3%;display:grid;">
                    <span
                        style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;">{{'common.description'
                        | translate}}</span>
                    <span *ngIf="action == 'View'"><span
                            style="font-size: 14px;font-weight:500;">{{selecteUser.description
                            ? selecteUser.description :
                            '-'}}</span></span>
                    <textarea id="trial_userPhoneNoIn"
                        *ngIf="action == 'Create' || action == 'Update'" type="text"
                        style="font-size: 14px;padding-top: 0px !important;height: 90px;padding-bottom: 0px !important;box-shadow:none !important;"
                        formControlName="description" placeholder="{{'common.description' | translate}}"
                        class="form-control"></textarea>
                </div>
                <button id="trial_userSaveAction" *ngIf="action == 'Create'" class="commonButton btn" type="submit"
                    style="margin-right: 15px;border-radius: 8px;width: 40%;padding: 1%;"
                    (click)="onSubmit()">{{'common.save' | translate}}</button>
                <button id="trial_userCancel" *ngIf="action == 'Create'" type="reset"
                    class="cancelButton btn btn-default"
                    style="margin-right: 15px;border-radius: 8px;width: 40%;padding: 1%;"
                    (click)="onCancel(); ">{{'common.cancel' |
                    translate}}</button>
                <button id="trial_userAction" *ngIf="action == 'Update'" class="commonButton btn" type="submit"
                    style="margin-right: 15px;border-radius: 8px;width: 40%;padding: 1%;"
                    (click)="updateQuickReplyRow()">{{'common.update' | translate}}</button>
                <button id="trial_userCancel1" *ngIf="action == 'Update'" type="reset"
                    class="cancelButton btn btn-default"
                    style="margin-right: 15px;border-radius: 8px;width: 40%;padding: 1%;"
                    (click)="onCancel(); ">{{'common.cancel' |
                    translate}}</button>
            </form>
        </div>
    </div>

</div>

