<app-page-header pageTitle="{{'richMenu.title' | translate}}" EngTitle="flex"></app-page-header>
<div class="col-md-12 col-sm-12 col-xs-12 conversation" style="padding-left:0px;">
	<div class="page-loader" *ngIf="loaderShow">
		<img src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px" alt="">
	</div>
	<div style="height: 390px;margin-bottom: 25px;">
		<!--Template Name, Category and Language-->
		<div class="panel panel-default col-lg-8 col-md-8 col-sm-8 col-xs-8 panel-width"
			style="background-color:white; padding:2% 0%;border-radius:5px;margin-top: 10px;min-height: 77px;">
			<div class="panel-body col-lg-12 col-md-12 col-sm-12 col-xs-12"
				[ngStyle]="{'display' : initResponsiveElements() ? 'inline-flex;' : '', 'padding-left' : initResponsiveElements() ? '' : '0px'}">

				<!--Name-->
				<div class="form-group col-lg-5 col-md-5 col-sm-5 col-xs-5" style="margin-bottom:0px !important;"
					[ngStyle]="{'padding-left' : initResponsiveElements() ? '0px' : ''}">
					<h4 class="h4-font-size">{{'richMenu.name' | translate}} </h4>
				</div>
				<div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 remove-padding-right">
					<div class="dropdown tclDisplayStyle">
						<input style="display:inline; width: 95%;" [disabled]="action =='edit'" type="text"
							[(ngModel)]="richForm.richName" ngDefaultControl class="form-control"
							placeholder="{{'richMenu.rich_placeholder' | translate}}" tabindex="-1"
							[ngClass]="{ 'is-invalid': errorList && errorList['richName'] }">
					</div>
					<span *ngIf="errorList && errorList['richName']" class="errorMessageStyle"><em
							class="fas fa-info-circle"></em>&nbsp;&nbsp; {{errorList['richName']}}</span>
				</div>
			</div>
			<div class="panel-body col-lg-12 col-md-12 col-sm-12 col-xs-12"
				[ngStyle]="{'display' : initResponsiveElements() ? 'inline-flex;' : '', 'padding-left' : initResponsiveElements() ? '' : '0px'}">

				<!--Display Name-->
				<div class="form-group col-lg-5 col-md-5 col-sm-5 col-xs-5" style="margin-bottom:0px !important;"
					[ngStyle]="{'padding-left' : initResponsiveElements() ? '0px' : ''}">
					<h4 class="h4-font-size">{{'richMenu.displayName' | translate}} </h4>
				</div>
				<div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 remove-padding-right">
					<div class="dropdown tclDisplayStyle">
						<input style="display:inline; width: 95%;" [disabled]="action =='edit'" type="text" [(ngModel)]="richForm.displayName"
							ngDefaultControl class="form-control" placeholder="{{'richMenu.menu_placeholder' | translate}}"
							tabindex="-1" [ngClass]="{ 'is-invalid': errorList && errorList['displayName'] }">
					</div>
					<span *ngIf="errorList && errorList['displayName']" class="errorMessageStyle"><em
							class="fas fa-info-circle"></em>&nbsp;&nbsp; {{errorList['displayName']}}</span>
				</div>
			</div>
			<div class="panel-body col-lg-12 col-md-12 col-sm-12 col-xs-12"
				[ngStyle]="{'display' : initResponsiveElements() ? 'inline-flex;' : '', 'padding-left' : initResponsiveElements() ? '' : '0px'}">

				<!--Display mode-->
				<div class="form-group col-lg-5 col-md-5 col-sm-5 col-xs-5" style="margin-bottom:0px !important;"
					[ngStyle]="{'padding-left' : initResponsiveElements() ? '0px' : ''}">
					<h4 class="h4-font-size">{{'richMenu.displayMode' | translate}} </h4>
				</div>
				<div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 remove-padding-right">
					<div class="dropdown tclDisplayStyle">
						<span style="padding-right: 20px;">
							<input type="radio" [disabled]="action =='edit'" id="display" name="display" value="display"
								[(ngModel)]="richForm.displayMode">
							<label class="question-form-label" for="display">Display</label>
						</span>
						<span>
							<input type="radio" [disabled]="action =='edit'"  id="hide" name="hide" value="hide" [(ngModel)]="richForm.displayMode">
							<label class="question-form-label" for="hide">Hide</label>
						</span>
					</div>
				</div>
			</div>
			<div class="panel-body col-lg-12 col-md-12 col-sm-12 col-xs-12"
				[ngStyle]="{'display' : initResponsiveElements() ? 'inline-flex;' : '', 'padding-left' : initResponsiveElements() ? '' : '0px'}">
				<div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-6" style="margin-bottom:0px !important;"
					[ngStyle]="{'padding-left' : initResponsiveElements() ? '0px' : ''}">
					<h4 class="h4-font-size">{{'richMenu.imageLayoutSection' | translate}} </h4>
				</div>
			</div>
			
		<div class="panel-body col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 0px;"
				[ngStyle]="{'display' : initResponsiveElements() ? 'inline-flex;' : '', 'padding-left' : initResponsiveElements() ? '' : '0px'}">
				<div class="page-loader" *ngIf="uploadloaderBase" style="left: 50%;">
					<img src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px" alt="">
				  </div>
					<div style="display: flex;" *ngIf="image_url">
					<div class="bottom-section">
					  <div #imageContainer class="image-container" style="width: 35rem;">
						<img class="image-preview" #imageElement (load)="onImageLoad($event)" [src]="image_url" alt="preview">
						<div class="layout-area">
						  <div *ngFor="let area of layoutAreaOptions; let i = index" class="layout-section" #layoutSection
							[style]="area.styles" [class.selected]="area.areaId == selectedLayoutArea?.areaId"
							(click)="selectLayoutArea(area, layoutSection, imageContainer, $event)" (keydown)="selectLayoutArea(area, layoutSection, imageContainer, $event)">
							<button *ngIf="area.text" class="action-button">{{area.text}}</button>
							<video *ngIf="area.videoUrl" class="responsive-attachment" style="width: 100%;height: 100%;" controls>
							  <track label="English" kind="captions" srclang="en-us">
							  <source [src]="area['videoUrl']">
							</video>
						  </div>
						</div>
					  </div>
					</div>
					<div *ngIf="selectedLayoutArea" class="edit-section1">
					  <div class="subField">
						<div class="label-text" style="padding-bottom: 10px;"><span>Action on the Layout Section</span>
						<span *ngIf="action !=='edit'" (click)="clearField();" (keydown)="clearField();" class="closeAttach" style="cursor: pointer;color: red;    float: right;font-size: 20px;font-weight: 600;">&times;</span></div>
						<select [ngModel]="selectedLayoutArea.action" (ngModelChange)="onActionSelect($event, i)">
						  <option value="postback">Quick reply</option>
						  <option value="uri">Web Url</option>
						</select>
					  </div>
					  <div class="subField" style="padding-top: 12px;"  *ngIf="selectedLayoutArea.action === 'postback'" >
						<div class="label-text">Button Text</div>
						<input type="text" [(ngModel)]="selectedLayoutArea.text">
					  </div>
					  <div *ngIf="selectedLayoutArea.action === 'uri'" class="subField" style="margin-top: 1rem;">
						<div class="label-text">Url</div>
						<input type="text" [(ngModel)]="selectedLayoutArea.linkUri">
					  </div>
					  <div style="text-align: right;padding-top: 10px;"><button [disabled]="disableImagemap" class="commonButton"
						  (click)="updateLayoutData();">Done</button></div>
					</div>
				  </div>
				<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-bottom:0px !important;"
					[ngStyle]="{'padding-left' : initResponsiveElements() ? '0px' : ''}">
					<button class="btn baseImage" [disabled]="action =='edit'">
					<label for="file-input_image" style="cursor: pointer;font-weight: 500;">{{'richMenu.baseImageButton' | translate}} </label>
					<input #fileInput id="file-input_image" [disabled]="action =='edit'"  type="file" accept="image/*"
					(change)="handleFileUpload($event, 'base')" class="custom-file-input" style="display: none;">
				</button> &nbsp;
				<span>
					<em [popover]="popUpBaseImage" [placement]="'right'" [container]="'body'" [outsideClick]="true"
					  class="fas fa-info-circle" triggers="mouseenter mouseleave click"></em>
				  </span>
				</div>
				<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-bottom:0px !important;padding-top: 20px;"
				[ngStyle]="{'padding-left' : initResponsiveElements() ? '0px' : ''}">
				<button class="btn baseImage" [disabled]="action =='edit'"  data-toggle="modal" data-target="#imagelayout">{{'richMenu.editlayout' | translate}} </button>
				</div>
	      <!-- layout popup start-->
		  <div id="imagelayout" class="modal fade"  data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog">
              <div class="col-md-12" class="modal-content">
                <div class="modal-header headerModal">
                  <button id="imagelayoutClose" type="button" class="close" data-dismiss="modal">&times;</button>
                  <h4 class="modal-title " style="text-align: center;">{{'richMenu.chooselayout' | translate}}
                  </h4>
                </div>
                <div style="width: 100%;height:325px;">
                  <div class="modal-body col-md-11 colorBlack" style="overflow: hidden;height:100%;padding-bottom: 10px;">
					<div class="layout-selection">
						<div class="layout-option" data-dismiss="modal" (click)="onLayoutSelect(1)" (keydown)="onLayoutSelect(1)">
						  <div class="gray-area"></div>
						  <div class="label">
							<input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 1"> 
							<span>layout 1</span>
						  </div>
						</div>
						<div class="layout-option" data-dismiss="modal" (click)="onLayoutSelect(2)" (keydown)="onLayoutSelect(2)">
						  <div class="gray-area">
							<div style="left: 50%;top: 0;bottom: 0;width: 1px;border-left-width: 1px;"></div>
						  </div>
						  <div class="label">
							<input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 2">
							<span>layout 2</span>
						  </div>
						</div>
						<div class="layout-option" data-dismiss="modal" (click)="onLayoutSelect(3)" (keydown)="onLayoutSelect(3)">
						  <div class="gray-area">
							<div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
						  </div>
						  <div class="label">
							<input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 3">
							<span>layout 3</span>
						  </div>
						</div>
						<div class="layout-option" data-dismiss="modal" (click)="onLayoutSelect(4)" (keydown)="onLayoutSelect(4)">
						  <div class="gray-area">
							<div style="left: 0;right: 0;top: 33%;height: 1px;border-top-width: 1px;"></div>
							<div style="left: 0;right: 0;top: 66%;height: 1px;border-top-width: 1px;"></div>
						  </div>
						  <div class="label">
							<input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 4">
							<span>layout 4</span>
						  </div>
						</div>
						<div style="width: 100%;"></div>
						<div class="layout-option" (click)="onLayoutSelect(5)" (keydown)="onLayoutSelect(5)" data-dismiss="modal">
						  <div class="gray-area">
							<div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
							<div style="left: 50%; top: 0; bottom: 0; width: 1px; border-left-width: 1px;"></div>
						  </div>
						  <div class="label">
							<input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 5">
							<span>layout 5</span>
						  </div>
						</div>
						<div class="layout-option" data-dismiss="modal" (click)="onLayoutSelect(6)" (keydown)="onLayoutSelect(6)">
						  <div class="gray-area">
							<div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
							<div style="left: 50%; top: 50%; bottom: 0; border-left-width: 1px;"></div>
						  </div>
						  <div class="label">
							<input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 6">
							<span>layout 6</span>
						  </div>
						</div>
						<div class="layout-option" data-dismiss="modal" (click)="onLayoutSelect(7)" (keydown)="onLayoutSelect(7)">
						  <div class="gray-area">
							<div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
							<div style="left: 0; right: 0; top: 75%; height: 1px; border-top-width: 1px;"></div>
						  </div>
						  <div class="label">
							<input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 7">
							<span>layout 7</span>
						  </div>
						</div>
						<div class="layout-option" data-dismiss="modal" (click)="onLayoutSelect(8)" (keydown)="onLayoutSelect(8)">
						  <div class="gray-area">
							<div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
							<div style="left: 33%; top: 0; bottom: 0; width: 1px; border-left-width: 1px;"></div>
							<div style="left: 66%; top: 0; bottom: 0; width: 1px; border-left-width: 1px;"></div>
						  </div>
						  <div class="label">
							<input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 8">
							<span>layout 8</span>
						  </div>
						</div>
					  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</div>
		</div>
		<div class="form-group col-lg-8 col-md-8 col-sm-8 col-xs-8 panel-width" style="text-align: center;padding: 2%;">
			<button *ngIf="action === 'open'" [disabled]="disableImagemap" type="button" id="templatesavebtn" class="commonButton btn btn-width"
				style="margin-right: 15px;border-radius: 8px;"
				[ngStyle]="{'width' : initResponsiveElements() ? '15%' : ''}" (click)="onSubmit();">{{'common.save' |
				translate}}</button>
			<button *ngIf="action !== 'edit' && action !== 'open'" type="button"  id="templatesavebtn" class="commonButton btn btn-width"
				style="margin-right: 15px;border-radius: 8px;"
				[ngStyle]="{'width' : initResponsiveElements() ? '15%' : ''}" (click)="onSubmit();">{{'common.update' |
				translate}}</button>
			<button id="templateCancelebtn" type="reset" class="cancelButton btn btn-default"
				style="margin-right: 15px;border-radius: 8px;"
				[ngStyle]="{'width' : initResponsiveElements() ? '15%' : ''}" (click)="onCancel(); ">{{'common.cancel' |
				translate}}</button>
		</div>
	</div>
</div>
<ng-template #popUpBaseImage>
	<div class="card" style="border:none;max-height: 250px;">
	  <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
		<p class="card-text" style="font-size: 12px;" innerHtml="{{'richMenu.attachment_info' | translate}}">
		</p>
	  </div>
	</div>
  </ng-template>
  <ng-template #popUpAttach>
	<div class="card" style="border:none;max-height: 250px;">
	  <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
		<p class="card-text" style="font-size: 12px;" innerHtml="{{'common.attachment_video_info' | translate}}">
		</p>
	  </div>
	</div>
  </ng-template>
  <ng-template #popUpImage>
	<div class="card" style="border:none;max-height: 250px;">
	  <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
		<p class="card-text" style="font-size: 12px;" innerHtml="{{'common.attachment_info_img' | translate}}">
		</p>
	  </div>
	</div>
  </ng-template>
