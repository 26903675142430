<div id="flowbuilder" class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;" xmlns:svg="">

  <div class="page-loader" *ngIf="loaderShow" style="left: 50%;">
    <img src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px" alt="">
  </div>


  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;">
    <h3 class="al-title">
      {{'flowBuilder.title' | translate}}
      <span *ngIf="flowName">(<span style="font-size: 18px;">{{flowName}} - <span style="text-transform:uppercase">{{language}}</span></span>)</span>
    </h3>


    <div  *ngIf="saveMessage === 1" class="geStatusAlert" >
      <span class="text">{{'flowBuilder.saving' | translate}}</span>
      <mat-progress-bar class="progressBar" mode="buffer" ></mat-progress-bar>
    </div>
    <div  *ngIf="saveMessage === 2" class="geStatusSucess commonButton">
      <span class="text">{{'flowBuilder.Saved_to_cloud' | translate}}</span>
    </div>

  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 flow-builder" style="padding:0px;">

    <mat-drawer-container class="example-container">
      <mat-drawer #sideNav mode="side" opened="false" position="end" style="border: 1px solid #ececec;overflow-x: hidden;">
        <div>
          <button class="right-close"  color="primary" tooltip="Close" placement="top"
                  (click)="closeRight()">
            <div class="tooltip">{{'flowBuilder?.add_menu' | translate}}</div>
            <span><em class="fas fa-times"></em></span>
          </button>
        </div>

        <div class="container" *ngIf="!childNodesShow">
          <div class="current_node_id">
            <div class="header bColor">
              <h5 style="font-weight: bold;">{{'flowBuilder.current_node' | translate}} (<span style="font-size: 13px;font-weight: normal;">{{nodetypeName}}</span>)</h5>
            </div>
            <div *ngIf="node_type === 'condition'">
              <form class="basic-container" [formGroup]="conditionForm">
                <div formArrayName="conditions" >
                  <div class="row rowbox" *ngFor="let con of conditionForm.get('conditions')['controls']; let i = index" [formGroupName]="i">
                    <div class="col-md-4" style="padding:0px;">
                      <input *ngIf="conditionalResponseType == 'api_response' && ValueDisplayAuthentication !=='authentication'" [ngStyle]="{'text-align':arabic_lan?'right':'left',
                                      'direction': arabic_lan?'rtl':'ltr'}" type="text" value="{{selectedConditionalResponseValue?.variable}}" readonly>
                      <input *ngIf="conditionalResponseType == 'send_api' && ValueDisplayAuthentication !=='authentication'" [ngStyle]="{'text-align':arabic_lan?'right':'left',
                                      'direction': arabic_lan?'rtl':'ltr'}" type="text" value="Send API response" readonly>
                                      <label *ngIf="ValueDisplayAuthentication == 'authentication'" for="condition1">{{'flowBuilder.authentication' | translate}}</label>
                    </div>
                    <div [ngClass]="isNumeric ? 'col-md-7' : 'col-md-4'" style="padding-left: 6px; padding-right: 0px;position: relative; display: inline-block;">
                      <select *ngIf="ValueDisplayAuthentication !== 'authentication'" formControlName="condition" id="condition1" name="condition" style="padding:11px !important;" (ngModelChange) = 'checkNumeric($event, i)'>
                        <option  *ngFor="let cond of withoutValidInvalidList;" [ngValue]="cond">{{cond.name}}</option>
                      </select>
                      <select *ngIf="ValueDisplayAuthentication == 'authentication'" formControlName="condition" id="condition1" name="condition" style="padding:11px !important;" (ngModelChange) = 'checkNumeric($event, i)'>
                        <option  *ngFor="let cond of validInvalidList;" [ngValue]="cond">{{cond.name}}</option>
                      </select>
                      <span *ngIf="node_type === 'condition' && phoneNumberInfoCheck" style="position: absolute; right: -25px; top: 50%; transform: translateY(-50%);">
                        <em [popover]="infoForPhoneNumber" [placement]="'right'" [container]="'body'" [outsideClick]="true" class="fas fa-info-circle" triggers="mouseenter mouseleave click"></em>

                      </span>
                    </div>
                    <div *ngIf='!isNumeric' class="col-md-3" style="padding-left: 6px; padding-right: 0px;">
                      <input *ngIf="ValueDisplayAuthentication !== 'authentication'" [ngStyle]="{'text-align':arabic_lan?'right':'left','direction': arabic_lan?'rtl':'ltr'}" type="text" formControlName="conditionValue" id="conditionValue" name="conditionValue" placeholder="{{'flowBuilder.value' | translate}}" title="{{'flowBuilder.Only_Numeric_value' | translate}}" (keypress)="valueValidation($event, i);">
                    </div>
                  </div>
                  <p *ngIf= "node_menuerrormsg" style="color:red;font-size: 9px;text-align: center;" >{{node_menuerrormsg}}</p>
                  <p *ngIf= "node_valueErrorMsg" style="color:red;font-size: 9px;text-align: end;padding-right: 50px;" >{{node_valueErrorMsg}}</p>
                </div>
              </form>
            </div>
            <div class="row rowbox" *ngIf="node_type !== 'conditional_response' && node_type !== 'condition'">
              <div class="col-35">
                <span>{{'flowBuilder.titles' | translate}}</span>
              </div>
              <div class="col-60" *ngIf="nodetypeName === 'Root' || node_type == 'menu_item'" [class.textMenu] = "node_type == 'menu_item'">
                <textarea  [ngStyle]="{'text-align':arabic_lan?'right':'left','direction': arabic_lan?'rtl':'ltr'}" [ngStyle]="{'cursor': (node_type === 'root') ? 'pointer' : 'default'}" rows="4" cols="50"   [(ngModel)]="parent_qa" id="Title" name="title" placeholder="{{'flowBuilder.titles' | translate}}" [readonly]="node_type === 'root'"></textarea>
              </div>
              <quill-editor class="col-60" *ngIf="nodetypeName != 'Root' && node_type != 'menu_item'" [(ngModel)]="parent_qa" name="question" ngDefaultControl [modules]="tools" [format]="'html'" (onContentChanged)="updateParentQaContent($event);" [styles]="{height: 'calc(35vh - 100px)'}" placeholder= "{{ getRecordStatus('placeholder',nodetype) }}" minLength=10  [required]="true" [ngModelOptions]="{standalone: true}" #Editor>
                <div quill-editor-toolbar >
                  <span class="ql-formats">
                    <button class="ql-bold" title="{{'flowBuilder.bold' | translate}}"></button>
                    <button class="ql-italic" title="{{'flowBuilder.italic' | translate}}"></button>
                    <button type="button" (click)="emojiClick()">😀</button>
                    <br>
                    <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" title="{{'flowBuilder.choose_your_emoji' | translate}}"></emoji-mart>
                  </span>
                  <span class="ql-formats" >
                    <button class="btn btn-default headerTextEditorStyle form-control" style="padding:0px !important;text-align:left;font-weight:500;" (click)="showSelectBox()" ><em class="fas fa-plus-circle" *ngIf="!showVariableSelectBox"></em> <em class="fas fa-minus-circle" *ngIf="showVariableSelectBox"></em>{{'common.add_variable' | translate}}</button>
                  </span>
                  <div *ngIf="showVariableSelectBox">
                    <span style="padding-left: 18px;padding-right:2px; font-size: 10px;color: #828282; font-weight: bold;" class="radio-inline question-form-label" *ngIf="showVariableSelectBox && projectVariable">
                      <input type="radio" style="margin-top: 1px;" [(ngModel)]="variableType" name="radio_activity" value="project" (change)="changeVariableRadio()" checked > {{'common.project_defined' | translate}}
                    </span>
                    <span class="radio-inline question-form-label" style="font-size: 10px;color: #828282; padding-left: 10px;font-weight: bold;" *ngIf="showVariableSelectBox && systemVariable">
                      <input type="radio" style="margin-top: 1px;" name="radio_activity"
                             [(ngModel)]="variableType" value="system"  (change)="changeVariableRadio()">  {{'common.system_defined' | translate}}
                    </span>
                    <span class="radio-inline question-form-label" style="font-size: 10px;color: #828282; padding-left: 8px; font-weight: bold;" *ngIf="showVariableSelectBox && userVariable">
                      <input type="radio" style="margin-top: 1px;" name="radio_activity"
                             [(ngModel)]="variableType" value="user"  (change)="changeVariableRadio()"> {{'common.user_defined' | translate}}
                    </span>
                  </div>
                  <div style="width: 170px;" *ngIf="showVariableSelectBox">
                    <ngx-select-dropdown tabindex="0" style="text-overflow: ellipsis;" [multiple]="false" (mouseover)="onHover($event)" title="{{titleDrop}}" [(ngModel)]="variableParam" id="variable3" name="variable" [config]="variableConfig" [options]="VariabledropdownOptions" (click)="variableClick()" (keydown)="variableClick()" (change)="variableOnChange($event)" [ngModelOptions]="{standalone: true}" (focus)="onFocus($event)"></ngx-select-dropdown>
                  </div>
                </div>
              </quill-editor>
              <p class="col-60" *ngIf="parenterrormsg" style="color:red;font-size: 9px;text-align: end;margin-left: -6%;margin-bottom: 0px;" >{{parenterrormsg}}</p>
            </div>
            <div *ngIf="(nodetype === 'slot_question' || node_type === 'menu_question')  && userAttributeOptions.length > 0">
              <div class="row rowbox" >
                <div class="col-35">
                  <label for="nodeid">{{'flowBuilder.user_attribute' | translate}}</label>
                </div>
                <div class=" textMenu col-60" >
                    <ngx-select-dropdown [config]="userAttributeConfig" [options]="userAttributeOptions"
                                         [(ngModel)]="userAttribute" [multiple]="false">
                    </ngx-select-dropdown>
                </div>
              </div>
            </div>
            <div *ngIf="nodetype === 'conditional_response'">
              <div class="row rowbox" >
                <div class="col-35">
                  <label for="nodeid">{{'flowBuilder.check_with' | translate}}</label>
                </div>
                <div class=" textMenu col-60" >
                  <select id="check_with" name="check_with" [(ngModel)]="checkWith" (change)="onSelectChange($event)">
                    <option *ngIf="!authdisplay"  value="api_response" selected >{{'flowBuilder.API_response' | translate}}</option>
                    <option *ngIf="userInput" value="user_input">{{'flowBuilder.user_input' | translate}}</option>
                    <option *ngIf="sendApi" value="send_api">{{'flowBuilder.send_api' | translate}}</option>
                    <option *ngIf="authdisplay" value="authentication">{{'flowBuilder.authentication' | translate}}</option>
                  </select>
                </div>
              </div>
              <div class="row rowbox" *ngIf="checkWith === 'api_response'">
                <div class="col-35">
                  <span >{{'common.variables' | translate}} </span>
                </div>
                <div class="col-60">
                  <select id="variablesUpdate" [(ngModel)]="selectedConditionalResponseValue.variable"   (change)="selectedConditionalvariable(selectedConditionalResponseValue.variable)"  name="variables" >
                    <option *ngFor="let variable of variablesList;" value = {{variable}} [ngValue]="variable.variable">{{variable?.variable}}</option>
                  </select>
                  <p *ngIf="parenterrormsg" style="color:red;font-size: 9px;" >{{parenterrormsg}}</p>
                </div>
              </div>
              <div class="row rowbox" *ngIf="checkWith === 'user_input'">
                <div class="col-35">
                  <label for="nodeid">{{'flowBuilder.input_node' | translate}}</label>
                </div>
                <div class=" textMenu col-60" >
                  <input type="text" [ngStyle]="{'text-align':arabic_lan?'right':'left','direction': arabic_lan?'rtl':'ltr'}" style="cursor: pointer;"
                         readonly id="input_node" name="nodeID" [(ngModel)]="userInputNode" [tooltip]="userInputNode" placement="top" theme="light" showDelay="500" width="auto" >
                </div>
              </div>
            </div>
            <div class="row rowbox" *ngIf="nodetypeName != 'Root' && node_type !== 'condition' && node_type !== 'conditional_response'">
              <div [ngClass]="node_type == 'condition' ? 'col-36' : 'col-35'" [ngStyle]="{'margin-top': !image_url ? '0px' : '6px'}">
                <span  style="margin-bottom: 0px;padding-top: 5px;">{{'flowBuilder.choosefile' | translate}}
                </span>
              </div>
              <div [ngClass]="node_type == 'condition' ? 'col-61' : 'col-60'" style="margin-top: 0px;">

                <div class="fileInput" *ngIf="(imageDetailsList.length < 4 && node_type !== 'menu_item') || (imageDetailsList.length < 1 && node_type === 'menu_item')">
                  <label for="file-input-edit2"  style="cursor: pointer;padding-top: 5px;">
                    <i class="fas fa-paperclip" aria-hidden="true"></i>
                    <span *ngIf="node_type !== 'menu_item'">{{'common.Attach' | translate}}
                      <em [popover]="popUpTemplatemultiple" [placement]="'right'" [container]="'body'" [outsideClick]="true" class="fas fa-info-circle" triggers="mouseenter mouseleave click"></em>

                    </span>
                    <span *ngIf="node_type === 'menu_item'">{{'common.Attachfile' | translate}}
                      <em [popover]="popUpTemplatemultiplemenu" [placement]="'right'" [container]="'body'" [outsideClick]="true" class="fas fa-info-circle" triggers="mouseenter mouseleave click"></em>

                    </span></label>



                  <input #fileInputEdit id="file-input-edit2" type="file"
                         accept="image/*,application/pdf,audio/*,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                         (change)="processFileEdit($event, '')" class="custom-file-input" style="display: none;">
                </div>
                <div *ngFor="let imageDetail of imageDetailsList;">
                <div style="padding: 7px 2px 0px;"*ngIf="imageDetail.image_url && imageDetail.mediaType == 'image'"  class="img-wrap">
                  <img alt=""
                       style="width: 50px; cursor: pointer"
                       [src]="imageDetail.image_url"
                       (click)="openImageLayoutDialog(imageDetail)"
                       (keydown)="openImageLayoutDialog(imageDetail)">
                  <span (click)="imageCrossClick(imageDetail.image_url);" (keydown)="imageCrossClick(imageDetail.image_url);" class="closeAttach"
                        style="cursor: pointer;">&times;</span>
                </div>
                <div style="padding: 7px 2px 0px;" *ngIf="imageDetail.image_url && imageDetail.mediaType ==='document'" class="img-wrap">
                  <a aria-label="url" [href]="imageDetail.image_url" target="_blank" style="width: 40px;">
                    <em class="fa fa-file-pdf-o" aria-hidden="true" style="font-size:50px;"></em></a>
                  <span (click)="imageCrossClick(imageDetail.image_url);" (keydown)="imageCrossClick(imageDetail.image_url);" class="closeAttach"
                        style="cursor: pointer;">&times;</span>
                </div>
                <div style="padding: 7px 2px 0px;" *ngIf="imageDetail.image_url && imageDetail.mediaType ==='video'" class="img-wrap">
                  <a aria-label="url" [href]="imageDetail.image_url" target="_blank">
                    <em class="fa fa-file-video-o" aria-hidden="true" style="font-size:50px;"></em></a>
                  <span (click)="imageCrossClick(imageDetail.image_url);" (keydown)="imageCrossClick(imageDetail.image_url);" class="closeAttach"
                        style="cursor: pointer;">&times;</span>
                </div>
                <div style="padding: 7px 2px 0px;" *ngIf="imageDetail.image_url && imageDetail.mediaType ==='audio'" class="img-wrap">
                  <a aria-label="url" [href]="imageDetail.image_url" target="_blank">
                    <em class="fa fa-file-audio-o" aria-hidden="true" style="font-size:50px;"></em>
                  </a>
                  <span (click)="imageCrossClick(imageDetail.image_url);" (keydown)="imageCrossClick(imageDetail.image_url);" class="closeAttach" style="cursor: pointer;">&times;</span>
                </div>
                <div style="padding: 7px 2px 0px;" *ngIf="imageDetail.image_url && imageDetail.mediaType ==='docx'" class="img-wrap">
                  <a aria-label="url" [href]="imageDetail.image_url" target="_blank">
                    <em class="fa fa-file-word-o" aria-hidden="true" style="font-size:50px;"></em></a>
                  <span (click)="imageCrossClick(imageDetail.image_url);" (keydown)="imageCrossClick(imageDetail.image_url);" class="closeAttach" style="cursor: pointer;">&times;</span>
                </div>
                </div>
                <div style="padding: 5px 5px" *ngIf="uploadloader">
                  <img alt="" src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 8%" />
                </div>
              </div>
            </div>
            <div *ngIf="node_type === 'end_response'">
              <div class="row rowbox" style="padding-bottom: 5px;">
                <div class="col-35">
                  <span >{{'flowBuilder.send_action' | translate}}</span>
                </div>
                <div class="col-60">
                  <select id="node_type_1" [(ngModel)]="updateactiontype"(ngModelChange)="sendactionChange(updateactiontype)"  name="action_type">
                    <option value="none">{{ 'flowBuilder.none' | translate}}</option>
                    <option value="send_email">{{ 'flowBuilder.email_report' | translate}}</option>
                    <option value="send_api">{{ 'flowBuilder.send_API' | translate}}</option>
                    <option value="unsubscribe">{{ 'flowBuilder.unsubscribe' | translate}}</option>
                    <option value="subscribe">{{ 'flowBuilder.subscribe' | translate}}</option>
                    <option *ngIf = "agentHandover" value="transfer_to_agent">{{ 'flowBuilder.transfer_to_agent' | translate}}</option>
                    <option value="connect_to_flows">{{ 'flowBuilder.Connect_to_flows' | translate}}</option>
                    <option *ngIf="isPaymentEnabled" value="payment">{{ 'flowBuilder.create_payment' | translate}}</option>
                  </select>
<p *ngIf = "!agentHandover && updateactiontype === 'transfer_to_agent' " style="color: red;font-size: 11px;">{{'flowBuilder.LiveAgent_Info' | translate}}</p>
                </div>
              </div>
              <div *ngIf="updateactiontype === 'transfer_to_agent' && routingAgent" class="row rowbox" style="padding-bottom: 5px;">
                <div class="col-35">
                  <span >{{'Agent Group'}}</span>
                </div>
                <div class="col-60">
                  <select id="node_type1" [(ngModel)]="updateAgentGroup" name="AgentGroup">
                    <option [ngValue]="agent" selected = "updateAgentGroup.group_name === agent.group_name" *ngFor="let agent of agentGroupList">
                      {{ agent?.['group_name'] }}
                    </option>
                  </select>
                  <p *ngIf="agentGrouperrormsg"
                     style="color:red;font-size: 9px;margin-bottom: 0px;">{{agentGrouperrormsg}}
                  </p>
                </div>
              </div>
              <form class="basic-container" [formGroup]="updateemailForm" *ngIf="(updateactiontype === 'send_email')">
                <div formArrayName="updateemails">
                  <div class="row rowbox" *ngFor="let updateemail of updateemailForm.get('updateemails')['controls']; let i = index" [formGroupName]="i">
                    <div class="col-35">
                      <span class="mt-3">{{'common.email' | translate}}</span>
                    </div>
                    <div class="col-40">
                      <input  [ngStyle]="{'text-align':arabic_lan?'right':'left','direction': arabic_lan?'rtl':'ltr'}" type="text" value = "email" (keyup)="handleInput($event.target['value'], i)"  formControlName="updateemailAddress" placeholder="{{'common.email' | translate}}" autocomplete="email">
                      <mat-error *ngFor="let validation of updatevalidationMsgs.updateemailAddress; ">
                        <div *ngIf="updateemail.get('updateemailAddress').hasError(validation.type) || emailerror[i]">
                          {{validation.message}}
                        </div>
                      </mat-error>
                    </div>
                    <div class="col-20 wrapper">
                      <div class="icon plus"  (click)="updateaddEmailFormGroup('')" (keydown)="updateaddEmailFormGroup('')">
                        <div class="tooltip">{{'flowBuilder.add_email' | translate}}</div>
                        <span><em class="fas fa-plus"></em></span>
                      </div>
                      <div class="icon remove"  (click)="updateremoveOrClearEmail(i)" (keydown)="updateremoveOrClearEmail(i)">
                        <div class="tooltip">{{'flowBuilder.remove_email' | translate}}</div>
                        <span><em class="fas fa-times"></em></span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row rowbox" style="padding-bottom: 5px;" *ngIf="(updateactiontype === 'connect_to_flows')">
                <div class="col-35">
                  <span >{{'flowBuilder.Choose_Flow' | translate}}</span>
                </div>
                <div class="col-60">

                  <ngx-select-dropdown tabindex="0" style="text-overflow: ellipsis;" [multiple]="false" (mouseover)="onHover($event)"  [(ngModel)]="updateConnectToFlow" id="variable3" name="name" [config]="NameConfig" [options]="flowbuilderListDatalist" (click)="variableClick()" (keydown)="variableClick()" [ngModelOptions]="{standalone: true}" (focus)="onFocus($event)"></ngx-select-dropdown>

                </div>

              </div>
              <div *ngIf="(updateactiontype === 'send_api')">
                <div class="row rowbox" >
                  <div class="col-35">
                    <span>{{'flowBuilder.webhook_URL' | translate}}</span>
                  </div>
                  <div class="col-60">
                    <input type="text" name="webhookurl"  value="webhookurl"  placeholder="{{'flowBuilder.webhook_URL' | translate}}" [(ngModel)]="updatewebhookurl" (ngModelChange)="sendactionChange(updateactiontype)">
                    <em _ngcontent-qba-c163="" [popover]="popUpTemplate" [outsideClick]="true" triggers="click"
                        placement="top" theme="light" class="fas fa-info-circle" style="float: right;margin-top: 15px;margin-right:-15px;"></em>
                  </div>
                </div>
                <div class="row rowbox" >
                  <div class="col-35">
                    <span>{{ 'common.header' | translate}}</span>
                  </div>
                  <div class="col-60">
                    <input type="text" name="headerkey" placeholder="{{'flowBuilder.header_Key_(Optional)' | translate}}" [(ngModel)]="updateheaderkey" (ngModelChange)="sendactionChange(updateactiontype)">
                    <input type="text" name="headervalue" placeholder="{{'flowBuilder.header_Value_(Optional)' | translate}}" [(ngModel)]="updateheadervalue" (ngModelChange)="sendactionChange(updateactiontype)">
                    <em class="fa fa-times-circle" *ngIf="urlvalidation && validationClick" style="color:red; margin-top: 17px;
                               position: relative;left: 202px;"> </em> <em class="fa fa-check-circle" style="color: green;
                               margin-top: 15px;
                               position: relative;left: 202px;" *ngIf="!urlvalidation"> </em><button style="margin: 5px 2px;float: right;" class="commonButton nodeSubmit" (click)="apiUpdateValidate()">{{ 'flowBuilder.validate' | translate}} <em class="fas fa-info-circle" tooltip="{{'flowBuilder.Validate_the_webhook_URL' | translate}}" placement="top" theme="light"></em></button>
                  </div>
                </div>
              </div>
              <div class="row rowbox" style="padding-bottom: 5px;" *ngIf="(updateactiontype === 'payment' && isPaymentEnabled)">
                <div class="col-35">
                  <span >{{'liveagent.amount' | translate}}</span>
                </div>
                <div class="col-60">
                  <input type="text" type="number" min="0" [(ngModel)]="paymentSettings.amount" class="form-control" />
                  <p class="col-60" *ngIf="paymentErrormsg && !paymentSettings.amount" style="color:red;font-size: 9px;margin-bottom: 0px;" >{{paymentErrormsg}}</p>
                </div>
                <div class="col-35">
                  <span >{{'liveagent.currency' | translate}}</span>
                </div>
                <div class="col-60">
                  <div ngbDropdown class="dropdown">
                    <button type="button" class="btn btn-primary dropdown-toggle caret-off storageStyle"
                      style="background-color: white;height: 34px;width: 100%;border: 1px solid #ccc;"
                      id="paymentCountryMenu" ngbDropdownToggle>
                      <span
                        style="float:left;font-weight: 400;font-size: 14px;color: #555555;">{{paymentSettings?.currencyCode}}</span>
                      <em class="fas fa-caret-down" style="margin-left: 5px;float:right;color: black;"></em>
                    </button>
                    <div style="max-height: 90px;overflow-y: scroll;padding:3%;width: 100%;" class="dropdown-menu"
                      aria-labelledby="paymentCountryMenu" ngbDropdownMenu>
                      <div *ngFor="let country of selectedCurrencies">
                        <button type="button" style="font-weight: 100;border: none;background-color: white;padding: 5px 0px;width: 100%;text-align: left;" class="dropdown-item" (click)="changeSelectedCurrency(country)" [ngClass]="{'selectedDropDownStyle' : paymentSettings?.currencyCode === country.currency}">
                          {{country.currency}}
                        </button>
                      </div>
                    </div>
                    <em _ngcontent-qba-c163="" [popover]="popUpCurrency" [outsideClick]="true" triggers="mouseenter click"
                    placement="top" theme="light" class="fas fa-info-circle" style="float: right;margin-top: 15px;margin-right:-15px;cursor: pointer;"></em>
                  </div>
                   <p class="col-60" *ngIf="paymentErrormsg && !paymentSettings?.currencyCode" style="color:red;font-size: 9px;margin-bottom: 0px;" >{{paymentErrormsg}}</p>
                </div>
                <div class="col-35">
                  <span>{{'common.title' | translate}}</span>
                </div>
                <div class="col-60">
                  <input type="text" type="text" [(ngModel)]="paymentSettings.title" placeholder="{{'liveagent.payTitle' | translate}}" class="form-control" />
                  <p class="col-60" *ngIf="paymentErrormsg && !paymentSettings.title"  style="color:red;font-size: 9px;margin-bottom: 0px;" >{{paymentErrormsg}}</p>
                </div>
                </div>
            </div>
            <div class="row rowbox">
              <div [ngClass]="node_type == 'condition' ? 'col-36' : 'col-35'">
                <label for="nodeid">{{ 'flowBuilder.node_id' | translate}}</label>
              </div>
              <div class=" textMenu" [ngClass]="node_type == 'condition' ? 'col-61' : 'col-60'">
                <input type="text" [ngStyle]="{'text-align':arabic_lan?'right':'left','direction': arabic_lan?'rtl':'ltr'}" [ngStyle]="{'cursor': (node_type === 'root') ? 'pointer' : 'auto'}" [readonly]="node_type == 'root'" id="nodeID" name="nodeID" autocomplete="off" placeholder="{{ 'flowBuilder.Node_Id' | translate}}" [(ngModel)]="nodeName" >
                <p *ngIf= "idErrormsg" style="color:red;font-size: 9px;" >{{idErrormsg}}</p>
              </div>
              <div *ngIf="menu_itemSort" [ngClass]="node_type == 'condition' ? 'col-36' : 'col-35'">
                <label for="nodesort">{{'flowBuilder.Sort' | translate}}</label>
              </div>
              <div id="nodesort"  class=" textMenu" [ngClass]="node_type == 'condition' ? 'col-61' : 'col-60'" *ngIf="menu_itemSort">
                <input type="number" style="font-size: 18px;
                padding: 10px;
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 4px;" [ngStyle]="{'text-align':arabic_lan?'right':'left','direction': arabic_lan?'rtl':'ltr'}" [ngStyle]="{'cursor': (node_type === 'root') ? 'pointer' : 'auto'}" [readonly]="node_type == 'root'"  autocomplete="off" [(ngModel)]="sortNo" (input)="ensureInteger($event)" min="1">
                <p *ngIf= "idErrormsgSort" style="color:red;font-size: 9px;" >{{'flowBuilder.Sort_no_already_exist' | translate}}</p>
              </div>
              <div *ngIf="menuStatusChecking" [ngClass]="node_type == 'condition' ? 'col-36' : 'col-35'">
                <label for="nodeid">{{'flowBuilder.NodeStatus' | translate}}</label>
              </div>
              <div class=" textMenu"  *ngIf="menuStatusChecking" [ngClass]="node_type == 'condition' ? 'col-36' : 'col-35'">

              <ui-switch class="uiSwitch " style="padding-left:0px;float: left; margin-top: 10px;"
                      name="nlpResponseSwitchfloat" [(ngModel)]="Node_status" (change)="nlpResponseType(selectedNodeIdStatus,$event)"
                      size="small">
                    </ui-switch>
                    <em _ngcontent-qba-c163="" [popover]="popUpHidemenu" [outsideClick]="true" triggers="mouseenter click"
                    placement="top" theme="light" class="fas fa-info-circle" style="padding-left: 10px;margin-top: 15px;cursor: pointer;"></em>
                    </div>
                    <div *ngIf="checkingNodes === 'condition' && flowOtpAuthentication && checkBoxDisplay" [ngClass]="node_type == 'condition' ? 'col-36' : 'col-35'">
                      <label for="nodeid">{{'flowBuilder.authentication' | translate}}</label>
                    </div>
                    <div *ngIf="checkingNodes === 'condition' && flowOtpAuthentication && checkBoxDisplay" class=" textMenu" [ngClass]="node_type == 'condition' ? 'col-61' : 'col-60'">
                      <input type="checkbox"  [(ngModel)]="Node_auth" (change)="CheckBoxvalue($event)" style = "margin-top: 13px;" [ngStyle]="{'cursor': (node_type === 'root') ? 'pointer' : 'auto'}" >

                    </div>
            </div>
          </div>
          <button [disabled]="idErrormsg" [ngClass] ="(urlvalidation) ? 'btnDisable submitButton' : 'commonButton nodeSubmit'" (click)="updateCurrentNode()" *ngIf="node_type !== 'root' && !(!agentHandover && updateactiontype === 'transfer_to_agent')">
            {{'common.update' | translate}} <em class="fas fa-info-circle" tooltip="{{'flowBuilder.Update_the_current_node_data' | translate}}" placement="top" theme="light"></em>
          </button>
        </div>

      </mat-drawer>
      <mat-drawer-content style="overflow: hidden;border: 1px solid #e8e8e8;">
        <div id="container" #parentDiv>
         <div class="drag-container" #dragContainer id="dragContainer" [ngStyle]="{'width': childWidthMethod() + 'px'}">
            <div>
              <div class="back-button" >
              <span  (click)="goback()" (keydown)="goback()" style="float: left;cursor: pointer;z-index: 999;margin-top: 2%;">
                 <img alt="" src="./assets/img/goback.png" class="img4" >
                 <span class="back-button-text">{{'flowBuilder.go_back' | translate}}</span>
              </span>
              </div>
              <div class="back-button" style="width: 35px;" matTooltip = "{{'flowBuilder.Activate_selecting_multiple_nodes' | translate}}">
              <span  (click)="select()" (keydown)="select()" style="float: left;cursor: pointer;z-index: 999;margin-top: 2%;">
                 <img alt="" *ngIf="selectionModeEnabled" src="./assets/images/flowbuilder-icon/select.svg" class="img5"  >
                <img alt="" *ngIf="!selectionModeEnabled" src="./assets/images/flowbuilder-icon/deselect.svg" class="img5">
              </span>
              </div>
              <button class="fullscreen btn-color" (click)="toggleFullScreen()"  [matTooltip] = "isFullScreen ? ('flowBuilder.Normal_screen' | translate) : ('flowBuilder.Full_screen' | translate)">
                <i [class]="isFullScreen ? 'fas fa-compress' : 'fas fa-expand'"></i>
              </button>
              <button class="fullscreen dropdown btn-color"  style="padding: 0;" >
                <a aria-label="url" class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding: 0px 5px;color: gray;"  (keydown)="onKeyDown($event)">
                  <i class="fa fa-download" aria-hidden="true" matTooltip = "{{'flowBuilder.download' | translate}}"></i>
                </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item" (click)="downloadImage()">
                    <img *ngIf="lineTypeItem" class="selectedLine" src="./assets/images/flowbuilder-icon/png-file-icon.svg" alt=" Icon">&nbsp; {{'flowBuilder.PNG' | translate}}</a>
                  <a class="dropdown-item" (click)="downloadJPG()">
                    <img *ngIf="lineTypeItem" class="selectedLine" src="./assets/images/flowbuilder-icon/jpg-file-icon.svg" alt=" Icon">&nbsp; {{'flowBuilder.JPG' | translate}}</a>
                  <a class="dropdown-item" (click)="downloadSVG()">
                    <img *ngIf="lineTypeItem" class="selectedLine" src="./assets/images/flowbuilder-icon/svg-file-icon.svg" alt=" Icon">&nbsp; {{'flowBuilder.SVG' | translate}}</a>
                  <a class="dropdown-item" (click)="downloadCSV()">
                    <img *ngIf="lineTypeItem" class="selectedLine" src="./assets/images/flowbuilder-icon/csv-file-icon.svg" alt=" Icon">&nbsp; {{'flowBuilder.CSV' | translate}}</a>
                </div>
              </button>

              <button (click)="zoomOut()" class="fullscreen btn-color" matTooltip = "{{'flowBuilder.zoom_out' | translate}}">
                <i class="fa fa-search-minus" aria-hidden="true"></i>
              </button>
              <button (click)="zoomIn()" class="fullscreen btn-color" matTooltip = "{{'flowBuilder.zoom_in' | translate}}">
                <i class="fa fa-search-plus" aria-hidden="true"></i>
              </button>
              <button (click)="resetZoom()" class="fullscreen btn-color" matTooltip = "{{'flowBuilder.reset_zoom' | translate}}">
                <img alt="" src="./assets/images/flowbuilder-icon/zoom-reset.svg" class="img5"  >
              </button>
              <button [disabled]="copyNode"  (click)="copyNodes()" class="fullscreen btn-color" matTooltip = "{{'flowBuilder.duplicate_selected_nodes' | translate}}">
                <i class="fa fa-clone"></i>
              </button>
              <button [disabled]="deleteedge"  (click)="deleteEdge()" class="fullscreen btn-color" matTooltip = "{{'flowBuilder.delete_Edge' | translate}}">
                <i class="fa fa-trash"></i>
              </button>
              <button matTooltip = "{{'flowBuilder.preview' | translate}}"  class="fullscreen btn-color" (click)="openDialog()"><i class="fa fa-eye"></i></button>
              <button class="fullscreen btn-color" (click)="changeLayout('horizontal')"  matTooltip = "{{'flowBuilder.horizontal_layout' | translate}}">
                <img alt="" src="./assets/images/flowbuilder-icon/flowchart_horizontal.svg" class="img5"  >
              </button>
              <button  class="fullscreen btn-color" (click)="changeLayout('vertical')" matTooltip = "{{'flowBuilder.Vertical_layout' | translate}}">
                <img alt="" src="./assets/images/flowbuilder-icon/flowchart_vertical.svg" class="img5"  >
              </button>
              <button class="custom-dropdown dropdown-selected btn-color" style="padding: 0;" >
                <a aria-label="url" class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" style = "padding: 2px 5px;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  (keydown)="onKeyDown($event)">
                  <div class="" (click)="toggleDropdown()" (keydown)="toggleDropdown()"  matTooltip = "{{'flowBuilder.Waypoints' | translate}}">
                    <img *ngIf="lineTypeItem" class="selectedLine" [src]="lineTypeItem.icon" alt="Selected Icon">
                  </div>
                </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item" *ngFor="let item of lineTypemenu; let i = index" [ngClass]="{'active': item.name === lineTypeItem.name}" (click)="selectItem(item)" (keydown)="selectItem(item)">
                    <img [src]="item.icon" alt="Item Icon">
                    {{ item.name }}</a>
                </div>
              </button>
              <button class="custom-dropdown dropdown-selected btn-color" style="padding: 0;" >
                <a aria-label="url" class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" style = "padding: 2px 15px;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  (keydown)="onKeyDown($event)">
                  <div class="" (click)="linePatternDropdown()" (keydown)="linePatternDropdown()" matTooltip = "{{'flowBuilder.Pattern' | translate}}">
                    <img *ngIf="linePatternItem" class="selectedLine" [src]="linePatternItem.icon" alt="Selected Icon">
                  </div>
                </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item" *ngFor="let item of linePatternItems" [ngClass]="{'active': item.name === linePatternItem.name}" (click)="selectPatternItem(item)">
                    <img [src]="item.icon" alt="Item Icon">
                    {{ item.name }}</a>
                </div>
              </button>


              <button  class="commonButton save-button" (click)="saveFlowbuilder()">

                <span class="text">{{'flowBuilder.save' | translate}}</span>
              </button>
              <div class="input-wrapper">
                <input type="text" style="padding: 5px 25px 5px 10px;width: 140px;"placeholder="{{'common.search_for' | translate}}" type="text" [(ngModel)]="searchQuery" (ngModelChange)="this.userQuestionUpdate.next($event)" />


              </div>
               <span class="fullscreen " *ngIf="numberOfNodes">
                 {{count+1}} of {{foundNode.length}}
               </span>

              <button  (click)="searchup()" class="fullscreen btn-color" >
                <i class="fa fa-arrow-down" ></i>
              </button>
              <button  (click)="searchdown()" class="fullscreen btn-color">
                <i class="fa fa-arrow-up" ></i>
              </button>

              <button id="capToDrag"  class="drag-item1 node-label">

                <span  class="dragdropLabelnodecount">{{'flowBuilder.Total_flow_nodes' | translate}}: {{totalnodecount}}</span>
              </button>

            </div>

            <div #slot_question id="capToDrag" class="drag-item node-label" mwlDraggable
                 dropData="slot_question"
                 dragActiveClass="drag-active"
                 [ghostDragEnabled]="true"
                 [showOriginalElementWhileDragging] ="true"
                 [ghostElementTemplate] ="draggingS"
                 [dragSnapGrid]="snapping"
                 (dragging)="dragging($event, 'slot_question')"
                 matTooltip = "{{'flowBuilder.slot_question' | translate}}">
                  <div>
                    <img src="assets/images/flowbuilder-icon/{{themeNode}}/slot-question.svg" alt="" width="20" *ngIf="themeNode">
                  </div>
                  <div>
                    <p class="dragdropLabel"> {{'flowBuilder.slot_question' | translate}}</p>
                    <p class="count">{{'flowBuilder.count' | translate}}: {{nodeCounts.slot_question ? nodeCounts.slot_question: 0  }}</p>
                  </div>


            </div>
            <div #menu_question id="indToDrag" class="drag-item node-label" mwlDraggable
                 dropData="menu_question"
                 dragActiveClass="drag-active"
                 [ghostDragEnabled]="true"
                 [showOriginalElementWhileDragging] ="true"
                 [ghostElementTemplate] ="draggingS"
                 [dragSnapGrid]="snapping"
                 (dragging)="dragging($event, 'menu_question')"
                 matTooltip = "{{'flowBuilder.menu_question' | translate}}">
                <div>
                  <img src="assets/images/flowbuilder-icon/{{themeNode}}/menu-response.svg" alt="" width="20" *ngIf="themeNode">
                </div>
                <div>
                  <p class="dragdropLabel"> {{'flowBuilder.menu_question' | translate}}</p>
                  <p class="count">{{'flowBuilder.count' | translate}}: {{nodeCounts.menu_question ? nodeCounts.menu_question : 0 }}</p>
                </div>

            </div>
            <div #menu_item id="menuItemDrag" class="drag-item node-label" mwlDraggable
                 dropData="menu_item"
                 dragActiveClass="drag-active"
                 [ghostDragEnabled]="true"
                 [showOriginalElementWhileDragging] ="true"
                 [ghostElementTemplate] ="draggingS"
                 [dragSnapGrid]="snapping"
                 (dragging)="dragging($event, 'menu_item')"
                 matTooltip = "{{'flowBuilder.menu_item' | translate}}">
              <div>
                <img src="assets/images/flowbuilder-icon/{{themeNode}}/menu-item.svg" alt="" style="margin-right: 6px;" width="18" *ngIf="themeNode">
              </div>
              <div>
                <p class="dragdropLabel"> {{'flowBuilder.menu_item' | translate}}</p>
                <p class="count">{{'flowBuilder.count' | translate}}: {{nodeCounts.menu_item ? nodeCounts.menu_item : 0}}</p>
              </div>
            </div>
            <div #conditional_response id="condToDrag" class="drag-item node-label"
                 mwlDraggable
                 dropData="conditional_response"
                 dragActiveClass="drag-active"
                 [ghostDragEnabled]="true"
                 [showOriginalElementWhileDragging] ="true"
                 [ghostElementTemplate] ="draggingS"
                 [dragSnapGrid]="snapping"
                 (dragging)="dragging($event, 'conditional_response')"
                 matTooltip = "{{'flowBuilder.conditional_response' | translate}}">
              <div>
                <img src="assets/images/flowbuilder-icon/{{themeNode}}/conditional-response.svg"  alt="" width="20" *ngIf="themeNode">
              </div>
              <div>
                <p class="dragdropLabel"> {{'flowBuilder.conditional_response' | translate}}</p>
                <p class="count">{{'flowBuilder.count' | translate}}: {{nodeCounts.conditional_response ? nodeCounts.conditional_response : 0}}</p>
              </div>
            </div>
            <div #condition id="conditionToDrag" class="drag-item node-label"
                 mwlDraggable
                 dropData="condition"
                 dragActiveClass="drag-active"
                 [ghostDragEnabled]="true"
                 [showOriginalElementWhileDragging] ="true"
                 [ghostElementTemplate] ="draggingS"
                 [dragSnapGrid]="snapping"
                 (dragging)="dragging($event, 'condition')"
                 matTooltip = "{{'flowBuilder.condition' | translate}}">
              <div>
                <img src="assets/images/flowbuilder-icon/{{themeNode}}/condition.svg"  alt="" width="20" *ngIf="themeNode">
              </div>
              <div>
                <p class="dragdropLabel"> {{'flowBuilder.condition' | translate}}</p>
                <p class="count">{{'flowBuilder.count' | translate}}: {{nodeCounts.condition ? nodeCounts.condition : 0}}</p>
              </div>
            </div>
            <div #end_response id="endresToDrag" class="drag-item node-label"
                 mwlDraggable
                 dropData="end_response"
                 dragActiveClass="drag-active"
                 [ghostDragEnabled]="true"
                 [showOriginalElementWhileDragging] ="true"
                 [ghostElementTemplate] ="draggingS"
                 [dragSnapGrid]="snapping"
                 (dragging)="dragging($event, 'end_response')"
                 matTooltip = "{{'flowBuilder.end_response' | translate}}">
              <div style="margin-left: 10px;">
                <img src="assets/images/flowbuilder-icon/{{themeNode}}/end-response.svg"  alt="" width="20" *ngIf="themeNode">
              </div>
              <div>
                <p class="dragdropLabel"> {{'flowBuilder.end_response' | translate}}</p>
                <p class="count">{{'flowBuilder.count' | translate}}: {{nodeCounts.end_response ? nodeCounts.end_response : 0}}</p>
              </div>
            </div>

          </div>
          <div id="cy" #graphArea [ngStyle]="{'width': childWidthMethod() + 'px','height': childHeightMethod() + 'px'}" (click)="evtListener()"
              (keydown)="evtListener()"
               mwlDroppable
               (drop)="onDrop($event)"
               dragOverClass="drop-over-active"
               (dragOver)="dragEnter($event)"
               (dragLeave) = "dragLeave($event)"

          ></div>
        </div>
        <div class="navigation-container">
          <button (click)="navigateUp()" class="fullscreen nav-button">
            <i class="fas fa-angle-up"></i>
          </button>
          <div class="horizontal-buttons">
            <button (click)="navigateLeft()" class="fullscreen nav-button">
              <i class="fas fa-angle-left"></i>
            </button>
            <button (click)="navigateRight()" class="fullscreen nav-button">
              <i class="fas fa-angle-right"></i>
            </button>
          </div>
          <button (click)="navigateDown()" class="fullscreen nav-button">
            <i class="fas fa-angle-down"></i>
          </button>
        </div>

      </mat-drawer-content>
    </mat-drawer-container>


  </div>

  <ng-template #draggingS>
    <div class="DragPreview " >
      <img src="../../../../assets/images/flowbuilder-icon/{{themeNode}}/slot-question.svg" style="margin:10px; width:15px;" alt="" width="16" *ngIf="nodeType === 'slot_question' ">
      <img src="../../../../assets/images/flowbuilder-icon/{{themeNode}}/menu-response.svg" style="margin:10px" alt="" width="16" *ngIf="nodeType === 'menu_question' ">

      <img src="../../../../assets/images/flowbuilder-icon/{{themeNode}}/menu-item.svg" style="margin:10px" alt="" width="16" *ngIf="nodeType === 'menu_item' ">
      <img src="../../../../assets/images/flowbuilder-icon/{{themeNode}}/conditional-response.svg" style="margin:10px" alt="" width="16" *ngIf="nodeType === 'conditional_response' ">
      <img src="../../../../assets/images/flowbuilder-icon/{{themeNode}}/condition.svg" style="margin:10px" alt="" width="16" *ngIf="nodeType === 'condition' ">
      <img src="../../../../assets/images/flowbuilder-icon/{{themeNode}}/end-response.svg" style="margin:10px" alt="" width="16" *ngIf="nodeType === 'end_response' ">
    </div>
  </ng-template>
</div>

<ng-template #popUpTemplate>
  <div class="card" style="border:none;max-height: 250px;width:250px;overflow-y: scroll;">
    <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
      <p class="card-text" style="font-size: 12px;" [innerHtml]="tooltipText">
      </p>
    </div>
  </div>
</ng-template>
<ng-template #popUpTemplatemultiple>
	<div class="card" style="border:none;max-height: 250px;">
		<div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
			<p class="card-text" style="font-size: 12px;" innerHtml="{{'content_elements.attachment_info' | translate}}">
			</p>
		</div>
	</div>
  </ng-template>
  <ng-template #popUpTemplatemultiplemenu>
    <div class="card" style="border:none;max-height: 250px;">
      <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
        <p class="card-text" style="font-size: 12px;" innerHtml="{{'content_elements.attachment_info_img' | translate}}">
        </p>
      </div>
    </div>
    </ng-template>
    <ng-template #infoForPhoneNumber>
      <div class="card" style="border:none;max-height: 250px;">
        <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
          <p class="card-text" style="font-size: 12px;" innerHtml="{{'flowBuilder.infoForPhoneNumber' | translate}}">
          </p>
        </div>
      </div>
      </ng-template>
    <ng-template #popUpCurrency>
      <div class="card" style="border:none;max-height: 250px;width:250px;overflow-y: scroll;">
        <div class="card-body" style="padding: 0px !important;">
          <p class="card-text" style="font-size: 12px;" innerHtml="{{'common.currencyInfo' | translate}}">
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template #popUpHidemenu>
      <div class="card" style="border:none;max-height: 250px;width:250px;overflow-y: scroll;">
        <div class="card-body" style="padding: 0px !important;">
          <p class="card-text" style="font-size: 12px;" innerHtml="{{'flowBuilder.hidemenuInfo' | translate}}">
          </p>
        </div>
      </div>
    </ng-template>
