import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';
import { EnvironmentService } from 'src/app/environment.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';
import { SharedsService } from 'src/app/shareds/shareds.service';

@Injectable({
  providedIn: 'root'
})
export class RichMenuService {
  apiUrl: string;
  httpOptions: any;

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService, private readonly sharedService: SharedsService, private readonly expService: ExceptionHandlerService
  ) { 
    this.apiUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };

  }
  addRichMenu(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    return this.http.post<any>(`${this.apiUrl}/dashboard/richmenu/save`, args,
        this.httpOptions).pipe(
            tap(),
            catchError((error) => {
              this.expService.handleError(error);
                return of(error);
            })
        );
  }
  listRichMenu( args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', args.appId )
      .set('search', args.search)
      .set('limit', args.limit)
      .set('skip', args.skip);
    return this.http.get<any>(`${this.apiUrl}/dashboard/richmenu/list`,
      this.httpOptions).pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  updateRichMenu(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    return this.http.post<any>(`${this.apiUrl}/dashboard/richmenu/update`, args,
      this.httpOptions).pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  deleteRichMenu(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = new HttpParams();
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/richmenu/delete`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
}
