import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rag',
  templateUrl: './rag.component.html',
  styleUrls: ['./rag.component.scss']
})
export class RagComponent {
  loadershow = false;
  imageUploadStatus = false;
  selectedRAGTab = 'document';
  selectedFile1: any = '';
  uploadLoader = false;
  uploadedFile = false;
  userSubscription1: Subscription;
  userSubscription2: Subscription;
  userSubscription3: Subscription;
  docName = '';
  documentUrl: any;
  uploadCheck = false;
  uploadedFiles: any[] = [];
  uploadedUrlFiles: any[] = [];
  urlName = '';
  uploadUrlLoader = false;
  uploadUrlFile = false;
  uploadUrlCheck = false;
  projectId: any;
  documentFiles: any;
  websiteFiles: any;
  ragList: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  rag: any = {
    ragEnabled: '',
  };
  ragEnabled = false;
  toastr: ToastrService;
  _settingsService: SettingsService;
  translate: TranslateService;


  constructor(private readonly injector: Injector,
    private readonly http: HttpClient) {
      this.toastr = this.injector.get<ToastrService>(ToastrService);
      this._settingsService = this.injector.get<SettingsService>(SettingsService);
      this.translate = this.injector.get<TranslateService>(TranslateService);
      this.translate.setDefaultLang('en');

    }
    ngOnInit() {
      this.listAllUrlFiles();
    }


  selectedRAGChange(value) {
    this.selectedRAGTab = value;
  }


  handleFileUpload(event: any): void {
    console.log('events', event.target.files[0].type);
    this.selectedFile1 = event.target.files[0];
    const reader1 = new FileReader();

    reader1.readAsBinaryString(this.selectedFile1);

    reader1.onload = async (onloadEvent: any) => {
      const Data = onloadEvent.target.result;
      const pageCount = Data.match(/\/Type\s*\/Page\b/g)?.length;
      console.log('pdf.getPageCount>>', pageCount);
      if (pageCount > 250) {
        this.toastr.error('page count is above 250');
        this.selectedFile1 = '';
      } else if (event?.target?.files?.length && event.target.files[0].type !== 'application/pdf') {
        this.selectedFile1 = '';
        this.toastr.error('Please upload valid pdf document');
      } else if (this.selectedFile1.size > 31457280) {
        this.selectedFile1 = '';
        this.toastr.error('Maximum file size must be less than 30MB');
      } else {
        this.uploadLoader = true;
        this.uploadedFile = true;
        this.uploadFile(event);
      }
    };
  }


  uploadFile(event) {
    const appUser = JSON.parse(localStorage.getItem('user'));
    console.log('idddddd', typeof (appUser), appUser.appid);

    const Editfiles = event.target.files;
    const file = Editfiles[0];
    const file2 = Editfiles[0]?.name;
    const pattern = /pdf-*/;
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async (onloadEvent: any) => {
      const Data = onloadEvent.target.result;
      const contentData = btoa(Data);
      const args = {
        data: contentData,
        contentType: file.type,
        filename: file2,
        appId: appUser.appid,
        type: 'document'
      };
      this.userSubscription1 = this._settingsService
        .getFiles(args)
        .subscribe((res) => {
          if (res) {
            console.log('this.docName', this.docName);
            this.selectedFile1 = '';
            this.uploadLoader = false;
            console.log('resss', res.info);
            console.log('connectionStatus', res.info.connectionStatus);
            this.listAllUrlFiles();
            this.documentUrl = res.info.documentUrl;
            this.uploadedFile = true;
            this.uploadCheck = true;
            this.emptyFileInput();
            // Push relevant data into the array
            this.uploadedFiles.push({
              filename: file2,
              documentUrl: res.info.documentUrl
            });
            this.toastr.success('Document uploaded successfully');
          }

          if (!file.type.match(pattern)) {
            this.toastr.error('', this.translate.instant('toastr.invalid_format'));
            return false;
          }
        });
    };
  }

  train() {
    if (this.urlName) {
      // Regular expression for URL validation
      const urlRegex = /^(https?:\/\/|www\.)[^ "]+$/;
      if (urlRegex.test(this.urlName)) {
        console.log('Valid URL:', this.urlName);
        // Proceed with uploading and training
        this.uploadUrlLoader = true;
        const appUser = JSON.parse(localStorage.getItem('user'));
        const args = {
          data: this.urlName,
          contentType: 'text',
          filename: this.urlName,
          appId: appUser.appid,
          type: 'website'
        };
        this.userSubscription2 = this._settingsService
          .getFiles(args)
          .subscribe((res) => {
            if (res) {
              if (res.info.connectionStatus === 200) {
                this.urlName = '';
                this.listAllUrlFiles();
                this.uploadUrlLoader = false;
                this.documentUrl = res.info.documentUrl;
                this.uploadUrlFile = true;
                this.uploadUrlCheck = true;
                // Push relevant data into the array
                this.uploadedUrlFiles.push({
                  filename: this.urlName,
                  documentUrl: res.info.documentUrl
                });
                this.toastr.success('Url uploaded successfully');
                console.log(this.uploadedUrlFiles);
              } else {
                this.urlName = '';
                this.uploadUrlLoader = false;
                this.toastr.error('Url already trained');
              }
            }
          });
      } else {
        this.toastr.error('Invalid URL');
        console.log('Invalid URL:', this.urlName);
      }
    }
  }

  onKey(event: any) {
    this.urlName = event.target.value;
  }
  onKeyDocument(event: any) {
    this.docName = event.target.value;
    console.log('this.docName1', this.docName);
  }


  listAllUrlFiles() {
    this.userSubscription3 = this._settingsService
      .getTrailAccountDetails()
      .subscribe((res) => {
        if (res?.info?.ragDocuments) {
          this.projectId = res.info._id;
          // Separate arrays for documents and websites
          this.documentFiles = [];
          this.websiteFiles = [];
          // Iterate over the ragDocuments array
          res.info.ragDocuments.forEach((document, index) => {
            if (document.type === 'document') {

              // Add document to the documentFiles array
              this.documentFiles.push(document);
              console.log(this.documentFiles, 'documentFiles');
            } else if (document.type === 'website') {
              // Add website to the websiteFiles array
              this.websiteFiles.push(document);
            }
            // Log each filename with type
            console.log(`File ${index + 1}: ${document.filename} - Type: ${document.type || 'Unknown'}`);
          });
          // Use the documentFiles and websiteFiles arrays as needed
          console.log('Document Files:', this.documentFiles);
          console.log('Website Files:', this.websiteFiles);
          this.ragList = res.info.ragDocuments.length;
          this.ragEnabled = res.info.ragEnabled;
        }
      });
  }

  deleteRagDocument(ragDocumentId: string, item: any) {
    const args = {
      projectId: this.projectId,
      ragDocumentId: ragDocumentId
    };
    this._settingsService.deleteRagDocument(args).subscribe(
      (response) => {
        if (response.connectionStatus === 200) {
          if (item.trainingStatus === true) {
            this.toastr.success(
              '',
              this.translate.instant('toastr.deleted_document')
            );
          } else {
            this.toastr.success(
              '',
              this.translate.instant('toastr.error_delete_file')
            );
          }
        } else if (item.trainingStatus === false) {
          this.toastr.success(
            '',
            this.translate.instant('toastr.error_delete_file')
          );
        }
        this.listAllUrlFiles();
      },
    );
  }
  emptyFileInput() {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }


}
