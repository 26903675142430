import {Component, HostListener, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { QuickreplyService } from './quickreply.service';
declare let Swal: any;

@Component({
  selector: 'app-quickreply',
  templateUrl: './quickreply.component.html',
  styleUrls: ['./quickreply.component.scss']
})
export class QuickreplyComponent implements OnInit {
  isOpen = false;
  action = 'View';
  addbutton = false;
  quickreplyForm: UntypedFormGroup;
  actionHeader = ' Quick Reply';
  screenWidth: number;
  submitted = false;
  selecteUser: any;
  appId: string;
  translatorLanguage: any;
  quickReplyList: any;
  loadershow = false;
  trialUsersTotal: any;
  displayedColumns: string[] = [
    'Quick Reply',
    'Added By',
    'Last Modified',
    'Status',
  ];
  user: any;
  searchvalue: string;
  paginationDisabled = false;
  status: boolean;
  pagelist = 1;
  totalCount: any;
  search: any;
  user_id: any;
  dateFormat3: any;
  screenHeight: number;
  itemsPerPage: number;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly quickreplyService: QuickreplyService,
  ) {
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
  }

  ngOnInit() {
    this.loadershow = true;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.itemsPerPage = this.getScreenHeightValue(this.screenHeight);
    this.quickreplyForm = this.fb.group({
      quickreply: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    this.appId =  this.user.appid ? this.user.appid : '';
    this.user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    this.user_id = this.user.user_id ? this.user.user_id : '';
    this.dateFormat3 = this.user.dateFormat ? this.user.dateFormat : 'MM/dd/YYYY';
    const arge = {
      appId: this.appId,
      limit: this.itemsPerPage,
      skip: 0,
      search: '',
    };
    this.getQuickReplyList(arge);
  }
  // HostListener listens to window resize events
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenHeight = window.innerHeight;
    this.itemsPerPage = this.getScreenHeightValue(this.screenHeight);
    const arge = {
      appId: this.appId,
      limit: this.itemsPerPage,
      skip: 0,
      search: '',
    };
    this.getQuickReplyList(arge);
  }
  getScreenHeightValue(h: number): number {
    return h > 1700 ? 40 : Math.max(Math.round((h * 4) / 300), 10);
  }

  addQuickReplyButton() {
    this.action = 'Create';
    this.isOpen = true;
    this.addbutton = true;
    this.quickreplyForm.reset();
  }
  checkWindowSize() {
    if (this.screenWidth > 992) {
      return false;
    } else {
      return true;
    }
  }
  showQuickReplyDetails(element) {
    this.submitted = false;
    this.isOpen = true;
    this.addbutton = false;
    this.action = 'View';
    this.selecteUser = element;
  }
  initResponsiveElements() {
    this.screenWidth = window.screen.width;
  }
  getQuickReplyList(args) {
    this.quickreplyService.getQuickReplyList(args).subscribe(
      (data) => {
        this.loadershow = false;
        this.quickReplyList = data?.info;
        this.trialUsersTotal = data?.totalcount;
        if (this.quickReplyList && this.quickReplyList.length > 0) {
          this.showQuickReplyDetails(this.quickReplyList[0]);
        } else {
          this.quickreplyForm.reset();
          this.isOpen = false;
        }
      });
  }

  onSubmit() {
    console.log('success');
    this.submitted = true;
    let args1 = {};
    this.action = 'Create';
    if (this.quickreplyForm.invalid) {
      return;
    } else if (this.action === 'Create') {
      args1 = {
        'title': this.quickreplyForm.value.quickreply,
        'description': this.quickreplyForm.value.description,
        'status': false,
        'user_id': this.user_id
      };
      console.log('args1', args1);
      this.quickreplyService.addQuickReply(args1).subscribe(
        (response) => {
          if (response.status === 200) {
            this.action = 'View';
            this.addbutton = false;
            this.toastr.success('', response.info);
            const arge = {
              appId: this.appId,
              limit: this.itemsPerPage,
              skip: 0,
              search: '',
            };
            this.getQuickReplyList(arge);
            this.quickreplyForm.reset();
            if (this.quickReplyList && this.quickReplyList.length > 0) {
              this.showQuickReplyDetails(this.quickReplyList[0]);
            }
          } else if (response.status === 1013) {
            this.toastr.error('', response?.info ?? response.message);
          } else {
            this.toastr.error('', response?.info ?? response?.error?.message);
          }
        },
        (err) => {
          this.toastr.error('', this.translate.instant('toastr.error_adding_contact'));
        }
      );
    }
  }


  searchQuickReplyUsers(value: any) {
    this.search = value;
    const arg = {
      limit: this.itemsPerPage,
      skip: 0,
      appId: this.appId,
      search: value
    };
    this.paginationDisabled = true;
    this.loadershow = true;
    this.quickreplyService.searchQuickReplyUsers(value, arg).subscribe(
      (data) => {
        this.paginationDisabled = false;
        this.loadershow = false;
        this.quickReplyList = data?.info;
        this.trialUsersTotal = data?.total;
      },
      (error) => {
      }
    );
  }

  updateQuickReply(args) {
      this.quickreplyService.updateQuickReply(args).subscribe(response => {
        if (response.status === 200) {
          this.action = 'View';
          this.addbutton = false;
          this.toastr.success('', response.info);
          const arge = {
            appId: this.appId,
            limit: this.itemsPerPage,
            skip: 0,
            search: '',
          };
          this.getQuickReplyList(arge);
        } else {
          this.toastr.error('', response.info);
          this.loadershow = false;
        }
      });
  }

  update() {
    this.action = 'Update';
    this.quickreplyForm.patchValue({
      quickreply: this.selecteUser.title,
      description: this.selecteUser.description
    });
  }

    openConfirmationDialog(obj) {
    Swal.fire({
      title:
        '<i class="fa fa-exclamation-triangle" style="color:red" aria-hidden="true"></i> &nbsp;Are you sure you want to delete this?',
      showCancelButton: true,
      customClass: 'swal-wide',
      confirmButtonColor: '#333333',

    }).then((result) => {
      if (result.value) {
        this.deleteQuickReply();
      }
    });
  }

  deleteQuickReply() {
    const args1 = {
      '_id': this.selecteUser._id,
      'title': this.quickreplyForm.value.quickreply,
      'description': this.quickreplyForm.value.description,
      'status': false,
      'user_id': this.user_id
    };
    this.quickreplyService.deleteQuickReply(args1).subscribe(response => {
      if (response.status === 200) {
        this.action = 'View';
        this.addbutton = false;
        this.toastr.success('', response.info);
        const arge = {
          appId: this.appId,
          limit: this.itemsPerPage,
          skip: 0,
          search: '',
        };
        this.getQuickReplyList(arge);
        this.quickreplyForm.reset();
      } else {
        this.toastr.error('', response.info);
      }
    });
  }
  onSwitchChanged(newStatus, element) {
    console.log('onSwitchChanged>>>>>');
    element.status = newStatus;
    this.loadershow = true;
    const args1 = {
      '_id': element._id,
      'title': element.title,
      'description': element.description,
      'status': newStatus,
      'user_id': this.user_id
    };
    this.updateQuickReply(args1);
  }
  updateQuickReplyRow() {
    this.loadershow = true;
    const args1 = {
      '_id': this.selecteUser._id,
      'title': this.quickreplyForm.value.quickreply,
      'description': this.quickreplyForm.value.description,
      'status': this.selecteUser.status,
      'user_id': this.user_id
    };
    console.log('updateQuickReplyRow.arg!!!!', args1);
    this.updateQuickReply(args1);
  }
  pageChange(page) {
    this.pagelist = page;
    const args1 = {
      limit: this.itemsPerPage,
      skip: this.pagelist * this.itemsPerPage - this.itemsPerPage,
    };
    this.getQuickReplyList(args1);
  }

  onCancel() {
    this.isOpen = false;
    this.addbutton = false;
    this.quickreplyForm.reset();
    if (this.quickReplyList && this.quickReplyList.length > 0) {
      this.showQuickReplyDetails(this.quickReplyList[0]);
    }
  }
}
