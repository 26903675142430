import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';

@Injectable({
  providedIn: 'root',
})
export class BroadcastmessageService {
  apiUrl: string;
  httpOptions: any;
  userName: any;
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly sharedService: SharedsService,
    private readonly expService: ExceptionHandlerService
  ) {
    this.apiUrl = this.envService.read('apiUrl');
    this.userName = JSON.parse(localStorage.getItem('name'));
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };
  }

  getSendCount(arg): Observable<any> {
    this.httpOptions.params = null;
    arg.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(`${this.apiUrl}/broadcast/limit`, arg, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getTemplateList(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    )
    .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/list/template`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  broadcastMessage(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/send/broadcast/message`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  broadcastEmailMessage(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    args.connection_device = 'email';
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/send/email-broadcast/message`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  broadcastTelegramMesssage(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    args.user_name = this.userName;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/send/broadcast/channel/schedule`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  broadcastScheduleMesssage(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/schedule/broadcast/message`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  broadcastList(args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('limit', args.limit)
      .set('skip', args.skip)
      .set('sort', args.sort ? args.sort : '')
      .set('sortorder', args.sortorder ? args.sortorder : '')
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/broadcast/list`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  emailBroadcastList(args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('limit', args.limit)
      .set('skip', args.skip)
      .set('search', args.search)
      .set('sort', args.sort ? args.sort : '')
      .set('sortorder', args.sortorder ? args.sortorder : '')
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/broadcast/email-list`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  listallCampaign(args): Observable<any> {
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(`${this.apiUrl}/v1/list/allcampaign`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  updateBroadcast(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/scheduled/broadcast/update`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  deleteBroadcast(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/scheduled/broadcast/delete`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getBroadcastSearch(args, search): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('limit', args.limit)
      .set('skip', args.skip)
      .set('search', search)
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/broadcast/list`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  listBroadcastUsers(args): Observable<any> {
    let broadcast_id = '';
    if (args.broadcast_id) {
      broadcast_id = args.broadcast_id;
    } else if (args.broadcastuserlistID) {
       broadcast_id = args.broadcastuserlistID;
    } else {
      //
    }
    this.httpOptions.params = new HttpParams()
      .set('limit', args.limit)
      .set('skip', args.skip)
      .set('broadcast_id', broadcast_id)
      .set('campaign_id', args.campaign_id)
      .set('appId', this.sharedService.appId)
      .set('isDownload',  args?.isDownload ?? false)
      .set('timeZone', this.sharedService.timeZone);
      return this.http
      .get<any>(
        `${this.apiUrl}/broadcast/template/users/list`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  listEmailBroadcastUsers(args): Observable<any> {
    let broadcast_id = '';
    if (args.broadcast_id) {
      broadcast_id = args.broadcast_id;
    } else if (args.broadcastuserlistID) {
      broadcast_id = args.broadcastuserlistID;
    } else {
      //
    }
    this.httpOptions.params = new HttpParams()
      .set('limit', args.limit)
      .set('skip', args.skip)
      .set('broadcast_id', broadcast_id)
      .set('campaign_id', args.campaign_id)
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/broadcast/email-users`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getbroadcastUserSearch(args, search): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('limit', args.limit)
      .set('skip', args.skip)
      .set('search', search)
      .set('broadcast_id', args.broadcastuserlistID)
      .set('campaign_id', args.campaign_id)
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/broadcast/template/users/list`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  listCampaignUsers(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/v1/list/campaign/users`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  broadcastMessageRecipient(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    args.broadcast_type = 'Now';
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/send/single_broadcast/message`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  broadcastMessageTag(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/send/tag/broadcast/message`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  broadcastScheduleTag(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    args.broadcast_type = 'schedule';
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/schedule/tag/broadcast/message`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }


  broadcastScheduleRecipient(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    args.broadcast_type = 'schedule';
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/schedule/single_broadcast/message`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getDistinctChannels(): Observable<any> {
    this.httpOptions.params = new HttpParams()
    .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/get/broadcast/channel/message`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  eligibleContactCount(arg): Observable<any> {
    if (arg.tagId && arg.toDate) {
      this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('type', arg.type)
      .set('tagId', arg.tagId)
      .set('fromDate', arg.fromDate)
      .set('toDate', arg.toDate)
      .set('timeZone', this.sharedService.timeZone);
    } else if (arg.tagId ) {
      this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('type', arg.type)
      .set('tagId', arg.tagId);
    } else {
      this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('type', arg.type)
      .set('timeZone', this.sharedService.timeZone);
    }
    return this.http
      .get<any>(
        `${this.apiUrl}/broadcast/user/count`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  sendNowScheduledMessage(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/send/now/scheduled/broadcast/message`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  listallTags(args): Observable<any> {
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(`${this.apiUrl}/dashboard/tag/list`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  flexBroadcastMessage(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/send/flex/message/user`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  listCRMCampaign(): Observable<any> {
    this.httpOptions.params = new HttpParams()
    .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/hubspot/contactlist`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
}
