//  the functional form is to make a copy every time.
export const LayoutAreas = () => ({
  1: [
    {areaId: 1, styles: {inset: 0}, },
  ],
  2: [
    {areaId: 1, styles: {left: 0, top: 0, width: '50%', height: '100%', }},
    {areaId: 2, styles: {right: 0, top: 0, width: '50%', height: '100%', }},
  ],
  3: [
    {areaId: 1, styles: {left: 0, top: 0, width: '100%', height: '50%', }},
    {areaId: 2, styles: {left: 0, bottom: 0, width: '100%', height: '50%', }},
  ],
  4: [
    {areaId: 1, styles: {left: 0, top: 0, width: '100%', height: '33.3%', }},
    {areaId: 2, styles: {left: 0, top: '33.3%', width: '100%', height: '33.3%', }},
    {areaId: 3, styles: {left: 0, bottom: 0, width: '100%', height: '33.3%', }},
  ],
  5: [
    {areaId: 1, styles: {left: 0, top: 0, width: '50%', height: '50%', }},
    {areaId: 2, styles: {right: 0, top: 0, width: '50%', height: '50%', }},
    {areaId: 3, styles: {left: 0, bottom: 0, width: '50%', height: '50%', }},
    {areaId: 4, styles: {right: 0, bottom: 0, width: '50%', height: '50%', }},
  ],
  6: [
    {areaId: 1, styles: {left: 0, top: 0, width: '100%', height: '50%', }},
    {areaId: 2, styles: {left: 0, bottom: 0, width: '50%', height: '50%', }},
    {areaId: 3, styles: {right: 0, bottom: 0, width: '50%', height: '50%', }},
  ],
  7: [
    {areaId: 1, styles: {left: 0, top: 0, width: '100%', height: '50%', }},
    {areaId: 2, styles: {left: 0, top: '50%', width: '100%', height: '25%', }},
    {areaId: 3, styles: {left: 0, bottom: 0, width: '100%', height: '25%', }},
  ],
  8: [
    {areaId: 1, styles: {left: 0, top: 0, width: '33.3%', height: '50%', }},
    {areaId: 2, styles: {left: '33.3%', top: 0, width: '33.3%', height: '50%', }},
    {areaId: 3, styles: {right: 0, top: 0, width: '33.4%', height: '50%', }},
    {areaId: 4, styles: {left: 0, bottom: 0, width: '33.3%', height: '50%', }},
    {areaId: 5, styles: {left: '33.3%', bottom: 0, width: '33.3%', height: '50%', }},
    {areaId: 6, styles: {right: 0, bottom: 0, width: '33.4%', height: '50%', }},
  ],
});
