import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Injector,
  ChangeDetectorRef,
  Renderer2,
  AfterViewInit, OnDestroy,
} from '@angular/core';
import { MatSidenav  } from '@angular/material/sidenav';
import { ToastrService  } from 'ngx-toastr';
import cytoscape from 'cytoscape';
import * as jquery from 'jquery';
// @ts-ignore
import gridGuide from 'cytoscape-grid-guide';
// @ts-ignore
import contextMenus from 'cytoscape-context-menus';
import edgehandles from 'cytoscape-edgehandles';
import popper from 'cytoscape-popper';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FlowBuilderService} from '../flow-builder/flow-builder.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { stylesheetObject } from './stylesheetObject';
import {QuillEditorComponent} from 'ngx-quill';
import {RangeStatic} from 'quill';
import { ConversationService } from '../conversation/conversation.service';
import {UsersService} from '../users/users.service';
import {SharedsService} from '../../../shareds/shareds.service';
import {Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import {NlpService} from '../../../shareds/nlp.service';
import cytoscapeSvg from 'cytoscape-svg';
import dagre from 'cytoscape-dagre';
import {MatDialog } from '@angular/material/dialog';
import { FlowbuilderPreviewComponent } from './flowbuilder-preview/flowbuilder-preview.component';
import { GetPaymentCountryList } from '../settings/countryList';
import {ImageLayoutComponent} from '../image-layout/image-layout.component';
declare let Swal: any;

gridGuide( cytoscape, jquery );
cytoscape.use(edgehandles);
cytoscape.use(contextMenus);
cytoscape.use(popper);
cytoscape.use(cytoscapeSvg);
cytoscape.use(dagre);
export interface Comp {
  node_type?: string;
  label?: string;
  node_id?: any;
  flow_id?: any;
  image_details: any;
  condition_operator: any;
  condition_check: any;
  variableArray: any;
  user_attribute: any;
  prev_message: any;
  node_name: any;
  submit_action: any;
  next_message: any;
  condition_id: any;
  condition_value: any;
  nodePosition: any;
  conditionCheckNodeName: any;
  flowNodeUsageCount?: any;
  groupId: any;
  sortNumber: any;
  hideMenu: any;
  authentication: any;
  removed_data: any;
  conditionDetails : any;
}

export interface Cord {
  x?: number;
  y?: number;
}

interface ConditionList {
  name: string;
  value: string;
}
@Component({
  selector: 'app-flowbuilder-v3',
  templateUrl: './flowbuilder-v3.component.html',
  styleUrls: ['./flowbuilder-v3.component.scss']

})
export class FlowbuilderV3Component implements OnInit, AfterViewInit, OnDestroy  {
  public markComponent = 'none';
  public cy: any;
  gridSpacing = 20;
  private dropdown: HTMLElement;
  @ViewChild('cy') cyContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('graphArea') graphArea: ElementRef;
  @ViewChild('parentDiv') parentDiv: ElementRef;
  childWidth: number;
  childHeight: number;
  public eh: any;
  private numRes = 1;
  private linkPosition = -100;
  public value!: string;
  public value1!: string;
  public remNode!: string;
  private options: any;
  userQuestionUpdate = new Subject<string>();
  color = 'red';
  searchQuery: string;
  saveMessage: any = 0;
  dragEndSubject: Subject<string> = new Subject<string>();
  public transform!: Cord;
  loaderShow = false;
  translatorLanguage: any;
  flowbuilderId: any;
  route: ActivatedRoute;
  router: Router;
  converstaionService: ConversationService;
  _nlpService: NlpService;
  childNodesShow = false;
  nodetypeName: string;
  nodetype: string;
  node_type = '';
  conditionForm: UntypedFormGroup;
  updateemailForm: UntypedFormGroup;
  menuForm: UntypedFormGroup;
  emailForm: UntypedFormGroup;
  conditionalResponseType = '';
  selectedConditionalResponseValue;
  isNumeric = false;
  arabic_lan = false;
  node_menuerrormsg: string;
  node_valueErrorMsg: string;
  variableType: string;
  parent_qa: any = '';
  userVariable = true;
  public isEmojiPickerVisible: boolean;
  projectVariable = true;
  systemVariable = true;
  titleDrop: string;
  variablesList: any;
  conditionalResponseVariableList: any = [];
  userAttributeOptions: any = [];
  userAttributeConfig: any = {};
  variables: any;
  idErrormsg: string;
  nodeName: string;
  image_url = '';
  fileType = '';
  image_title = '';
  contentType = '';
  page = 1;
  limit = 10;
  copiedNodeData = [];
  updateConnectToFlow;
  updateConnectToFlowId;
  matchingElement;
  flowbuilderListData: any = [];
  flowbuilderListDatalist: any = [];
  NameConfig: any = [];
  uploadloader = false;
  updateactiontype: string;
  agentGroupList: any;
  usersService: UsersService;
  sharedService: SharedsService;
  updateAgentGroup: any;
  routingAgent: any;
  agentGrouperrormsg = '';
  emailerror: any = [];
  parenterrormsg = '';
  paymentErrormsg:any = '';
  checkWith = 'api_response';
  userInputNode: string;
  parentNodeErrorMsg = '';
  selectedNodeData: any;
  actiontype: any;
  previousNode: any;
  node_id: any;
  conditionalResponseValue = '';
  conditionalId = '';
  nodeIdentifier: string;
  userData: any;
  theme: string;
  themeNode: string;
  nodeDataType: string;
  deleteedge = true;
  evtTarget: any;
  totalnodecount: any;
  isEdgeClicked = false;
  count = 0;
  Query: any;
  foundNode: any = [];
  numberOfNodes = false;
  menu_itemSort = false;
  menuStatusChecking = false;
  sortNo;
  selectedNodesId;
  connectedEdgesid;
  allEdges = [];
  checkingNodes;
  parentNode = [];
  parentChild = [];
  expandCollapsedIdentify: any = [];
  idErrormsgSort = false;
  indication: boolean;
  allNodesTillEnd = [];
  minusMarkingNodes = [];
  parentNodeDetails = [];
  totalChildArrays = [];
  duplicates = [];
  selectedNodeIdStatus;
  connectedEdgesForsort;
  totalDuplicates = [];
  flatArrayOfTotal = [];
  uniqueArray = [];
  totalArrayCommonParentDetails = [];
  totalArrayFromParentDetails = [];
  sortCheckingIndicatorCommon = false;
  sortCheckingIndicator = false;
  nlpResponseSwitch: boolean;
  Node_status;
  searchTerm = '';
  filteredFlowbuilderListData: any[] = [];
  authenticationChecking: any;
  Node_auth: any;
  flowOtpAuthentication;
  checkBoxDisplay;
  phoneNumberInfoCheck;
  previousNodeAuth;
  authdisplay;
  ValueDisplayAuthentication;
  copyNode = true;
  pasteNodeData = true;
  public updatevalidationMsgs = {
    'updateemailAddress': [{
      type: 'email',
      message: 'Enter a valid email'
    }]
  };
  conditionList: ConditionList[] = [
    {name: 'Character count equal to', value: 'length='},
    {name: 'Character count greater than', value: 'length>'},
    {name: 'Character count greater than or equal to', value: 'length>='},
    {name: 'Character count less than', value: 'length<'},
    {name: 'Character count less than or equal to', value: 'length<='},
    {name: 'Character count not  equal to', value: 'length!='},
    {name: 'Email', value: 'Is_email'},
    {name: 'Equal to', value: '='},
    {name: 'Greater than', value: '>'},
    {name: 'Greater than or equal to', value: '>='},
    {name: 'Is media', value: 'Is_media'},
    {name: 'Less than', value: '<'},
    {name: 'Less than or equal to', value: '<='},
    {name: 'Not equal to', value: '!='},
    {name: 'Not numeric', value: 'Is_not_a_number'},
    {name: 'Numeric', value: 'Is_a_number'},
    {name: 'Not an email', value: 'Is_not_a_email'},
    {name: 'Not a media', value: 'Is_not_media'},
    {name: 'Valid', value: 'Is_valid'},
    {name: 'Invalid', value: 'Is_invalid'},
    {name: 'Phone number', value: 'Is_phone_number'},
    {name: 'Not phone number', value: 'Is_not_phone_number'},
  ];

  public graph: any;
  tools: any = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ hargeader: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
    ],
  };
  validInvalidList;
  withoutValidInvalidList;
  public showAllStyle: any = stylesheetObject;
  @ViewChild('Editor') editorElementRef: QuillEditorComponent;
  @ViewChild('dragContainer') dragContainer!: ElementRef<HTMLElement>;
  @ViewChild('sideNav') public sideNav: MatSidenav;
  @ViewChild('fileInputEdit') fileInputEdit: ElementRef;

  nodeType: any;
  showVariableSelectBox = false;
  variableParam: any;
  userAttribute: any = {};
  VariabledropdownOptions: any = [];
  variableConfig: any = {};
  selectedAnswerBoxIndex: any = 0;
  variableArray = [];
  bgNodeColor = false;
  selectedFormGroupIndexForImage = -1;
  agentGroup: any;
  urlvalidation = false;
  updatewebhookurl: any;
  updateheaderkey: any;
  updateheadervalue: any;
  addChildButton = true;
  permission: any;
  language: any;
  flowName: any;
  removeConnection = false;
  userInput = false;
  sendApi = false;
  prevMessage = [];
  flows: any;
  conditionCheckNodeName = '';
  validationClick = false;
  webhookurl: any;
  headerkey: any;
  headervalue: any;
  intervalId: any;
  agentHandover: any = false;
  tooltipText = `<p>Set up a webhook url with POST method. You will be receiving a webhook payload as input in the webhook URL in the below json format when user completes the flow.<br>{<br>"input_1": "value1",<br>"input_2": "value2"<br>}<br>When a webhook payload is received at the webhook URL, you must always return a 200 OK HTTP json response as below.<br>{<br>\t"status": 200,<br>"info":"OK"<br>}<br>If the json response has a parameter "text", the value of the "text" parameter will be appended along with the end response.<br>If the json response has a parameter "validate", the value of the "validate" parameter can be used to check and create further nodes in the flow from the end response node.</p>`;
   themeStyles = {
    'orange-style.css': { backgroundColor: '#FFD078', rootColor: '#e6a03f', themeNode: 'orange' },
    'green-style.css': { backgroundColor: '#CCFBCC', rootColor: 'green', themeNode: 'green' },
     'light-green-style.css': { backgroundColor: '#CCFFF5', rootColor: '#3bbd9b', themeNode: 'light-green' },
     'blue-style.css': { backgroundColor: '#DBEDFF', rootColor: '#57a6f4', themeNode: 'blue' },
     'black-style.css': { backgroundColor: '#BEBEBE', rootColor: '#3B3B3B', themeNode: 'black' },
    };
   nodeTypeImages = {
    'slot_question': 'slot-question.svg',
    'menu_question': 'menu-response.svg',
     'menu_item': 'menu-item.svg',
     'conditional_response': 'conditional-response.svg',
     'condition': 'condition.svg',
     'end_response': 'end-response.svg',
  };
   flowBuilderError: any;
  snapping: any = {'x': 0, 'y': 0};
  over = false;
  positionY: number;
  positionX: number;
  selectionModeEnabled = true;
  isFullScreen = false;
  lineTypemenu = [
    { name: 'tree-line', value: 'taxi', icon: './assets/images/flowbuilder-icon/tree-line.svg' },
    { name: 'line', value: 'bezier', icon: './assets/images/flowbuilder-icon/line.svg' },
    { name: 'Segments', value: 'segments', icon: './assets/images/flowbuilder-icon/segments.svg' },
    { name: 'line-curve', value: ' unbundled-bezier', icon: './assets/images/flowbuilder-icon/line-curve.svg' }
  ];

  linePatternItems = [

    { name: 'solid-line', value: 'solid', icon: './assets/images/flowbuilder-icon/line-solid.svg' },
    { name: 'dashed-line', value: 'dashed', icon: './assets/images/flowbuilder-icon/line-dashed.svg' },
    { name: 'dotted-line', value: 'dotted', icon: './assets/images/flowbuilder-icon/line_dotted.svg' }
  ];

  lineTypeItem: any = this.lineTypemenu[0];
  linePatternItem: any = this.linePatternItems[0];
  dropdownOpen = false;
  linePatternOpen = false;
  setExistFlowPosition = false;
  private readonly routerSubscription: Subscription;
  nodeCounts = {
    root: 0,
    slot_question: 0,
    conditional_response: 0,
    condition: 0,
    end_response: 0,
    menu_question: 0,
    menu_item: 0
  };
  image_url_org = '';
  conditionListCopy: ConditionList[];
  imageDetailsList: any = [];
  imageUrlListMax = 4;
  multipleNodeSelected = [];
  selectedNodes: any;
  isPaymentEnabled: any;
  paymentSettings = {
    amount: '',
    currencyCode: '',
    title: ''
  };
  paymentCountryList: any;
  selectedCurrencies: any;
  constructor(public dialog: MatDialog, private readonly renderer: Renderer2, private readonly toastr: ToastrService, private readonly injector: Injector, private readonly cdRef: ChangeDetectorRef, public readonly formBuilder: UntypedFormBuilder, private readonly flowBuilderService: FlowBuilderService, private readonly translate: TranslateService, private readonly el: ElementRef) {
    this.route = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.converstaionService = this.injector.get<ConversationService>(ConversationService);
    this.usersService = this.injector.get<UsersService>(UsersService);
    this.sharedService = this.injector.get<SharedsService>(SharedsService);
    this._nlpService = this.injector.get<NlpService>(NlpService);
    this.router = this.injector.get<Router>(Router);
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
    this.route.params.subscribe(params => {
      this.flowbuilderId = params['id'];
      localStorage.setItem('flowid', this.flowbuilderId);
    });
    this.userData = JSON.parse(localStorage.getItem('user'));
    this.isPaymentEnabled =  this.userData.appSettings.projectDetail?.paymentSettings?.paymentEnabled ?? false;
    this.agentHandover = (this.userData.appSettings.projectDetail.agent_handover || this.userData.appSettings.group_messaging);
    this.transform = {x: 0, y: 0};
    this.flowBuilderService.data$?.subscribe(data => {
      if (data && data.type === 'upload') {
        this.processFileEdit(data.file, data.type);
      }
    });
  }
  goback() {
    this.router.navigate([environment.dashboardPrefix + '/flowbuilderlist']);
  }

  updateNodeBackground() {
    this.bgNodeColor = false;
    const currentThemeStyle = this.themeStyles[this.theme] || {};
    this.themeNode = currentThemeStyle.themeNode;

    this.cy?.nodes().forEach((node) => {
      const nodeElement = this.cy?.getElementById(node?.data()?.id);
      const isRootNode = node.data().node_type === 'root';
      const isEndNode = node.data().node_type === 'end_response';
      const bgColor = this.setBackgroundColor(isEndNode, isRootNode, currentThemeStyle);
      if (bgColor) {
        nodeElement.style('background-color', bgColor);
        nodeElement.style('color', currentThemeStyle.rootColor);
        this.bgNodeColor = true;
      }
      const nodeTypecheck = node.data().node_type;
      if (nodeTypecheck === 'end_response') {
        if (nodeElement?.data()?.submit_action?.action !== '') {
          nodeElement?.style({
            'background-image': `url("assets/images/${nodeElement?.data().submit_action?.action}.png")`,
            'background-width': '25px',
            'background-height': '25px',
            'background-position-x': '10%',
            'background-position-y': '50%',
          });
        }
      }
      const nodeImage = this.nodeTypeImages[node.data().node_type];
      if (nodeTypecheck !== 'end_response') {
        if (nodeImage && this.themeNode) {
          const imageUrl = `../../assets/images/flowbuilder-icon/${this.themeNode}/${nodeImage}`;
          nodeElement.style('background-image', `url("${imageUrl}")`);
        }
      } else if (nodeElement?.data()?.submit_action?.action === undefined) {
        const imageUrl = `../../assets/images/flowbuilder-icon/${this.themeNode}/${nodeImage}`;
        nodeElement.style('background-image', `url("${imageUrl}")`);
      }
      if (isRootNode) {
        nodeElement.style('color', 'white');
      }
      if (isEndNode) {
        nodeElement.style('color', 'red');
      }
    });

  }
  setBackgroundColor(isEndNode, isRootNode, currentThemeStyle) {
    let bgColor;
    if (isEndNode) {
        bgColor = '#FF9A9A';
    } else if (isRootNode) {
        bgColor = currentThemeStyle.rootColor;
    } else {
        bgColor = currentThemeStyle.backgroundColor;
    }
    return bgColor;
  }
  updateUnselectedNodeBackground(id: any) {
    this.bgNodeColor = false;
    const currentThemeStyle = this.themeStyles[this.theme] || {};
    this.themeNode = currentThemeStyle.themeNode;
      const nodeElement = this.cy.getElementById(id);
      const isRootNode = nodeElement.data().node_type === 'root';
      const isEndNode = nodeElement.data().node_type === 'end_response';
      const bgColor = this.setBackgroundColor(isEndNode, isRootNode, currentThemeStyle);
      if (bgColor) {
        nodeElement.style('background-color', bgColor);
        this.bgNodeColor = true;
      }
      const nodeTypecheck = nodeElement.data().node_type;
      if (nodeTypecheck === 'end_response') {
        if (nodeElement.data().submit_action?.action !== '') {
          nodeElement.style({
            'background-image': `url("assets/images/${nodeElement.data().submit_action?.action}.png")`,
            'background-width': '25px',
            'background-height': '25px',
            'background-position-x': '10%',
            'background-position-y': '50%',
          });
        }
      }
      const nodeImage = this.nodeTypeImages[nodeElement.data().node_type];
      if (nodeTypecheck !== 'end_response') {
        if (nodeImage && this.themeNode) {
          const imageUrl = `../../assets/images/flowbuilder-icon/${this.themeNode}/${nodeImage}`;
          nodeElement.style('background-image', `url("${imageUrl}")`);
        }
      } else if (nodeElement.data()?.submit_action?.action === undefined) {
        const imageUrl = `../../assets/images/flowbuilder-icon/${this.themeNode}/${nodeImage}`;
        nodeElement.style('background-image', `url("${imageUrl}")`);
      }
      if (isRootNode) {
        nodeElement.style('color', 'white');
      }
      if (isEndNode) {
        nodeElement.style('color', 'red');
      }
  }
  edgeRemoveConnection (nodeId, data) {
    const isPresent = data?.['flowLinkData'].some(obj => obj.source === nodeId);
    if (!isPresent) {
    if (this.cy) {
      this.cy.edges().find((edge: any) => {
        if (edge.data().source === nodeId ) {
          edge.remove();
        }
      });
    }
  }
  }
  flowbuilderData(type, nodeType: any = '', nodeId: any = '') {
    this.flowBuilderService.flowBuilderData(this.flowbuilderId).subscribe({
      next: (data) => {
        this.flows = data['flows'];
        this.nodeCounts = this.flows?.reduce((acc, obj) => {
          if (acc[obj.node_type]) {
            acc[obj.node_type]++;
          } else {
            // Otherwise, initialize it with 1
            acc[obj.node_type] = 1;
          }
          return acc;
        }, {});
        this.totalnodecount =
        (this.nodeCounts?.condition ?? 0) +
        (this.nodeCounts?.conditional_response ?? 0) +
        (this.nodeCounts?.end_response ?? 0) +
        (this.nodeCounts?.menu_item ?? 0) +
        (this.nodeCounts?.menu_question ?? 0) +
        (this.nodeCounts?.root ?? 0) +
        (this.nodeCounts?.slot_question ?? 0);
        if (nodeType === 'end_response') {
          this.edgeRemoveConnection(nodeId, data);
        }

        const waypoints: any = this.lineTypemenu.find((points: any) => points.value === data['line_waypoints']);
        const patterns: any = this.linePatternItems.find((pattern: any) => pattern.value === data['line_pattern']);
        if (waypoints) {
          this.lineTypeItem = waypoints;
        }

        if (patterns) {
          this.linePatternItem = patterns;
        }

        this.appendNodePosition(data, type);
        this.setExportDeleteDataNode();
        this.setHidemenuDeleteData();
        if (data?.['flowDetails']) {
          this.language =  data['flowDetails'].language;
          this.flowName = data['flowDetails']?.name;
          this.arabic_lan = this.checkCondition(this.language === 'ar' , true , false);
        }
        const arg = {
          limit: 10000,
          skip: (this.page * 10) - 10,
          language: this.language,
        };
        this.flowbuilderList(arg);
      },
      error: (error) => {
        this.flowBuilderError = error.message;
      },
      complete: () => {
      },

    });
    //  this.totalnodecount = this.nodeCounts.condition ? this.nodeCounts.condition : 0 + this.nodeCounts.conditional_response ? this.nodeCounts.conditional_response : 0 +
    // this.nodeCounts.end_response ? this.nodeCounts.end_response:0+ this.nodeCounts.menu_item ? this.nodeCounts.menu_item:0 + this.nodeCounts.menu_question ? this.nodeCounts.menu_question :0+
    // this.nodeCounts.root ? this.nodeCounts.root :0 + this.nodeCounts.slot_question ? this.nodeCounts.slot_question :0


  }
  appendNodePosition(json: any, type) {
    this.setExistFlowPosition = false;
    if (json?.flows?.length) {
    for (const flow of json.flows) {
      if (flow.node_type === 'root' && flow.nodePosition) {
        this.setExistFlowPosition = false;
        break;
      }
      if (flow.node_type === 'root' && !flow.nodePosition) {
        this.setExistFlowPosition = true;
        flow.nodePosition = {
          x: 10,
          y: 10
        };
      }
       if (flow.nodePosition) {
         if (flow.next_message.length > 1) {
           this.setExistFlowPosition = true;
           this.positionY = -100;
           this.positionX = 400;
           flow.next_message.forEach((nextNode) => {
             const obj = json.flows.find(item => item.id === nextNode);
             if (obj) {
               obj['nodePosition'] = {
                 x: flow.nodePosition.x + this.positionX,
                 y: flow.nodePosition.y + this.positionY
               };
             }
             this.positionY += 100;
             this.positionX += 400;
           });
         } else {
           this.setExistFlowPosition = true;
           const obj = json.flows.find(item => item.id === flow.next_message[0]);
           if (obj) {
             obj['nodePosition'] = {
               x: flow.nodePosition.x + 400,
               y: flow.nodePosition.y
             };
           }
         }
       }
    }
  }
    this.flowsListConversion(json, type);
  }
  flowsListConversion(json: any, type) {
    const nodes: any[] = [];
    const edges: any[] = [];
    json.flows.forEach((flow: any) => {
      nodes.push({data: {
          id: flow.id,
          node_type: flow.node_type,
          node_name: flow.node_name,
          label: flow.label,
          flowNodeUsageCount: flow.flowNodeUsageCount,
          dummyLabel: this.getLabelchanges(flow.label),
          image_details: flow.image_details,
          condition_check: flow.condition_check,
          submit_action: flow.submit_action,
          next_message: flow.next_message,
          prev_message: flow.prev_message,
          variableArray: flow.variableArray,
          user_attribute: flow.userAttribute,
          conditionDetails : flow.conditionDetails,
          sortNumber: flow.sortNumber,
          hideMenu: flow.hideMenu,
          authentication: flow.authentication,
          condition_id: flow.condition_id,
          condition_operator: flow.condition_operator,
          condition_value: flow.condition_value,
          type: flow.type,
          flow_id: flow.flow_id,
          groupId: flow.groupId,
          conditionCheckNodeName: flow.conditionCheckNodeName,
          removed_data: flow.removed_data
        }, position: flow.nodePosition, classes: 'has-images'
      });

      flow.next_message.forEach((nextMessageId: string) => {
        edges.push({data: {
            id: edges.length,
            source: flow.id,
            target: nextMessageId,
            type: 'bendPoint',
          }});

      });

    });

    this.graph = { nodes, edges };
    if (this.graph && type === 'update') {
      this.updateFlowElements(json.flows);

    } else if (this.graph && type === 'preview') {
      this.previewOpen();
    } else {
      this.init();
    }
  }
  updateFlowElements(flows: any) {
    flows.forEach((data: any) => {
      const nodeId = this.cy?.getElementById(data.id);
      if (nodeId.length > 0) {
        nodeId.data('id', data.id);
        nodeId.data('node_type', data.node_type);
        nodeId.data('node_name', data.node_name);
        nodeId.data('label', data.label);
        nodeId.data('dummyLabel', this.getLabelchanges(data.label));
        nodeId.data('flowNodeUsageCount', data.flowNodeUsageCount);
        nodeId.data('image_details', data.image_details);
        nodeId.data('condition_check', data.condition_check);
        nodeId.data('groupId', data.groupId);
        nodeId.data('submit_action', data.submit_action);
        nodeId.data('next_message', data.next_message);
        nodeId.data('prev_message', data.prev_message);
        nodeId.data('variableArray', data.variableArray);
        nodeId.data('user_attribute', data.userAttribute);
        nodeId.data('conditionDetails', data.conditionDetails);
        nodeId.data('sortNumber', data.sortNumber);
        nodeId.data('hideMenu', data.hideMenu);
        nodeId.data('authentication', data.authentication);
        nodeId.data('condition_id', data.condition_id);
        nodeId.data('condition_operator', data.condition_operator);
        nodeId.data('condition_value', data.condition_value);
        nodeId.data('type', data.type);
        nodeId.data('flow_id', data.flow_id);
        nodeId.data('conditionCheckNodeName', data.conditionCheckNodeName);
        nodeId.data('removed_data', data.removed_data);
        this.cy.getElementById(data.id).addClass('has-images');
      }
    });

    this.updateNodeBackground();
    this.cy.nodes('.has-images').forEach((node) => {
      this.createOrUpdateOverlay(node);
    });


  }
  ngOnInit() {
    this.paymentCountryList = GetPaymentCountryList.getCountryList();
    this.selectedCurrencies =  this.paymentCountryList.filter(item => this.userData.appSettings.projectDetail?.paymentSettings?.selectedCurrencies?.includes(item?.currency));
    this.userQuestionUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.count = 0;
        this.searchNode(value);
      });
    this.flowOtpAuthentication = this.userData.appSettings.projectDetail.flow_otp_authentication;
    this.theme = localStorage.getItem('theme');
    this.getProjectVariablesList();
    this.checkVariabledata({'variableType': 'system'});
    this.checkVariabledata({'variableType': 'user'});
    this.checkVariabledata({'variableType': 'project'});
    this.flowbuilderData('loading');
    this.getAppCredentials();
    this.loaderShow = true;
    const menus = this.userData.roles.menu_tab;
    const index = menus.findIndex(
      (element) => (element?.name.toLowerCase() === this.route.snapshot.data['title'].toLowerCase() || element?.name.toLowerCase() === 'content elements' || element.name.toLowerCase() === 'content')
    );
    if (index === -1) {
      if (menus[0].sub && menus[0].sub.length > 0) {
        this.router.navigate([
          environment.dashboardPrefix + menus[0].sub[0].path.toLowerCase(),
        ]);
      } else {
        this.router.navigate([
          environment.dashboardPrefix + menus[0].path.toLowerCase(),
        ]);
      }
    } else {
      this.permission = this.userData.permission;
    }
    this.menuForm = this.formBuilder.group({
      menus: this.formBuilder.array([this.createMenuFormGroup()])
    });
    this.emailForm = this.formBuilder.group({
      emails: this.formBuilder.array([this.createEmailFormGroup()])
    });
    this.updateemailForm = this.formBuilder.group({
      updateemails: this.formBuilder.array([this.updatecreateEmailFormGroup('')])
    });
    this.conditionForm = this.formBuilder.group({
      conditions: this.formBuilder.array([this.createConditionFormGroup()])
    });
    const arg = {
      'appId': this.sharedService.appId,
      'search': '',
      'limit': 0,
      'skip': 0,
    };

    this.dragEndSubject.pipe(debounceTime(300)).subscribe((SourceItem: any) => {
      this.saveMessage = 1;
      this.dragEndNode(SourceItem);
    });
    this.getAgentGroup(arg);
    this.conditionListCopy = JSON.parse(JSON.stringify(this.conditionList));
  }

  getAppCredentials() {
    this._nlpService.getAppCredentials().subscribe(
      (data) => {
        if (data?.info) {
          this.routingAgent = data.info.routingAgent;
        }
      });
  }

  getProjectVariablesList() {
    this.variableType = 'project';
    this.getVariablesList();
  }

  dragEndNode(evtTarget: any) {
    if (!evtTarget.data('id')) {
      return;
    }

    const nodePositionChange: any = {
      flow_id: this.flowbuilderId,
      node_id: evtTarget.data('id'),
      nodePosition: { x: evtTarget.position().x - 20, y: evtTarget.position().y - 20 }
    };

    this.nodePositionUpdate(nodePositionChange);
  }

  nodePositionUpdate(arg: any) {
    this.flowBuilderService.updateNodePosition(arg).subscribe({
      next: (data) => {
      },
      error: (error) => {
        this.toastr.error('', this.translate.instant('toastr.something_wrong'));
      },
      complete: () => {
        this.saveMessage = 2;
        this.actiontype = 'none';
      },
    });
  }

  addNodesEdge(arg: any, menuItem: string = 'nonMenu') {
    this.flowBuilderService.nodeEdgesAdd(arg).subscribe({
      next: (data) => {
      },
      error: (error) => {
        this.toastr.error('', this.translate.instant('toastr.something_wrong'));
      },
      complete: () => {
        this.saveMessage = 2;
        if (menuItem === 'menuClick') {
          this.flowbuilderData('update');
          this.addEdge(arg);
        } else {
          this.flowbuilderData('update');
        }
      },
    });
  }

  addEdge(arg) {
    this.cy.add([{
      group: 'edges',
      data: { id:  arg.source + arg.target, source: arg.source, target: arg.target }
    }]);

  }
  private createMenuFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      'menunode': new UntypedFormControl(''),
      'menuUrl': new UntypedFormControl(''),
      'menuTitle': new UntypedFormControl('')
    });
  }

  private createEmailFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      'emailAddress': new UntypedFormControl('', Validators.email)
    });
  }

  createConditionFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      'condition': new UntypedFormControl(''),
      'conditionValue': new UntypedFormControl(''),
      'isNumeric' : new UntypedFormControl('')
    });
  }

  checkNumeric(event, i) {
   if (event?.value === 'Is_phone_number' || event?.value === 'Is_email') {
    this.checkBoxDisplay = true;
    this.phoneNumberInfoCheck = event.value === 'Is_phone_number' ? true : false;
   } else {
    this.checkBoxDisplay = false;
    this.phoneNumberInfoCheck = false;
    this.Node_auth = false;
   }
   this.Node_auth = false;
    const condition = event?.value ?? '';
    if (condition) {
      const isNumeric = this.changeCondition(condition);
      this.conditionForm.controls.conditions.value[i].isNumeric = isNumeric;
    }
  }
  changeCondition(condition) {
    if (condition === 'Is_not_a_number' || condition === 'Is_a_number' || condition === 'Is_email' || condition === 'Is_not_a_email' || condition === 'Is_media' || condition === 'Is_not_media' || condition === 'Is_invalid' || condition === 'Is_valid' || condition === 'Is_not_phone_number' || condition === 'Is_phone_number') {
      this.isNumeric = true;
      return true;
    } else {
      this.isNumeric = false;
      return false;
    }
  }

  valueValidation(event: any, i: any) {

    const formValue = this.conditionForm?.value ? this.conditionForm.value.conditions : '';
    if (formValue[i].condition !== '' && formValue[i].condition !== undefined && formValue[i].condition != null) {
      const val = event.charCode >= 48 && event.charCode <= 57;
      if (formValue[i].condition.name !== 'Equal to' && formValue[i].condition.name !== 'Not equal to' && val === false) {
        this.toastr.error(this.translate.instant('toastr.numeric_value'));
        return false;
      } else {
        return true;
      }
    } else {
      this.toastr.error(this.translate.instant('toastr.selectCondition'));
      return false;
    }
  }

  changeVariableRadio() {
    this.getVariablesList();
  }

  updateParentQaContent(event) {
    this.parent_qa = event.html;
    const selection: RangeStatic = {
      index: this.editorElementRef.quillEditor.getLength(),
      length: 0,
    };
    this.editorElementRef.quillEditor.setSelection(selection);
  }
  getVariablesList() {
    if (this.node_type === 'conditional_response') {
      this.variableType = 'project';
    }
    const args = {
      variableType: this.variableType
    };
    this.flowBuilderService.getVariablesList(args).subscribe(data => {
      if (data.status === 200) {
        this.variablesList = this.setValues(data.info , []);
        this.VariabledropdownOptions = this.setValues(data.info , []);
        if (this.variableType === 'project' || this.variableType === 'system' ) {
          this.variables = this.setVariableList();
          if (data?.info?.length === 0 && this.variableType === 'project' && this.nodetype !== 'conditional_response') {
            this.projectVariable = false;
            this.variableType = 'system';
            const arg = {
              variableType: this.variableType
            };
            this.flowBuilderService.getVariablesList(arg).subscribe((data1) => {
              this.variablesList = this.setValues(data1.info , []);
              this.VariabledropdownOptions = this.setValues(data1.info , []);
              this.variables = this.setVariableList();
            });
          }
          this.variableConfig = {
            displayKey: 'variable' ,
            search: true,
            height: '175px',
            placeholder: 'Select',
            limitTo: this.VariabledropdownOptions.length,
            moreText: 'more',
            noResultsFound: 'No results found!',
            searchPlaceholder: 'Search',
            searchOnKey: 'variable',
          };
        } else {
          this.setUserVariableConfig();
        }
      }
    }, err => {
      this.toastr.error('', this.translate.instant('toastr.something_wrong'));
    });
  }

  setValues(value1, values2) {
    if (value1) {
      return value1;
    } else {
      return values2;
    }
  }

  setObject(value1, values2) {

    if (value1 && Object.keys(value1)?.length !== 0) {
      return value1;
    } else {
      return values2;
    }
  }

  checkCondition(cond , val1, val2) {
    if (cond) {
      return val1;
    } else {
      return val2;
    }
  }

  setVariableList() {
    if (this.variablesList && this.variablesList.length > 0) {
      return this.variablesList[0];
    } else {
      return [];
    }
  }

  setUserVariableConfig() {
    if (this.variableType === 'user' && this.VariabledropdownOptions.length > 0) {
      this.variableConfig = {
        displayKey: 'attribute_name' ,
        search: true,
        height: '175px',
        placeholder: 'Select',
        limitTo: this.VariabledropdownOptions.length,
        moreText: 'more',
        noResultsFound: 'No results found!',
        searchPlaceholder: 'Search',
        searchOnKey: 'attribute_name',
      };
    }
  }
  emojiClick() {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
    const selectionStart = this.editorElementRef.quillEditor.getSelection();
    if (selectionStart) {
      this.selectedAnswerBoxIndex = selectionStart.index;
    }
  }

  addEmoji(event: any) {
    this.editorElementRef.quillEditor.insertText(
      this.selectedAnswerBoxIndex,
      event.emoji.native
    );
    this.isEmojiPickerVisible = false;
  }

  showSelectBox() {
    this.variableParam = '';
    if (this.showVariableSelectBox) {
      this.showVariableSelectBox = false;
    } else {
      this.showVariableSelectBox = true;
      this.variableType = 'project';
    }
  }

  checkVariabledata(obj) {
    this.flowBuilderService.getVariablesList(obj).subscribe((data) => {
      if (data?.info?.length === 0) {
        if (obj.variableType === 'project') {
          this.projectVariable = false;
          this.variableType = 'system';
          this.conditionalResponseVariableList = this.checkCondition(this.variableType === 'project', data.info, this.conditionalResponseVariableList);
          this.VariabledropdownOptions = this.conditionalResponseVariableList;
        } else if (obj.variableType === 'user') {
          this.userVariable = false;
          this.variableType = 'system';
        } else if (obj.variableType === 'system') {
          this.systemVariable = false;
        }
      } else if (data?.info?.length > 0) {
        if (obj.variableType === 'project') {
          this.conditionalResponseVariableList = data.info;
          this.VariabledropdownOptions = this.setValues(data.info , []);
        }
        if (obj.variableType === 'user') {
          this.userAttributeOptions = this.setValues(data.info , []);
          if (this.userAttributeOptions.length > 0) {
            this.userAttributeConfig = {
              displayKey: 'attribute_name',
              search: true,
              height: '175px',
              placeholder: 'Select',
              limitTo: this.userAttributeOptions.length,
              moreText: 'more',
              noResultsFound: 'No results found!',
              searchPlaceholder: 'Search',
              searchOnKey: 'attribute_name',
            };

          }
        }
      }
    });
  }
  onHover(event: MouseEvent) {
    const target = event.target['innerText'];
    this.titleDrop = target;
  }

  variableClick() {
    const selectionStart = this.editorElementRef.quillEditor.getSelection();
    if (selectionStart) {
      this.selectedAnswerBoxIndex = selectionStart.index;
    }
  }

  variableOnChange(event) {
    let charType;
    if (this.variableParam) {
      this.variableArray.push(this.variableParam);
      if (this.variableType === 'user') {
        charType = '{' + this.variableParam.attribute_name + '}';
      } else {
        charType = '{' + this.variableParam.variable + '}';
      }
    } else {
      this.variableParam = '';
    }
    this.editorElementRef.quillEditor.insertText(this.selectedAnswerBoxIndex, charType);
    this.variableParam = '';
  }
  init() {
    this.cy = cytoscape({
      container: document.getElementById('cy'),
      elements: this.graph,
      style: this.showAllStyle,
      layout: {
        name: 'preset',
      },
      boxSelectionEnabled: true,
      wheelSensitivity: 0,
      zoomingEnabled: false,
    });
    this.cy?.on('render', (event) => {
      this.cy.nodes('.has-images').forEach((node) => {
        this.createOrUpdateOverlay(node);
      });
    });
    let debounceTimer;
    this.cy?.on('select', 'node', () => {
      this.copyNode = false;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        const selectedNodes = this.cy.$('node:selected');
        this.checkingNodes = selectedNodes.data().node_type;
        this.selectedNodesId = selectedNodes.data();
        this.selectedNodes = selectedNodes;
        this.multipleNodeSelected = [];
        if (selectedNodes?.length === 1 && selectedNodes[0].data().node_type === 'root') {
          this.deleteedge = true;
        }
        // Use batch operation for performance
        this.cy.batch(() => {
          const allEdges = this.cy.edges();
          allEdges.removeClass('edge-highlighted').addClass('edge-dimmed');

          selectedNodes.forEach(node => {
            if (!this.multipleNodeSelected.includes(node?.data()?.id) && node.data().node_type !== 'root') {
              this.multipleNodeSelected.push(node?.data()?.id);
            }
            const connectedEdges = node.connectedEdges();
            this.connectedEdgesid = connectedEdges;
            this.connectedEdgesForsort = connectedEdges;
            this.evtTarget = connectedEdges;
            connectedEdges.addClass('edge-highlighted').removeClass('edge-dimmed');
          });

          if (selectedNodes.data().node_type === 'menu_item') {
            this.FindParentForSort();
            this.selectedNodeIdStatus = selectedNodes.data().id;
          }

          if (selectedNodes.length > 0) {
            selectedNodes.nodes().forEach(data => {
              const nodeId = this.cy.getElementById(data.data().id);
              nodeId.style('background-color', '#9d9b9b');
            });
            this.selectionModeEnabled = true;
            this.cy?.userPanningEnabled(true);
            this.cy?.userZoomingEnabled(true);
            this.renderer.setStyle(this.graphArea.nativeElement, 'cursor', 'pointer');
          }
        });

        this.idErrormsgSort = false;
      }, 50); // Adjust debounce delay as necessary
    });
    this.cy?.on('unselect', 'node', (event) => {
      const node = event.target;
      this.copyNode = true;
      this.multipleNodeSelected = [];
      this.updateUnselectedNodeBackground(node.id());
    });
    this.cy?.on('tap', 'edge', (evt) => {
      const edge = evt.target;
      this.deleteedge = false;
      this.isEdgeClicked = true;
      this.evtTarget = evt.target;
      this.cy.edges().removeClass('edge-highlighted').addClass('edge-dimmed');
      edge.addClass('edge-highlighted').removeClass('edge-dimmed');
    });

    this.cy?.on('tap', (event) => {
      if (event.target === this.cy) {
        this.deleteedge = true;
        this.evtTarget = null;
        this.cy.edges().removeClass('edge-highlighted edge-dimmed');
      }
    });

    this.cy?.on('mouseover', 'node', (event) => {
      const node = event.target;
      if (node.data().label && node.data().label !== '') {
        if (!node.popperRefObj) {
          node.popperRefObj = node.popper({
            content: () => {
              const content = document.createElement('div');
              content.classList.add('popper-div');
              const message = this.setMouseHoverMessage(node);
              if (node?.data()?.hideMenu) {
              const msg = `<div style="color:red;">Menu is hidden</div><br>`;
              content.innerHTML = msg + message + node.data().label;
              } else {
              content.innerHTML = message + node.data().label;
              }
              document.body.appendChild(content);
              return content;
            },
            popper: { placement: 'top' }
          });
        }
      }
    });


    this.cy?.on('mouseout', 'node', (event) => {

      const node = event.target;
        if (event?.target?.popperRefObj && event?.target?.popper) {
             event.target.popperRefObj.state.elements.popper.remove();
            event.target.popperRefObj.destroy();
            node.popperRefObj = null;
        }
    });
      this.cy?.on('cxttap', 'edge', (event) => {
          const edge = event.target;
          this.cy.edges().unselect();
          edge.select();
      });
      this.cy?.on('cxttapstart', (event) => {
          event.preventDefault();
      });


    const options = {
      snapToGridOnRelease: true,
      snapToGridDuringDrag: true,
      snapToAlignmentLocationOnRelease: false,
      snapToAlignmentLocationDuringDrag: false,
      distributionGuidelines: false,
      geometricGuideline: false,
      initPosAlignment: false,
      centerToEdgeAlignment: false,
      resize: false,
      parentPadding: false,
      drawGrid: true,
      gridSpacing: this.gridSpacing,
      zoomDash: false,
      panGrid: false,
      gridStackOrder: -1,
      gridColor: '#ffffff',
      lineWidth: 1.0,
      guidelinesStackOrder: 4,
      guidelinesTolerance: 2.00,
      guidelinesStyle: {
        strokeStyle: '#8b7d6b',
        geometricGuidelineRange: 400,
        range: 100,
        minDistRange: 10,
        distGuidelineOffset: 10,
        horizontalDistColor: '#ff0000',
        verticalDistColor: '#00ff00',
        initPosAlignmentColor: '#0000ff',
        lineDash: [0, 0],
        horizontalDistLine: [0, 0],
        verticalDistLine: [0, 0],
        initPosAlignmentLine: [0, 0],
      },
      parentSpacing: -1
    };

    const defaults = {
      toggleOffOnLeave: true,
      handleNodes: 'node',
      preview: false,
      hoverDelay: 150,
      snap: true,
      snapThreshold: 20,
      snapFrequency: 15,
      noEdgeEventsInDraw: true,
      disableBrowserGestures: true,
      handlePosition: (node: any) => {

        return 'middle top';
      },
      handleInDrawMode: true,

      edgeType: function (sourceNode: any, targetNode: any) {
        return 'flat';
      },
      loopAllowed: function (node: any) {
        return true;
      },
      nodeLoopOffset: -50,
      nodeParams: function (sourceNode: any, targetNode: any) {
        return {};
      },
      ghostEdgeParams: function () {

        return {id: ''};
      },

      show: function (sourceNode: any) {
      },
      hide:  (sourceNode: any) => {
        this.dragEndSubject.next(sourceNode);
      },
      start: function (sourceNode: any) {
      },
      complete: (sourceNode: any, targetNode: any, addedEles: any) => {
        this.removeConnection = false;
        this.nodeValidation(sourceNode, targetNode, addedEles);
      },
      stop: (sourceNode: any) => {
      },
      cancel: (sourceNode: any, cancelledTargets: any) => {
      },
      hoverover: (sourceNode: any, targetNode: any) => {
      },
      hoverout: (sourceNode: any, targetNode: any) => {
      },
      previewon: (sourceNode: any, targetNode: any, previewEles: any) => {
      },
      previewoff: (sourceNode: any, targetNode: any, previewEles: any) => {
      },
      drawon: () => {
      },
      drawoff: () => {
      }
    };
    this.cy.gridGuide(options);
    this.eh = this.cy.edgehandles(defaults);
    this.eh.disable();
    this.eh.enable();
    this.cy?.contextMenus({
      menuItems: [
        {
          id: 'menu-item-node',
          content: 'Add Menu Item',
          tooltipText: '',
          image: {src : 'assets/images/Rectangle7.png', width : 12, height : 12, x : 6, y : 7},
          selector: 'node[node_type = "menu_question"]',
          coreAsWell: false,
          onClickFunction: (event: any) => {
            const target = event.target;
            const nodeItem = {
              dropData: 'menu_item',
              clientX: target.position().x ?? 500,
              clientY: target.position().y ?? 500,
              selectedNodeId: target.data().id
            };
            this.addInNode(nodeItem);
          }
        }, {
          id: 'condition-node',
          content: 'Add Condition',
          tooltipText: '',
          image: {src : 'assets/images/conditional.png', width : 12, height : 12, x : 6, y : 7},
          selector: 'node[node_type = "conditional_response"]',
          coreAsWell: false,
          onClickFunction: (event: any) => {
            const target = event.target;
            const nodeItem = {
              dropData: 'condition',
              clientX: target.position().x,
              clientY: target.position().y,
              selectedNodeId: target.data().id
            };
            this.addInNode(nodeItem);

          }
        }, {
          id: 'delete-node',
          content: 'Delete Node',
          tooltipText: 'remove',
          image: {src : 'assets/images/delete-button.svg', width : 12, height : 12, x : 6, y : 7},
          selector: 'node[node_type != "root"]',
          onClickFunction: (event: any) => {
            const target = event.target;
            this.multipleNodeSelected = [];
            this.multipleNodeSelected.push(target?.data()?.id);
            this.showDeleteMenu(target, 'node');

          },
        },
        {
          id: 'delete-edge',
          content: 'Delete Edge',
          tooltipText: 'remove',
          image: {src : 'assets/images/delete-button.svg', width : 12, height : 12, x : 6, y : 7},
          selector: 'edge',
          onClickFunction: (event: any) => {
            const target = event.target;
            this.showDeleteMenu(target, 'edge');
          },
        },

      ],
    });
    this.options =  {
        name: 'preset',
        positions: undefined,
        grid: true,
        zoom: 0.8,
        pan: true,
        fit: true,
        padding: 0,
        avoidOverlap: true,
        animate: false,
        animationDuration: 500,
        animationEasing: undefined,
        animateFilter: function (node, i) { return true; },
        ready: undefined,
        stop: undefined,
        transform: function (node, position) {
          return position;
        }
    };
    this.updateNodeBackground();
    const layout = this.cy.layout(this.options);
    layout.run();
    this.cy.minZoom(0.2);
    this.cy.maxZoom(2);
    this.cy.fit();
    this.cy?.center();
    this.resetZoom();

    this.cy.on('drag', 'node', (event) => {
      const node = event.target;
      const position = node.position();
      const container = this.cy.container();
      const boundingBox = this.cy.extent();
      const pan = this.cy.pan();
      const padding = 50;
      const panUpdate = { ...pan };

      if (position.x - boundingBox.x1 < padding) {
        panUpdate.x += 10;
      } else if (boundingBox.x2 - position.x < padding) {
        panUpdate.x -= 10;
      }

      if (position.y - boundingBox.y1 < padding) {
        panUpdate.y += 10;
      } else if (boundingBox.y2 - position.y < padding) {
        panUpdate.y -= 10;
      }
      this.cy.pan(panUpdate);
    });
    if (this.setExistFlowPosition === true) {
      this.saveFlowbuilder();
    }
    this.cy.style()
      .selector('edge')
      .style({
        'curve-style': this.lineTypeItem.value,
        'line-style': this.linePatternItem.value
      }).update();
  }

  snapToGrid(position: { x: number; y: number }) {
    return {
      x: Math.round(position.x / this.gridSpacing) * this.gridSpacing,
      y: Math.round(position.y / this.gridSpacing) * this.gridSpacing,
    };
  }
  getItemsForNodeType(nodeType, nodeId) {
    const commonItems = [
      { action: 'Delete', icon: 'assets/images/delete-button.svg', id: nodeId, },
      { action: 'Expand/Collapse', icon: 'assets/images/expand-collapse.png', id: nodeId , }
    ];

    switch (nodeType) {
      case 'root':
        return [ { action: 'Add Slot Question', icon: `../../assets/images/flowbuilder-icon/${this.themeNode}/slot-question.svg`, id: nodeId, nodeType: 'slot_question', }, {action: 'Add Menu Question', icon: `../../assets/images/flowbuilder-icon/${this.themeNode}/menu-response.svg`, id: nodeId, nodeType: 'menu_question', }, {action: 'Add Conditional Response', icon: `./../assets/images/flowbuilder-icon/${this.themeNode}/conditional-response.svg`, id: nodeId, nodeType: 'conditional_response' }, {action: 'Add End Response', icon: `./../assets/images/flowbuilder-icon/${this.themeNode}/end-response.svg`, id: nodeId, nodeType: 'end_response'}, { action: 'Expand/Collapse', icon: 'assets/images/expand-collapse.png', id: nodeId }];
      case 'condition':

       return this.getItemsForNodeTypeCondition(nodeType, nodeId , commonItems);
      case 'menu_item':
      case 'slot_question':
        return [ { action: 'Add Slot Question', icon: `../../assets/images/flowbuilder-icon/${this.themeNode}/slot-question.svg`, id: nodeId, nodeType: 'slot_question', }, {action: 'Add Menu Question', icon: `../../assets/images/flowbuilder-icon/${this.themeNode}/menu-response.svg`, id: nodeId, nodeType: 'menu_question', }, {action: 'Add Conditional Response', icon: `./../assets/images/flowbuilder-icon/${this.themeNode}/conditional-response.svg`, id: nodeId, nodeType: 'conditional_response', }, {action: 'Add End Response', icon: `./../assets/images/flowbuilder-icon/${this.themeNode}/end-response.svg`, id: nodeId, nodeType: 'end_response', }, ...commonItems];
      case 'menu_question':
        return [ { action: 'Add menu item', icon: 'assets/images/Rectangle7.png', id: nodeId, nodeType: 'menu_item' }, ...commonItems];
      case 'conditional_response':
        return  [{ action: 'Add Condition', icon: 'assets/images/conditional.png', id: nodeId, nodeType: 'condition' }, ...commonItems];
      default:
        return [...commonItems];
    }
  }
  getItemsForNodeTypeCondition(nodeType, nodeId, commonItems) {

    if (this.Node_auth === true ) {

      return [{action: 'Add Conditional Response', icon: `./../assets/images/flowbuilder-icon/${this.themeNode}/conditional-response.svg`, id: nodeId, nodeType: 'conditional_response' }];
    } else {
      return [ { action: 'Add Slot Question', icon: `../../assets/images/flowbuilder-icon/${this.themeNode}/slot-question.svg`, id: nodeId, nodeType: 'slot_question', }, {action: 'Add Menu Question', icon: `../../assets/images/flowbuilder-icon/${this.themeNode}/menu-response.svg`, id: nodeId, nodeType: 'menu_question', }, {action: 'Add Conditional Response', icon: `./../assets/images/flowbuilder-icon/${this.themeNode}/conditional-response.svg`, id: nodeId, nodeType: 'conditional_response', }, {action: 'Add End Response', icon: `./../assets/images/flowbuilder-icon/${this.themeNode}/end-response.svg`, id: nodeId, nodeType: 'end_response', }, ...commonItems];

    }

  }
  showDropdown(position, nodeData) {
    const element = document.querySelector(`img.node-image[data-node-id="${nodeData.id}"]`);
    if (element instanceof HTMLImageElement) {
    const overlayImage = element; // Now safely typed as HTMLImageElement
    if (overlayImage?.src.includes('assets/images/greaterThan.png')) {
        this.expandCollapsed(nodeData.id);
        return;
    }
    }
    if (!this.dropdown) {
      this.dropdown = this.renderer.createElement('div');
      this.renderer.addClass(this.dropdown, 'menu_dropdown');
      this.nodeDataType = nodeData.node_type;
      const items = this.getItemsForNodeType(nodeData.node_type, nodeData.id);
      if (nodeData?.submit_action?.action === 'send_api') {
        items.unshift({action: 'Add Conditional Response', icon: `./../assets/images/flowbuilder-icon/${this.themeNode}/conditional-response.svg`, id: nodeData.id, nodeType: 'conditional_response' });
      }

      items.forEach((item) => {
        const option = this.renderer.createElement('div');
        this.renderer.listen(option, 'click', () => this.onDropdownOptionSelect(item, nodeData));
        const img = this.renderer.createElement('img');
        this.renderer.setAttribute(img, 'src', item.icon);
        this.renderer.setAttribute(img, 'alt', item.action);
        this.renderer.setAttribute(img, 'width', '15');
        this.renderer.setAttribute(img, 'height', '15');
        this.renderer.appendChild(option, img);
        const text = this.renderer.createText(' ' + item.action);
        this.renderer.appendChild(option, text);
        this.renderer.appendChild(this.dropdown, option);
      });
      this.renderer.appendChild(document.body, this.dropdown);
    }

    this.renderer.setStyle(this.dropdown, 'left', `${position.x + 370}px`);
    this.renderer.setStyle(this.dropdown, 'top', `${position.y + 120}px`);
    this.renderer.setStyle(this.dropdown, 'display', 'block');
  }

  onDropdownOptionSelect(option: any, nodedata: any) {
    const target = this.cy.getElementById(nodedata.id);
    if (option.action === 'Expand/Collapse') {
      this.expandCollapsed(nodedata.id);
    }
    if (option.action === 'Delete') {
      this.multipleNodeSelected = [];
      this.multipleNodeSelected.push(target?.data()?.id);
      this.showDeleteMenu(target, 'node');
    }
    if (option.action === 'Add menu item' || option.action === 'Add Condition') {
      const nodeItem = {
        dropData: option.nodeType,
        clientX: target.position().x + 100 ,
        clientY: target.position().y,
        selectedNodeId: target.data().id
      };
      this.addInNode(nodeItem);
    }
    if (option.action === 'Add Slot Question' || option.action === 'Add Menu Question' || option.action === 'Add Conditional Response' || option.action === 'Add End Response') {
      const hasMoreThanOneChild = this.hasMoreThanOneNodeChild(nodedata.id);
      if (hasMoreThanOneChild) {
        const nodeItem = {
          dropData: option.nodeType,
          clientX: target.position().x ?? 500,
          clientY: target.position().y ?? 500,
          selectedNodeId: target.data().id
        };
        this.addInNode(nodeItem);
      } else {
        const label = this.getLabelFromNode(nodedata.next_message[0]);
        const message = `Sorry you are not allowed to connect to this condition node as it is already connected from '${label}'`;
        this.toastr.error('', message);
      }
    }
    this.removeMenuList();
  }

  expandCollapsed(selectedId: any) {
    this.expandCollapsedIdentify = [];
    this.allNodesTillEnd = [];

    const node = this.cy.getElementById(selectedId);
    const filteredNodeCollection = node.successors().targets();
    const successorsTargets = filteredNodeCollection.filter((item) => item.data().id !== node.data().id);
       successorsTargets.forEach(element => {
      this.allNodesTillEnd.push(element.data().id);
    });
    if (node.connectedEdges().targets().length === 1) {
      if (node.connectedEdges().targets().style('display') === 'none') {
        node.successors().targets().style('display', 'element');
        node.successors().targets().data('_hidden', '');
        this.hideAndShowOverlay(successorsTargets, 'grid');
        this.expandCollapsedIdentify.push({
          nodeID: node.data().id,
          indication: true
        });
        // tslint:disable-next-line:no-shadowed-variable
        this.cy.nodes().forEach((node) => {

          this.expandCollapsedIdentify.forEach((item) => {
           if (item.nodeID?.includes(node.data().id)) {
           this.handleDeletion1(node);
           this.indication = false;
           this.createOrUpdateOverlay(node);



             // tslint:disable-next-line:no-shadowed-variable
            this.cy.nodes().forEach((node) => {
              const isAnyIncluded = this.allNodesTillEnd.some(element => this.minusMarkingNodes.includes(element));
              if (isAnyIncluded) {
                this.handleDeletion1(node);
                this.createOrUpdateOverlay(node);
                this.indication = false;
              }
            });
           }
          });
        });
      } else {
        this.expandCollapsedIdentify.push({
          nodeID: node.data().id,
          indication: true
        });
        node.successors().targets().style('display', 'none');
        node.successors().targets().data('_hidden', 'node-hidden');
        this.hideAndShowOverlay(successorsTargets, 'none');
        // tslint:disable-next-line:no-shadowed-variable
        this.cy.nodes().forEach((node) => {
          this.expandCollapsedIdentify.forEach((item) => {
           if (item.nodeID?.includes(node.data().id)) {
           if (this.allNodesTillEnd.length > 0) {
             this.handleDeletion1(node);
           this.indication = true;
           this.createOrUpdateOverlay(node);
           }

           }
          });
     });
    }
  } else if (node.connectedEdges()?.targets()[0]?.style('display') === 'none' || node.connectedEdges()?.targets()[1]?.style('display') === 'none') {
        node.successors()?.targets()?.style('display', 'element');
        node.successors()?.targets()?.data('_hidden', '');
        this.hideAndShowOverlay(successorsTargets, 'grid');
        this.expandCollapsedIdentify.push({
          nodeID: node.data().id,
          indication: true
        });
      // tslint:disable-next-line:no-shadowed-variable
        this.cy.nodes().forEach((node) => {

          this.expandCollapsedIdentify.forEach((item) => {
           if (item.nodeID?.includes(node.data().id)) {
           this.handleDeletion1(node);
           this.indication = false;
           this.createOrUpdateOverlay(node);




             // tslint:disable-next-line:no-shadowed-variable
            this.cy.nodes().forEach((node) => {
              const isAnyIncluded = this.allNodesTillEnd.some(element => this.minusMarkingNodes.includes(element));
              if (isAnyIncluded) {
                this.handleDeletion1(node);
                this.createOrUpdateOverlay(node);
                this.indication = false;
              }
            });

           }
          });
        });
      } else {
        this.expandCollapsedIdentify.push({
          nodeID: node.data().id,
          indication: true

        });

        successorsTargets.style('display', 'none');
        successorsTargets.data('_hidden', 'node-hidden');
        this.hideAndShowOverlay(successorsTargets, 'none');
      // tslint:disable-next-line:no-shadowed-variable
        this.cy.nodes().forEach((node) => {
           this.expandCollapsedIdentify.forEach((item) => {
            if (item.nodeID?.includes(node.data().id)) {
            this.handleDeletion1(node);
            this.indication = true;
            this.createOrUpdateOverlay(node);
            }
           });
      });

    }
  }
  handleDeletion1(node) {
      const nodeId = node.id();
      const overlay = document.getElementById(`overlay-${nodeId}`);
      if (overlay) {
        overlay.parentNode.removeChild(overlay);
      }
      const indicator = document.getElementById(`indicator-${nodeId}`);
      if (indicator) {
        indicator.parentNode.removeChild(indicator);
      }
      const menuhide = document.getElementById(`menuhide-${nodeId}`);
      if (menuhide) {
        menuhide.parentNode.removeChild(menuhide);
      }
  }
  hideAndShowOverlay(successorsTargets: any, type: any) {
    successorsTargets.forEach(function(targetNode) {
      const overlay = document.getElementById(`overlay-${targetNode.data().id}`);
      if (overlay) {
        overlay.style.display = type;
      }
      const indicator = document.getElementById(`indicator-${targetNode.data().id}`);
      if (indicator) {
        indicator.style.display = type;
      }
      const menuhide = document.getElementById(`menuhide-${targetNode.data().id}`);
      if (menuhide) {
        menuhide.style.display = type;
      }
    });
  }
  createOrUpdateOverlay(node) {
    const overlayId = `overlay-${node.id()}`;
    let overlay = document.getElementById(overlayId);
    const renderedPosition = node.renderedPosition();
    const width = node.renderedWidth();
    if (!overlay) {
      overlay = document.createElement('div');
      overlay.id = overlayId;
      const labeldiv = document.createElement('div');
      overlay.appendChild(labeldiv);
      overlay.style.position = 'absolute';
      this.cy.container().appendChild(overlay);
      const image1 = new Image();
        if (this.expandCollapsedIdentify.length > 0 && this.indication && node.connectedEdges().length > 0) {
          image1.src = 'assets/images/greaterThan.png';
          this.minusMarkingNodes.push(node.data().id);
          this.indication = false;
        } else {
        image1.src = 'assets/images/plus-image.png';

        this.minusMarkingNodes = this.minusMarkingNodes.filter(id => id !== node.data().id);

      }
      image1.classList.add('node-image');
      image1.dataset.nodeId = node.id();
      image1.style.width = '14px';
      image1.addEventListener('click', (event) => this.handleImageClick(event, node));
      const imagediv = document.createElement('div');
      imagediv.style.margin = '10px 0px 0px 0px';
      imagediv.classList.add('node-overlay');
      imagediv.appendChild(image1);
      overlay.appendChild(imagediv);
    }
    if (this.cy.zoom() < 0.55 && this.cy.zoom() > 0.46) {
      overlay.style.left =  `${renderedPosition.x + (width - 40) / 2}px`;
      overlay.style.top = `${(renderedPosition.y - 18)}px`;
      overlay.style.transform = `scale(${this.cy.zoom()})`;
    } else if (this.cy.zoom() < 0.45) {
      overlay.style.left =  `${renderedPosition.x + (width - 30) / 2}px`;
      overlay.style.top = `${(renderedPosition.y - 16)}px`;
      overlay.style.transform = `scale(${this.cy.zoom()})`;
    } else {
      overlay.style.left =  `${renderedPosition.x + (width - 50) / 2}px`;
      overlay.style.top = `${(renderedPosition.y - 20)}px`;
      overlay.style.transform = `scale(${this.cy.zoom()})`;
    }
    this.createChangeIndicator(node);
    if (node?.data()?.node_type === 'menu_item' && node?.data()?.hideMenu) {
      this.createMenuHideIcon(node);
    }
  }

  zoomIn(): void {
    this.cy?.zoomingEnabled(true);
    this.cy?.zoom({
      level: this.cy.zoom() * 1.2,
      renderedPosition: { x: this.cy.width() / 2, y: this.cy.height() / 2 }
    });
  }

  resetZoom(): void {
    this.cy?.zoomingEnabled(true);
    this.cy?.zoom({
      level: 0.8,
      renderedPosition: { x: this.cy.width() / 2, y: this.cy.height() / 2 }
    });
    this.setRootNodePosition();
  }

  zoomOut(): void {
    this.cy?.zoomingEnabled(true);
    this.cy?.zoom({
      level: this.cy.zoom() / 1.2,
      renderedPosition: { x: this.cy.width() / 2, y: this.cy.height() / 2 }
    });
  }

  handleImageClick(event: MouseEvent, node: cytoscape.NodeSingular) {
    this.sideNav.close();
    const renderedPosition = node.renderedPosition();
    this.showDropdown(renderedPosition, node.data());
  }

  getAgentGroup(args) {
    this.usersService.listAgentGroup(args).subscribe(
      (data) => {
        if (data?.data) {
          this.agentGroupList = data?.data?.data;
          this.agentGroupList = this.agentGroupList.filter(function(item) {
            return item.users.length > 0;
          });
        }
      });
  }
  selectNode(sourceNode , type: any = '') {
    this.paymentSettings = {
      amount: '',
      currencyCode: '',
      title: ''
    };
    this.authdisplay = false;
    if (sourceNode.data().node_type === 'menu_item' && type !== 'update') {
      this.FindParentForSort();
      this.selectedNodeIdStatus = sourceNode.data().id;
    }
    this.updateactiontype = 'none';
    this.node_type = sourceNode?.data()?.node_type;
    if (!this.node_type) {
      return;
    }

    this.updateNodeBackground();
    const elements = document.querySelectorAll('.popper-div');
    if (elements !== null) {
      elements.forEach((element) => {
          element.remove();
      });
    }
    this.userInput = false;
    this.sendApi = false;
    const selectedNode = this.flows?.filter(value => value.id === sourceNode.data().id );
    this.assignNodeTypeName(selectedNode[0]);
    this.checkingConditionalPrevNode(selectedNode);
    this.setConditionListForAPI(sourceNode);
    this.node_id = sourceNode.data().id;
    this.conditionCheckNodeName = sourceNode.data().conditionCheckNodeName;
    const nodeId = this.cy?.getElementById(this.node_id);
    nodeId.style('background-color', '#9d9b9b');
    this.parent_qa = sourceNode.data().label;
    this.selectedNodeData = sourceNode.data();
    this.previousNode = this.setValues(sourceNode.data().prev_message , '');
    this.setNodeValues(sourceNode);
    this.checkWith = sourceNode.data().condition_check;
    if(this.authdisplay) {
      this.checkWith = 'authentication';
    }
    const groupId = this.setValues(sourceNode.data().groupId, '');
    let agentGroup;
    if (groupId && this.agentGroupList?.length) {
      agentGroup = this.agentGroupList.filter(value => value._id === groupId );
    }
    this.updateAgentGroup = agentGroup?.length && agentGroup[0] ?  agentGroup[0] : 'Select group';
    if (sourceNode.data().node_type === 'root') {
      this.nodeName = 'Root';

    } else {
      this.nodeName = sourceNode.data().node_name;
    }
    if (sourceNode.data().node_type === 'menu_item' && sourceNode.connectedEdges()?.length > 0 && type !== 'update') {
        this.menu_itemSort = true;
        this.sortNo = sourceNode.data().sortNumber;
    } else {
      this.menu_itemSort = false;
    }
    if (sourceNode.data().node_type === 'menu_item') {
        this.menuStatusChecking = true;
        this.Node_status = sourceNode.data().hideMenu;
    } else {
      this.menuStatusChecking = false;
    }
    if (sourceNode.data().node_type === 'slot_question') {
      this.authenticationChecking = true;
  } else {
    this.authenticationChecking = false;
  }
    if (sourceNode.data().node_type === 'end_response') {

      this.updateConnectToFlowId = sourceNode?.data()?.submit_action?.flow_id;
       this.matchingElement = this.flowbuilderListDatalist.find(element => element._id === this.updateConnectToFlowId);

      if (this.matchingElement) {
       this.updateConnectToFlow = this.matchingElement;
        }
  }
    if (sourceNode?.data()?.image_details?.length) {
      this.fileType =  sourceNode.data().image_details[0].type;
      this.image_title = sourceNode.data().image_details[0].image_title;
      this.image_url = sourceNode.data().image_details[0].image_url;
      this.image_url_org = sourceNode.data().image_details[0].image_url_org;
      this.contentType = sourceNode.data().image_details[0].mediaType;
      this.imageDetailsList = JSON.parse(JSON.stringify(sourceNode.data().image_details));
    } else {
      this.fileType =  '';
      this.image_title = '';
      this.image_url = '';
      this.image_url_org = '';
      this.contentType = '';
      this.imageDetailsList = [];
    }
    if (sourceNode.data().node_type === 'end_response') {
      this.handleEndResponseOnNodeClick(sourceNode);
    }
    this.removeMenuList();
    if (type !== 'update') {
      this.sideNav.open();
    } else {
      this.updateCurrentNode('update');
    }
  }

  checkingConditionalPrevNode(sourceNode) {
    this.prevMessage = [];
    sourceNode[0]?.prev_message?.forEach((value: any) => {
      this.cy?.nodes().find((x: any) => {
        if (x.data().id === value) {
          this.prevMessage.push(x.data());
        }
      });
    });

    this.prevMessage.forEach((data: any) => {
      if (data.node_type === 'slot_question') {
        this.userInput = true;
      }
      if (data.node_type === 'end_response' && data.submit_action?.action === 'send_api') {
        this.sendApi = true;
      }
    });
    const userInputText = 'Check with: User Input ';
    let text = '';
    let parent_qa = '';
    if (sourceNode[0]?.condition_check === 'user_input') {
      this.prevMessage.forEach((data: any) => {
        text += data.node_name + ' ( ' + data.dummyLabel + ') / ' ;
        parent_qa += data.label + ' / ';
      });
      if (text.endsWith(' / ')) {
        text = text.slice(0, -3);
      }

      if (parent_qa.endsWith(' / ')) {
        parent_qa = parent_qa.slice(0, -3);
      }
      this.userInputNode = text;
      this.parent_qa = userInputText + parent_qa;
    } else {
      this.userInputNode = '';
      this.parent_qa = '';
    }
  }

  assignNodeTypeName(sourceNode: any) {
    if (sourceNode) {
    switch (sourceNode.node_type) {
      case 'slot_question':
        this.setSlotQuestioncase(sourceNode);
        break;

      case 'menu_question':
        this.nodetypeName = 'Menu Question';
        break;

      case 'menu_item':
        this.nodetypeName = 'Menu Item';
        break;

      case 'end_response':
        this.nodetypeName = 'End Response';
        break;

      case 'conditional_response':      // this.node_valueErrorMsg='';
        this.nodetypeName = 'Conditional Response';
        break;

      case 'condition':
        this.nodetypeName = 'Condition';
        break;

      case 'root':
        this.nodetypeName = 'Root';
        break;

      default:
        break;
    }
  }
  }
  setSlotQuestioncase(sourceNode) {
    this.nodetypeName = 'Slot Question';
    if (sourceNode.next_message.length) {
      const nxtMessage = this.cy?.nodes().find(x => x.data().id === sourceNode.next_message[0]);
      this.addChildButton = this.checkCondition(nxtMessage?.node_type === 'conditional_response' && nxtMessage?.condition_check === 'user_input', false, true);
    }
    const label = sourceNode.label;
    this.userInputNode = this.nodeName + ' (' + label + ')';
  }
  hasMoreThanOneNodeChild(nodeId: any) {
    const node = this.cy?.getElementById(nodeId);
    const outgoingEdges = node.outgoers('edge');
    const uniqueChildren = new Set(outgoingEdges.map(edge => edge.target().id()));
    return uniqueChildren.size < 1;
  }

  hasMoreThanOneChild(nodeId) {
    const sourceNodeId = nodeId;
    const existsInMoreThanOneEdge: boolean = this.cy.edges().filter((edge: any): boolean => edge.data('source') === sourceNodeId).length > 2;
    return existsInMoreThanOneEdge;
  }

  nodeValidation(sourceNode: any, targetNode: any, addedEles: any) {

    const sourceNodeName = sourceNode.data().node_type;
    const targetNodeSortNo = targetNode.data().sortNumber;
    if (targetNodeSortNo) {

      this.sortNo = '';
      this.selectNode(targetNode, 'update');

    }
    const hasMoreThanOneChild = this.hasMoreThanOneChild(sourceNode.data().id);
    const twoWayConnection = this.twoWayConnectionChecking(sourceNode, targetNode);
    const exists = sourceNode.data().next_message.includes(targetNode.data().id);
    this.nodeValidationRest(sourceNode, targetNode, addedEles);
    if (exists) {
        this.removeConnection = true;
        this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'alreadyExist');
    }

    if ((sourceNodeName === 'root')  && hasMoreThanOneChild) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'MoreThanOneChild');
    }

    if ((sourceNodeName === 'slot_question') && hasMoreThanOneChild) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'MoreThanOneChild');
    }
    if ((sourceNodeName === 'condition')  && hasMoreThanOneChild) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'MoreThanOneChild');
    }

    if ((sourceNodeName === 'menu_item')  && hasMoreThanOneChild) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'MoreThanOneChild');
    }

    if ((sourceNodeName === 'end_response') && hasMoreThanOneChild) {
        this.removeConnection = true;
        this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'MoreThanOneChild');
    }

    if ( twoWayConnection) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'twoWayConnection');
    }

    if (!this.removeConnection) {
      this.saveMessage = 1;
      const nodeEdges: any = {
        flow_id: this.flowbuilderId,
        source: sourceNode.data().id,
        target: targetNode.data().id
      };
      this.addNodesEdge(nodeEdges);
    }
  }
  nodeValidationRest(sourceNode: any, targetNode: any, addedEles: any) {

    const sourceNodeName = sourceNode.data().node_type;
    const targetNodeName = targetNode.data().node_type;
    if ((sourceNode.data().id === targetNode.data().id)) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'SameNode');
    }
    if ((sourceNodeName === 'menu_question') && (targetNodeName !== 'menu_item') ) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'Invalid Connection');
    }
    if ((sourceNodeName !== 'menu_question') && (targetNodeName === 'menu_item') ) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'Invalid Connection');
    }
    if ((sourceNodeName === 'conditional_response') && (targetNodeName !== 'condition') ) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'Invalid Connection');
    }
    if ((sourceNodeName !== 'conditional_response') && (targetNodeName === 'condition') ) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'Invalid Connection');
    }

    if ((sourceNodeName === 'conditional_response') && (targetNodeName === 'condition') && targetNode.data().prev_message.length > 0) {
      this.removeConnection = true;
      this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'conditionMorePrevNode');
    }
    if ((sourceNodeName === 'condition')  && sourceNode.data().authentication) {

      if (targetNodeName !== 'conditional_response') {

        this.removeConnection = true;
        this.removeEdgeConnection(sourceNode, targetNode, addedEles, 'MoreThanOneChild');
      }

    }

  }
  twoWayConnectionChecking(sourceNode: any, targetNode: any) {
    const isIdPresent = targetNode.data().next_message.some(obj => obj === sourceNode.data().id);
   return isIdPresent;
  }
  removeEdgeConnection(sourceNode: any, targetNode: any, addedEles: any, errorType: string) {
    let lastCreatedEdge = addedEles[0];
    if (lastCreatedEdge) {
      lastCreatedEdge.remove();
      lastCreatedEdge = null;
    }
    this.toasterErrorMessage(sourceNode, targetNode, errorType);

  }

  toasterErrorMessage(sourceNode: any, targetNode: any, errorType: string) {
    let message = '';

    if (errorType === 'Invalid Connection') {
      message = `Sorry, you can't create a connection from a ${this.getRecordStatus('label', sourceNode.data().node_type)} to ${this.getRecordStatus('label', targetNode.data().node_type)}`;
    } else if (errorType === 'conditionMorePrevNode') {
      const label = this.getLabelFromNode(targetNode.data().prev_message[0]);
      message = `Sorry you are not allowed to connect to this condition node as it is already connected from '${label}'`;
    } else if (errorType === 'MoreThanOneChild') {
      const label = this.getLabelFromNode(sourceNode.data().next_message[0]);
      message = `Sorry, this node is already connected to '${label}', multiple connections are not allowed for ${this.getRecordStatus('label', sourceNode.data().node_type)}.`;
    } else if (errorType === 'SameNode') {
      message = `Sorry, Connecting to the same node is not a valid connection`;
    } else {
      message = `Sorry, these nodes are already connected to each other.`;
    }

    if (message && errorType !== 'alreadyExist') {
      this.toastr.error('', message);
    }
  }

  getLabelFromNode(nodeId) {
    if (!nodeId) {
      return '';
    } else {
      const node = this.cy?.getElementById(nodeId);
      const labelMatch = node.data()?.dummyLabel.replace(/<[^>]*>/g, ' '); // NOSONAR
      return labelMatch || node.data()?.dummyLabel;

    }

  }

  getLabelchanges(label: any) {
    if (!label) {
      return '';
    } else {

      const labelMatch = label.replace(/<[^>]*>/g, ' '); // NOSONAR
      return labelMatch || label;

    }
  }

  showDeleteMenu(node: any, type: string) {
    let title;
    if (type === 'node') {
      title = this.setNodeDeletionTitle(node);
    } else {
      const sourceLabel = node?.data() ? this.getLabelFromNode(node.data().source) : '';
      const targetLabel = node?.data() ? this.getLabelFromNode(node.data().target) : '';
      title = `<h5>Are you sure you want to delete the connection from <b>'${sourceLabel}</b>' to '<b>${targetLabel}</b>'?</h5>`;
    }

    Swal.fire({
      html: title,
      showCancelButton: true,
      customClass: 'swal-wide',
      confirmButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.handleDeletion(node, type);
      }
    });
  }
  setNodeDeletionTitle(node) {
    let title = '';
    if (this.multipleNodeSelected.length > 1) {
      title = `<h5><i class="fa fa-exclamation-triangle" style="color:red" aria-hidden="true"></i>&nbsp; Are you sure you want to delete the nodes?</h5>`;
    } else {
      const label = node.data() ? this.getLabelFromNode(node.data().id) : '';
      title = `<h5><i class="fa fa-exclamation-triangle" style="color:red" aria-hidden="true"></i>&nbsp; Are you sure you want to delete the node '<b>${label}</b>'?</h5>`;
    }
    return title;
  }

  handleDeletion(node: any, type: string) {
    this.intervalId = setTimeout(() => {
      this.removeOrphanOverlays();
      }, 600);
    const nodeId = node.id();
    const overlay = document.getElementById(`overlay-${nodeId}`);

    if (overlay) {
      overlay.parentNode.removeChild(overlay);
    }
    const indicator = document.getElementById(`indicator-${nodeId}`);
    if (indicator) {
      indicator.parentNode.removeChild(indicator);
    }
    const menuhide = document.getElementById(`menuhide-${nodeId}`);
    if (menuhide) {
      menuhide.parentNode.removeChild(menuhide);
    }
    this.handelMultipleChildDeletion(node);
    if (type === 'node') {
      this.deleteNodeService({
        'flow_id': this.flowbuilderId,
        'node_id': this.multipleNodeSelected || node?.data()?.id,
      }, node);
      this.sideNav.close();
    } else if (type === 'edge') {
      this.deleteEdges({
        flow_id: this.flowbuilderId,
        source: node.data()?.source,
        target: node.data()?.target
      }, node);
    }
  }

  deleteNodeService(arg: any, node: any) {
    this.flowBuilderService.deleteNode(arg).subscribe({
      next: (data) => {
        this.saveMessage = 2;
      },
      error: (error) => {
        this.toastr.error('', this.translate.instant('toastr.something_wrong'));
      },
      complete: () => {
        this.actiontype = 'none';
        if (node.length > 1) {
          node.forEach(item => {
            if (item.data().node_type !== 'root') {
             item.remove();
            }
        });
        } else {
          node.remove();
        }
        this.flowbuilderData('update');
      },
    });
  }
  deleteEdges(arg: any, node: any) {
    this.flowBuilderService.deleteEdges(arg).subscribe({
      next: (data) => {
        this.saveMessage = 2;
        this.actiontype = 'none';
      },
      error: (error) => {
        this.toastr.error('', this.translate.instant('toastr.something_wrong'));
      },
      complete: () => {
        node.remove();
        this.conditionalResponseType = '';
        this.flowbuilderData('update');
      },
    });
  }

  getRecordStatus(type, value) {
    const labelMappings = {
      'menu_item': 'Menu item',
      'slot_question': 'Slot Question',
      'menu_question': 'Menu Question',
      'end_response': 'End Response',
      'conditional_response': 'Conditional Response',
      'condition': 'Condition',
      'subCondition': 'Sub Condition'
    };

    const placeholderMappings = {
      'menu_item': 'Please type Menu Question',
      'slot_question': 'Please type the question',
      'menu_question': 'Please type the question',
      'end_response': 'End Response'
    };

    if (type === 'label') {
      return labelMappings[value] || null;
    } else if (type === 'placeholder') {
      return placeholderMappings[value] || null;
    }

    return null;
  }

  onKeypressId(event: any) {
    if (event === '' || event === undefined || event === null) {
      this.idErrormsg = this.translate.instant('flowBuilder.idErrormsg');
    } else {
      this.idErrormsg = '';
    }
  }

  dragging({ x, y }: any, type: any) {
    this.transform.x = x - 150;
    this.transform.y = y;
    this.nodeType = type;
  }
  onDrop(dropData: any): void {
    this.addNode(dropData);
  }

  dragEnter(event: any): void {
    this.snapping = {'x': 20, 'y': 20};
    this.color = 'pink';
    this.over = true;
  }
  dragLeave(event: any): void {
    this.snapping = {'x': 0, 'y': 0};
    this.color = 'pink';
    this.over = false;
  }

  addNodeData(num: any, item: any) {
    return [{
      group: 'nodes',
      data: { id: item.node_data._id, node_type: item.node_data.node_type, label: '', type: 'text', image_details: [], prev_message: [], node_name: item.node_data.node_name, submit_action: {}, next_message: [], variableArray: [], user_attribute: {}, condition_check: '', condition_id: '', condition_operator: '', condition_value: '', conditionCheckNodeName: '', flowNodeUsageCount: null, groupId: '', sortNumber: null, hideMenu: '', authentication: ''},
      position: {
        x: item.node_data.nodePosition.x,
        y: item.node_data.nodePosition.y
      }
    }];
  }

  addNode(item: any) {
    this.numRes += 1;
    const arg = {
      'node_type': item.dropData,
      'text': '',
      'flow_id': this.flowbuilderId,
      'nodePosition': {
        x: -(this.cy.pan()?.x) + 1050 ,
        y: -(this.cy.pan()?.y) + 400
      },
      'type': 'text'
    };
    this.addNodeInCloud(arg);
  }



  addInNode(item: any) {
    this.numRes = 1;
    const nodeElement = this.cy.getElementById(item.selectedNodeId);
    this.linkPosition = nodeElement.position().y;
    if (nodeElement.data().next_message.length > 0 ) {
      const index = nodeElement.data().next_message.length;
      const nodeElementNext = this.cy.getElementById(nodeElement.data().next_message[index - 1]);
      this.linkPosition = nodeElementNext.position().y + 100;
    }
    const arg = {
      node_type: item.dropData,
      text: '',
      flow_id: this.flowbuilderId,
      type: 'text',
      nodePosition: {
        x: 300 + item.clientX,
        y: this.linkPosition
      }
    };
    this.addNodeInCloud(arg, 'menuClick', item.selectedNodeId);
  }

  addNodeInCloud(arg: any, menuItem: string = 'nonMenu', selectedNodeId: string = '') {
    this.flowBuilderService.addNode(arg).subscribe({
      next: (data) => {
        this.saveMessage = 2;
        this.actiontype = 'none';
        if (menuItem === 'menuClick') {
          const nodeEdges  = {
            flow_id: data['flow_id'],
            source: selectedNodeId,
            target: data['node_data']._id
          };
          this.cy.add(this.addNodeData(this.numRes, data));
          this.addNodesEdge(nodeEdges, menuItem);
        } else {
          this.cy.add(this.addNodeData(this.numRes, data));
          this.flowbuilderData('update');
        }

      },
      error: (error) => {
        this.toastr.error('', this.translate.instant('toastr.something_wrong'));
      },
      complete: () => {
      },
    });
  }

  evtListener() {
    this.cy?.on('tap', (event: any) => {
      const evtTarget = event.target;
      if (typeof evtTarget.isNode === 'function' || typeof evtTarget.isEdge === 'function') {
        if (evtTarget.isNode()) {

          this.markComponent = evtTarget.node_type;
          this.selectNode(evtTarget);
        } else {
          this.markComponent = '';
          this.removeMenuList();
        }
      } else {
        this.removeMenuList();
      }
    });
  }
  removeMenuList () {
    if (this.dropdown && document.body) {
      this.renderer.removeChild(document.body, this.dropdown);
      this.dropdown = null;
    }
  }
  closeRight() {
    this.childNodesShow = false;
    this.childNodesShow = false;
    this.validationClick = false;
    this.nodeIdentifier = '';
    this.node_id = '';
    this.nodetype = '';
    this.parent_qa = '';
    this.node_type = '';
    this.actiontype = 'none';
    this.variables = this.setVariableList();
    this.checkWith = 'api_response';
    this.conditionalResponseType = '';
    this.conditionalResponseValue = '';
    this.conditionalId = '';
    this.variableArray = [];
    this.userAttribute = {};
    this.conditionForm.reset();
    this.sideNav.close();
    this.menuForm.reset();
    this.emailForm.reset();
    this.updateemailForm.reset();
    this.urlvalidation = false;
    this.webhookurl = '';
    this.headerkey = '';
    this.headervalue = '';
    this.updatewebhookurl = '';
    this.updateheaderkey = '';
    this.updateheadervalue = '';
    this.idErrormsg = '';
    this.node_menuerrormsg = '';
    this.node_valueErrorMsg = '';
    this.agentGrouperrormsg = '';
    this.parenterrormsg = '';
    this.parentNodeErrorMsg = '';
    this.menuForm = this.formBuilder.group({
      menus: this.formBuilder.array([this.createMenuFormGroup()])
    });
    this.emailForm = this.formBuilder.group({
      emails: this.formBuilder.array([this.createEmailFormGroup()])
    });
    this.updateemailForm = this.formBuilder.group({
      updateemails: this.formBuilder.array([this.updatecreateEmailFormGroup('')])
    });
    this.conditionForm = this.formBuilder.group({
      conditions: this.formBuilder.array([this.createConditionFormGroup()])
    });
    this.sideNav.close();
    this.updateNodeBackground();
  }

  processFileEdit(event, action) {
    this.uploadloader = true;
    const Editfiles = event.target.files;
    const file = Editfiles[0];
    const file2 = Editfiles[0]?.name;
    const pattern2 = /.*jpeg.*/; // NOSONAR
    const pattern3 = /.*pdf.*/; // NOSONAR
    const pattern4 = /mp4-*/;
    const pattern5 = /msword-*/;
    const pattern6 =
      /vnd.openxmlformats-officedocument.wordprocessingml.document-*/;
    const pattern7 = /audio-*/;
    const pattern8 = /video-*/;
    const pattern9 = /.*png.*/; // NOSONAR
    const reader = new FileReader();
    const sizeinMb = (file.size / (1024 * 1024)).toFixed(2);
    if ((this.node_type === 'menu_item' && !this.childNodesShow)) {
      if (!file.type.match(pattern9) && !file.type.match(pattern2)) {
        this.toastr.error('', this.translate.instant('toastr.invalid_format'));
        this.setfileInput();
        this.uploadloader = false;
        return;
      }
      if (parseFloat(sizeinMb) > 5) {
        this.toastr.error('', this.translate.instant('toastr.uploadedFile_limit_5mb'));
        this.setfileInput();
        this.uploadloader = false;
        return;
      }
    } else {
      if ( !file.type.match(pattern2)  &&
      !file.type.match(pattern3) &&
      !file.type.match(pattern4) &&
      !file.type.match(pattern5) &&
      !file.type.match(pattern6) &&
      !file.type.match(pattern7) &&
      !file.type.match(pattern8) &&
      !file.type.match(pattern9) && (file?.name.search('.doc') === -1)) {
      this.toastr.error('', this.translate.instant('toastr.invalid_format'));
      this.setfileInput();
      this.uploadloader = false;
      return;
    }
      if (parseFloat(sizeinMb) > 5 && (file.type.match(pattern2) || file.type.match(pattern9))) {
        this.toastr.error('', this.translate.instant('toastr.uploadedFile_limit_5mb'));
        this.setfileInput();
        this.uploadloader = false;
        return;
      }
      if (parseFloat(sizeinMb) > 16 && (file.type.match(pattern3) ||
       file.type.match(pattern4) ||
        file.type.match(pattern5) ||
         file.type.match(pattern6) ||
          file.type.match(pattern7)  ||
           file.type.match(pattern8) )) {
        this.toastr.error('', this.translate.instant('toastr.uploadedFile_limit_16mb'));
        this.setfileInput();
        this.uploadloader = false;
        return;
      }
    }
    this.setFileType(file);
    reader.readAsBinaryString(file);
    reader.onload = (onloadEvent: any) => {
      const Data = onloadEvent.target.result;
      const contentData = btoa(Data);
      const args = {
        data: contentData,
        contentType: file.type,
        filename: this.setValues(file2, ''),
        bucketType: 'content'
      };
      this.converstaionService.fileupload(args).subscribe(response => {
          this.fileuploadResponse(response, file2, action, args.filename);
        },
        err => {
        });
    };
  }

  fileuploadResponse(response, file2, action, filename) {
    this.uploadloader = false;
    this.setfileInput();
    if (response.info.url) {
      if(action === 'upload') {
        this.handleFileUpload(response.info, filename)
        return;
      }
      this.image_url = response.info.url;
      this.image_url_org = response.info.url_orginal;
      this.image_title = file2;
      const imageDataObj = {
        mediaType: this.contentType,
        type: this.fileType,
        image_url: response.info.url,
        image_title: file2,
        image_url_org: response.info.url_orginal
      };
      this.imageDetailsList.push(imageDataObj);
    } else {
      this.toastr.info('', response.info);
      if(action === 'upload') {
        this.handleFileUpload( '', filename)
        return;
      }
    }
  }
  setFileType(file) {
    if (file.type.search('image') !== -1) {
      this.contentType = 'image';
      this.fileType = file.type;
    } else if (file.type.search('video') !== -1) {
      this.contentType = 'video';
      this.fileType = file.type;
    } else if (file.type.search('pdf') !== -1) {
      this.contentType = 'document';
      this.fileType = file.type;
    } else if (file?.name.search('.doc') !== -1) {
      this.contentType = 'docx';
      this.fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (file.type.search('audio') !== -1) {
      this.contentType = 'audio';
      this.fileType = file.type;
    } else {
      this.contentType = file.type;
      this.fileType = file.type;
    }
  }
  processMultipleResponseFileEdit(event, responseIndex, menu) {
    this.uploadloader = true;
    this.selectedFormGroupIndexForImage = responseIndex;
    const Editfiles = event.target.files;
    const file = Editfiles[0];
    const file2 = Editfiles[0]?.name;
    const pattern1 = /jpeg-*/;
    const pattern2 = /png-*/;
    const reader = new FileReader();
    const sizeinMb = (file.size / (1024 * 1024)).toFixed(2);
    if (
      !file.type.match(pattern1) &&
      !file.type.match(pattern2)
    ) {
      this.setfileInput();
      this.selectedFormGroupIndexForImage = -1;
      this.toastr.error('', this.translate.instant('toastr.invalid_format'));
      this.uploadloader = false;
      return;
    }
    if (parseFloat(sizeinMb) > 2) {
      this.toastr.error('', this.translate.instant('toastr.uploadedFile_limit'));
      this.uploadloader = false;
      this.setfileInput();
      this.selectedFormGroupIndexForImage = -1;
      return;
    }
    this.setFileType(file);
    reader.readAsBinaryString(file);
    reader.onload = (onloadEvent: any) => {
      const Data = onloadEvent.target.result;
      const contentData = btoa(Data);
      const args = {
        data: contentData,
        contentType: file.type,
        filename: this.setValues(file2, ''),
        bucketType: 'content'
      };
      this.converstaionService.fileupload(args).subscribe(
        (response) => {
          if (response.info.url) {
            const currentResponse = this.menuForm.get('menus') as UntypedFormArray;
            if (currentResponse) {
              currentResponse.controls[responseIndex].patchValue({
                menuUrl: this.setValues(response.info.url, ''),
                menuTitle: this.setValues(file2, '')
              });

              this.selectedFormGroupIndexForImage = -1;
            }
            this.selectedFormGroupIndexForImage = -1;
            this.uploadloader = false;

          } else {
            this.toastr.info('', response.info);
            this.selectedFormGroupIndexForImage = -1;
          }
          this.uploadloader = false;
        },
        (err) => {
          this.selectedFormGroupIndexForImage = -1;
        }
      );
    };
  }
  imageCrossClick(url: any) {
    Swal.fire({
      title:
        '<i class="fa fa-exclamation-triangle" style="color:red" aria-hidden="true"></i>&nbsp; Are you sure you want to remove the file?',
      showCancelButton: true,
      customClass: 'swal-wide',
      confirmButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.imageDetailsList = this.imageDetailsList.filter(item => item.image_url !== url);
        this.image_title = '';
        this.image_url = '';
        this.image_url_org = '';
        this.fileType = '';
        this.image_url_org = '';
      }
    });
  }
  removeMedia(responseIndex) {
    Swal.fire({
      title:
        '<i class="fa fa-exclamation-triangle" style="color:red" aria-hidden="true"></i>&nbsp; Are you sure you want to remove the file?',
      showCancelButton: true,
      customClass: 'swal-wide',
      confirmButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        const currentResponse = this.menuForm.get('menus') as UntypedFormArray;
        if (currentResponse) {
          currentResponse.controls[responseIndex].patchValue({
            menuUrl: null,
            menuTitle: null,
          });
        }
      }
    });
  }
  setfileInput() {
    if (this.fileInputEdit?.nativeElement) {
      this.fileInputEdit.nativeElement.value = '';
    }
  }

  sendactionChange(value) {
    this.agentGroup = undefined;
    if (value === 'send_api') {
      this.urlvalidation = true;
    } else {
      this.urlvalidation = false;
    }
    this.updateConnectToFlow = '';
  }
  resetUpdateEmail() {
    this.updateemailForm.reset();
    this.updateemailForm = this.formBuilder.group({
      updateemails: this.formBuilder.array([this.updatecreateEmailFormGroup('')])
    });
  }

  handleEndResponseOnNodeClick(sourceNode) {
    if (sourceNode?.data()?.submit_action?.action) {
      if (sourceNode.data().submit_action.action === 'send_email') {
        this.resetUpdateEmail();
        const emailData = this.updateemailForm.get('updateemails') as UntypedFormArray;
        emailData.removeAt(0);
        this.updateactiontype = sourceNode.data().submit_action.action;
        for (const email of sourceNode.data().submit_action.receiver_email) {
          this.updateaddEmailFormGroup(email);
        }
      }
      if (sourceNode.data().submit_action.action === 'connect_to_flows') {

        this.updateactiontype = sourceNode.data().submit_action.action;

      }
      if (sourceNode.data().submit_action.action === 'send_api') {
        this.updateactiontype = sourceNode.data().submit_action.action;
        this.updatewebhookurl = sourceNode.data().submit_action.webhook_url;
        const obj = sourceNode.data().submit_action.header_data || {};
        const objKey = this.setValues(Object.keys(obj), []);
        this.updateheaderkey = this.checkCondition(objKey.length === 1 , objKey[0], '');
        this.updateheadervalue = this.checkCondition(objKey.length === 1 , sourceNode.data()?.submit_action?.header_data?.[objKey[0]] , '');
      }
      if (sourceNode.data().submit_action.action === 'transfer_to_agent' || sourceNode.data()?.submit_action?.action === 'unsubscribe' || sourceNode.data()?.submit_action?.action === 'subscribe') {
        this.updateactiontype = sourceNode.data().submit_action.action;
      }
      if (sourceNode.data().submit_action.action === 'payment') {
        this.updateactiontype = sourceNode.data().submit_action.action;
        this.paymentSettings = sourceNode.data().submit_action.paymentSettings;
      } else {
        this.paymentSettings = {
          amount: '',
          currencyCode: '',
          title: ''
        };
      }
    } else {
      this.updateactiontype = 'none';
    }
  }

  public updateaddEmailFormGroup(value) {
    const updateemails = this.updateemailForm.get('updateemails') as UntypedFormArray;
    updateemails.push(this.updatecreateEmailFormGroup(value));
  }

  public updateremoveOrClearEmail(i: number) {
    const updateemails = this.updateemailForm.get('updateemails') as UntypedFormArray;
    if (updateemails.length > 1) {
      updateemails.removeAt(i);
    } else {
      updateemails.reset();
    }
  }

  private updatecreateEmailFormGroup(data): UntypedFormGroup {
    return new UntypedFormGroup({
      'updateemailAddress': new UntypedFormControl(data, Validators.email)
    });
  }

  handleInput(event, i) {
    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (!EMAIL_REGEXP.test(event)) {
      this.emailerror[i] = true;
    } else {
      this.emailerror[i] = false;
    }
  }
  updateConditionValue(conditionvalue) {
    conditionvalue.forEach(element => {
      let value;
      if (element.condition && element.condition !== '') {
        if (element.condition.value === 'Is_not_a_number' || element.condition.value === 'Is_a_number' || element.condition.value === 'Is_email' || element.condition.value === 'Is_not_a_email' || element.condition.value === 'Is_not_media' || element.condition.value === 'Is_media' || element.condition.value === 'Is_invalid' || element.condition.value === 'Is_valid' || element.condition.value === 'Is_not_phone_number' || element.condition.value === 'Is_phone_number') {
          value = '';
          this.conditionForm.value.conditions[0].conditionValue = '';
        } else {
          value = element.conditionValue;
        }
        this.checkValueError(element);
        this.parent_qa = this.conditionCheckNodeName + ' ' + element.condition.name.toLowerCase() + ' ' +  value;
      }
    });
  }
  updateCurrentNode(type: any = '') {
    if (type !== 'update') {
      this.checkingSort();
    this.checkingSortCommon();
    }
    this.prepareUpdateArguments();
    if (this.sortCheckingIndicator && this.node_type === 'menu_item' ) {

      this.sortCheckingIndicator = false;
      this.idErrormsgSort = true;
      return;
    }
    if (this.sortCheckingIndicatorCommon && this.node_type === 'menu_item') {
      this.sortCheckingIndicatorCommon = false;
      this.idErrormsgSort = true;
      return;
    }

    this.idErrormsgSort = false;

    if (!this.isUpdateValid()) {
      return;
    }
    this.sortCheckingIndicatorCommon = false;
    this.sortCheckingIndicator = false;
    let arg = this.buildUpdateArgumentObject();
    if (this.updateactiontype === 'none') {
      arg['submit_action'] = {};
    }
    arg = this.handleEndResponse(arg);
    arg = this.handleSpecialNodeTypes(arg);
    this.flowBuilderService.flowNodeDataSubmit(arg).subscribe(
      data => this.handleUpdateSuccess(arg),
      err => this.handleUpdateError()
    );
  }

  prepareUpdateArguments() {
    if (this.nodetype === 'conditional_response') {
      this.handlecheckWithCondition();
    }
    if (this.nodetype === 'condition') {
      this.updateConditionValue(this.conditionForm.value.conditions);
    }
  }

  isUpdateValid() {
    return this.checkerrorstatus() && !this.node_valueErrorMsg && !this.node_menuerrormsg;
  }

  buildUpdateArgumentObject() {
    const arg = {
      'node_type': this.node_type,
      'text': this.formatText(this.parent_qa),
      'flow_id': this.flowbuilderId,
      'type': 'text',
      'node_id': this.selectedNodeData.id,
      'node_name': this.nodeName,
      'submit_action': this.updateactiontype === 'none' ? {} : null,
      'node_identifier': this.node_type === 'menu_item' || this.node_type === 'menu_question' ? this.setValues(this.nodeIdentifier, '') : null,
      'image_details': this.getImageDetails(),
      'sort_number': this.node_type === 'menu_item' ? this.sortNo : null,
      'hide_menu' : this.node_type === 'menu_item' ? this.Node_status : false,
      'removed_data' : this.selectedNodeData.removed_data ? this.selectedNodeData.removed_data : {}
    };
    if (this.node_type === 'slot_question' || this.node_type === 'menu_question') {
     if (this.userAttribute.length === 0) {
       arg['user_attribute'] = {};
     } else {
       arg['user_attribute'] = this.userAttribute ? this.userAttribute : {};
     }

    }
    if (this.shouldProcessVariableArray()) {
      arg['variableArray'] = this.processVariableArray(this.parent_qa);
    }

    return arg;
  }

  handleSpecialNodeTypes(arg) {
    if (this.nodetype === 'conditional_response') {
      return this.handleConditionalResponse();
    }
    if (this.shouldUpdateConditionForm()) {
      return this.updateConditionForm(this.conditionForm.value.conditions);
    }
    return arg;
  }

  formatText(label) {
    label = label?.replace(/&amp;/g, '&');
    label = label?.replace(/&nbsp;/g, ' ');
    label = label?.replace(/ <\/em>/g, '</em> ');
    if (label?.includes('style')) {
      label = label.replace(/(style=")([a-z0-9:;.\s()\-,]*)(")/gi, '');
    }
    return label;
  }
  getImageDetails() {
    if (this.imageDetailsList.length) {
      const image_array = this.imageDetailsList.map(images => {
        const obj = images;
        obj.image_url = obj.image_url_org;
        return obj;
      });
      return image_array;
    } else {
      return [];
    }
  }

  shouldProcessVariableArray() {
    return ['end_response', 'slot_question', 'menu_question', 'menu_item'].includes(this.node_type) && this.variableArray && this.variableArray.length > 0;
  }

  shouldUpdateConditionForm() {
    return this.conditionForm?.value && this.conditionForm.value.conditions.length > 0 && this.conditionalResponseType !== '' && this.nodetype === 'condition';
  }

  handleUpdateSuccess(arg) {
    if (arg['node_type'] === 'end_response') {
      this.flowbuilderData('update', 'end_response', arg['node_id']);
    } else {
      this.flowbuilderData('update');
    }

    this.toastr.info('', this.translate.instant('toastr.node_updated'));
    this.actiontype = 'none';
    this.closeRight();
  }

  handleUpdateError() {
    this.toastr.error('', this.translate.instant('toastr.something_wrong'));
  }


  onSelectChange(event: any): void {
    this.selectedConditionalResponseValue = this.variablesList[0];
    const selectedValue = event.target.value;
    const userInputText = 'Check with: User Input ';
    let text = '';
    let parent_qa = '';
    if (selectedValue === 'user_input') {
      this.prevMessage.forEach((data: any) => {
        text += data.node_name + ' ( ' + data.dummyLabel + ' ) / ' ;
        parent_qa += data.label + ' / ';
      });
      if (text.endsWith(' / ')) {
        text = text.slice(0, -3);
      }
      if (parent_qa.endsWith(' / ')) {
        parent_qa = parent_qa.slice(0, -3);
      }
      this.userInputNode = text;
      this.parent_qa = userInputText + parent_qa;
    } else {
      this.userInputNode = '';
      this.parent_qa = '';
    }
  }
  updateConditionForm(conditionvalue) {
    const values = [];
    conditionvalue.forEach(element => {
      let value;
      if (element.condition && element.condition !== '') {
        if (element.condition.value === 'Is_not_a_number' || element.condition.value === 'Is_a_number' || element.condition.value === 'Is_email' || element.condition.value === 'Is_not_a_email' || element.condition.value === 'Is_media' || element.condition.value === 'Is_not_media') {
          value = '';
          this.conditionForm.value.conditions[0].conditionValue = '';
        } else {
          value = element.conditionValue;
        }
        this.checkValueError(element);
        values.push({
          'node_type': this.nodetype,
          'text': this.conditionalResponseType !== 'authentication' ? this.conditionCheckNodeName + ' ' + element.condition.name.toLowerCase() + ' ' +  value : this.conditionCheckNodeName + ' ' + element.condition.name.toLowerCase(),
          'flow_id': this.flowbuilderId,
          'type': 'text',
          'sent_by': 'bot',
          'node_id': this.selectedNodeData.id,
          'appId': this.sharedService.appId,
          'condition_check': this.conditionalResponseType,
          'condition_id': this.conditionalId,
          'condition_operator': element.condition.value,
          'condition_value': this.conditionalResponseType !== 'authentication' ? value : '' ,
          'node_name': this.nodeName,
          'authentication' : this.Node_auth ? this.Node_auth : false,
        });
      }
      if (values.length === 0) {
        this.node_menuerrormsg = this.translate.instant('flowBuilder.idErrormsg');
      } else {
        this.node_menuerrormsg = '';
      }
    });
    return values[0];
  }

  checkValueError(element) {
    if ((element.conditionValue === '' || element.conditionValue === null ) && element.condition.value !== 'Is_not_a_number' && element.condition.value !== 'Is_a_number' && element.condition.value !== 'Is_email' && element.condition.value !== 'Is_not_a_email' && element.condition.value !== 'Is_not_media' && element.condition.value !== 'Is_media' && element.condition.value !== 'Is_invalid' && element.condition.value !== 'Is_valid' && element.condition.value !== 'Is_not_phone_number' && element.condition.value !== 'Is_phone_number') {
      //this.node_valueErrorMsg = this.translate.instant('flowBuilder.idErrormsg');
    } else {
      this.node_valueErrorMsg = '';
    }
  }

  checkerrorstatus() {
    if (this.actiontype === 'transfer_to_agent' && !this.updateAgentGroup) {
      this.agentGrouperrormsg = this.translate.instant('flowBuilder.agentGrouperrormsg');
      return false;
    } else {
      this.agentGrouperrormsg = '';
    }
    if (this.parent_qa === '' || this.parent_qa === undefined || this.parent_qa === null) {
      this.parenterrormsg = 'Field is required';
      return false;
    } else {
      this.parenterrormsg = '';

    }
    if (this.node_type === 'root' || this.node_type === 'end_response' || this.node_type === 'menu_item') {
      this.parentNodeErrorMsg = '';
    }
    if (!this.checkNodeName()) {
      return false;
    }
    if (this.updateactiontype === 'payment') {
      const inValid =  Object.values(this.paymentSettings).some((v) => v === '');
      if (inValid) {
        this.paymentErrormsg = 'Field is required';
        return false;
      }
    }
    return true;
  }

  checkNodeName() {
    for (const key in this.flows) {
      if (this.flows[key].node_name && (this.flows[key].id !== this.node_id)) {
        const index = this.flows[key]?.node_name?.toLowerCase() === this.nodeName?.toLowerCase();
        if (index) {
          this.toastr.error('', this.translate.instant('toastr.nodeId_exists'));
          return false;
        }
      }
    }
    return true;
  }

  processVariableArray(string) {
    this.variableParam = '';
    if (this.variableArray?.length) {
      string = string.replace(/<[^>]+>/g, ''); // NOSONAR
      this.variableArray.forEach((variableItem) => {
        let i = -1;
        i = string.search(variableItem.variable);
        if (i === -1) {
          const index = this.variableArray.findIndex(
            (item1) => item1._id === variableItem._id
          );
          if (index !== -1) {
            this.variableArray.splice(index, 1);
          }
        }
      });
    }
    return this.variableArray;
  }

  handleConditionalResponse() {
    let values = {};
    const checkWithValues = this.handlecheckWithCondition();
    values = {
        'node_type': this.nodetype,
        'text': checkWithValues.text,
        'flow_id': this.flowbuilderId,
        'type': 'text',
        'sent_by': 'bot',
        'prev_message': this.previousNode,
        'node_id': this.node_id,
        'node_name': this.nodeName,
        'appId': this.sharedService.appId,
        'condition_check': this.checkWith,
        'condition_id': checkWithValues.condition_id
      };
    return values;
  }

  selectedConditionalvariable(event: any) {
    const variableData = this.variablesList?.filter((data: any) => data?.variable === event);
    this.selectedConditionalResponseValue = variableData[0];
  }
  handlecheckWithCondition() {
    const result = { text: '', condition_id: '' };
    if ( this.checkWith === 'api_response' ) {
      result.text = 'Check with: API Response' + '\n' + this.selectedConditionalResponseValue?.variable;
      this.parent_qa = 'Check with: API Response' + '\n' + this.selectedConditionalResponseValue?.variable;
      result.condition_id = this.selectedConditionalResponseValue._id;
    } else if (this.checkWith === 'send_api') {
      result.text = 'Check with: Send API response';
      this.parent_qa = 'Check with: Send API response';
      result.condition_id = '';
    } else if (this.checkWith === 'authentication') {
      result.text = 'Check with: authentication';
      this.parent_qa = 'Check with: authentication';
      result.condition_id = '';
    } else {
      result.text = this.parent_qa;
      result.condition_id = this.previousNode[0];
    }
    return result;
  }

  handleEndResponse(arg) {
    const emailValues = [];
    const emailvalue = this.setEmailValue();
    emailvalue.forEach(element => {
      if (element.updateemailAddress && element.updateemailAddress !== '') {
        emailValues.push(element.updateemailAddress);
      }
    });
    this.handleSendAPI(arg);
    this.handleSendEmail(emailValues, arg , this.updateactiontype);
    this.handleTransferToAgent(arg);
    this.handleConnectToFlows(arg);
    this.handleCreatePayemnt(arg);
    if (this.updateactiontype === 'unsubscribe' || this.updateactiontype === 'subscribe') {
      arg['submit_action'] = {
        'action': this.updateactiontype
      };
    }
    return arg;
  }

  setEmailValue() {
    if (this.updateemailForm?.value) {
      return this.updateemailForm.value.updateemails;
    } else {
      return '';
    }
  }
  handleTransferToAgent(arg) {
    if (this.updateactiontype === 'transfer_to_agent') {
      arg['submit_action'] = {
        'action': this.updateactiontype
      };
      arg['groupName'] = this.updateAgentGroup?.group_name;
      arg['groupId'] = this.updateAgentGroup?._id;
    }
  }
  handleConnectToFlows(arg) {
    if (this.updateactiontype === 'connect_to_flows') {
    if (this.updateactiontype && this.updateConnectToFlow._id) {
      arg['submit_action'] = {
        'action': this.updateactiontype,
        'flow_id': this.updateConnectToFlow._id
      };
    }
    }
    }
  handleSendEmail(emailValues, arg, action) {
    if (action === 'send_email') {
      const emaildata = {
        'action': action,
        'receiver_email': emailValues
      };

      if (emailValues.length > 0) {
        arg['submit_action'] = emaildata;
      }
      if (this.emailerror.includes(true)) {
        return true;
      }
    }

  }
  handleSendAPI(arg) {
    if (this.updateactiontype === 'send_api') {
      if (this.urlvalidation || !this.updatewebhookurl) {
        return true;
      } else {
        const apidata = {
          'action': this.updateactiontype,
          'webhook_url': this.updatewebhookurl,
          'header_data': {},
        };
        const headerkey = this.setValues(this.updateheaderkey , '');
        if (headerkey) {
          apidata.header_data[this.updateheaderkey] = this.setValues(this.updateheadervalue , '');
        }
        arg['submit_action'] = apidata;
      }
    }
  }


  setNodeValues(sourceNode: any) {
    this.validInvalidList = this.conditionList.filter(item => item.name === 'Valid' || item.name === 'Invalid');
    this.withoutValidInvalidList = this.conditionList.filter(item => item.name !== 'Valid' && item.name !== 'Invalid');
    if (sourceNode.data().node_type === 'menu_question') {
      this.nodetype = 'menu_item';
      this.node_type = sourceNode.data().node_type;
      this.variableArray = this.setValues(sourceNode.data().variableArray, []);
      this.userAttribute = this.setObject(sourceNode.data().user_attribute , '');
    } else if (sourceNode.data().node_type === 'end_response') {
      this.nodetype = sourceNode.data().node_type;
      this.node_type = sourceNode.data().node_type;
      this.variableArray = this.setValues(sourceNode.data().variableArray, []);
    } else if (sourceNode.data().node_type === 'conditional_response' && sourceNode.data().condition_check === 'user_input') {
      this.nodetype = sourceNode.data().node_type;
      this.node_type = sourceNode.data().node_type;
      this.conditionalId = sourceNode.data().condition_id;
      this.checkResponseType(sourceNode);
    } else if (sourceNode.data().node_type === 'conditional_response') {
      this.checkResponseType(sourceNode);

      this.authenticationCheckingConditionalRes(sourceNode);
      this.nodetype = sourceNode.data().node_type;
      this.node_type = sourceNode.data().node_type;
      const checkIndex = this.conditionalResponseVariableList.filter(e => e._id === sourceNode.data().condition_id);
      if (checkIndex.length > 0) {
        this.selectedConditionalResponseValue = checkIndex[0];
      } else {
        this.selectedConditionalResponseValue = this.conditionalResponseVariableList[0];
      }
      this.conditionalId = sourceNode.data().condition_id;
    } else if (sourceNode.data().node_type === 'condition') {
      this.Node_auth = sourceNode.data().authentication;

      this.authenticationConditionRes(sourceNode);
      this.phoneNumberInfoCheck = this.phoneNumberInfoCheckvalue(sourceNode);
      this.checkBoxDisplay = this.checkBoxDisplayValue(sourceNode);

      this.nodetype = sourceNode.data().node_type;
      this.node_type = sourceNode.data().node_type;
      this.conditionalResponseType = sourceNode.data().condition_check;

       const condition = this.conditionList.filter(obj => {
         return obj.value === sourceNode.data().condition_operator;
       });
       const isNumeric = this.changeCondition(sourceNode.data().condition_operator);
       const control = <UntypedFormArray>this.conditionForm.controls.conditions;
       control.controls = [];
       control.push(
         this.formBuilder.group({
           condition: condition,
           conditionValue: sourceNode.data().condition_value,
           isNumeric: isNumeric
         })
       );
      const checkIndex = this.conditionalResponseVariableList.filter(e => e._id === sourceNode.data().condition_id);
      if (checkIndex.length > 0) {
        this.selectedConditionalResponseValue = checkIndex[0];
      } else {
        this.selectedConditionalResponseValue = this.conditionalResponseVariableList[0];
      }
       this.conditionalId = sourceNode.data().condition_id;
    }  else if (sourceNode.data().node_type === 'menu_item') {
      this.nodetype = 'menu_item';
      this.node_type = sourceNode.data().node_type;
    } else {
      this.node_type = sourceNode.data().node_type;
      this.nodetype = 'slot_question';
      this.variableArray = this.setValues(sourceNode.data().variableArray , []);
      this.userAttribute = this.setObject(sourceNode.data().user_attribute , '');
    }
  }
  authenticationConditionRes(sourceNode) {
    this.previousNodeAuth = sourceNode.data().prev_message;
    this.cy.nodes().forEach((node) => {

       if (this.previousNodeAuth?.includes(node.data().id)) {

       this.ValueDisplayAuthentication = node.data().condition_check;
      }
    }
    );
  }
  authenticationCheckingConditionalRes(sourceNode) {
    this.previousNodeAuth = sourceNode.data().prev_message;
    this.cy.nodes().forEach((node) => {
      if (this.previousNodeAuth?.includes(node.data().id) && node.data().authentication) {
        this.authdisplay = node.data().authentication;
      }
    }
    );
  }
  phoneNumberInfoCheckvalue(sourceNode) {
    if (sourceNode.data().condition_operator === 'Is_phone_number' ) {
      return true;
    } else {
      return false;
    }

    }
    checkBoxDisplayValue(sourceNode) {
      if (sourceNode.data().condition_operator === 'Is_email' || sourceNode.data().condition_operator === 'Is_phone_number' ) {
        return true;
      } else {
        return false;
      }

      }
  checkResponseType(sourceNode) {
    if (sourceNode.data().label.includes(':')) {
      if (sourceNode.data().label.split(':')[1].includes('API Response')) {
        this.conditionalResponseType = 'api_response';
      } else if (sourceNode.data().label.split(':')[1].includes('User Input')) {
        this.conditionalResponseType = 'user_input';
      } else {
        this.conditionalResponseType = 'send_api';
      }
    }
    if (sourceNode.data().condition_check === 'api_response') {
      this.conditionalResponseValue = sourceNode.data().label.split(':')[1].split('\n')[1];
    }
  }

  apiUpdateValidate() {
    const arg = {
      header_data: {},
      webhook_url: this.updatewebhookurl,
      flowName: this.flowName
    };
    const headerkey = this.updateheaderkey ? this.updateheaderkey : '';
    if (headerkey) {
      arg.header_data[this.updateheaderkey] = this.updateheadervalue ? this.updateheadervalue : '';
    }
    this.flowBuilderService.apiValidate(arg).subscribe(data => {
      if (data['status'] === 200) {
        this.urlvalidation = false;
      } else {
        this.urlvalidation = true;
        this.validationClick = true;
        this.toastr.error('', this.translate.instant('toastr.api_Validate_failed'));
      }
    }, (error) => {
      this.urlvalidation = true;
    });
  }

  saveFlowbuilder() {
    const nodeData = [];
    this.cy?.nodes().forEach((x: any) => {
      if (x.data().node_type && x.data().node_type !== '') {
        const flowArg = {
          _id: x.data().id ? x.data().id : '',
          node_type: x.data().node_type,
          node_name: x.data().node_name,
          text: x.data().label,
          image_details: x.data().image_details,
          condition_check: x.data().condition_check,
          submit_action: x.data().submit_action,
          next_message: x.data().next_message,
          prev_message: x.data().prev_message,
          variableArray: x.data().variableArray,
          user_attribute: x.data().user_attribute,
          sortNumber: x.data().sortNumber,
          hideMenu: x.data().hideMenu,
          authentication: x.data().authentication,
          condition_id: x.data().condition_id,
          condition_operator: x.data().condition_operator,
          condition_value: x.data().condition_value,
          type: x.data().type,
          flow_id: x.data().flow_id,
          groupId: x.data().groupId,
          conditionCheckNodeName: x.data().conditionCheckNodeName,
          nodePosition: x.position(),
          conditionDetails:x.data().conditionDetails,       
        };
        nodeData.push(flowArg);

      }
    });

    const arg = {
      'flow_id': this.flowbuilderId,
      'line_pattern': this.linePatternItem.value,
      'line_waypoints': this.lineTypeItem.value,
      'flowDetails': {
        'language': this.language,
        'flowNodes': nodeData
      }
    };
    this.flowBuilderService.saveFlowBuilderData(arg).subscribe({
      next: (data) => {
        if (data['status'] && data['status'] === 200) {
          this.toastr.info('', data['message']);
        } else {
          this.toastr.error('', data['message']);
        }

      },
      error: (error) => {
        this.toastr.error('', error.message);
      },
      complete: () => {

      },
    });
  }

  select() {
    this.selectionModeEnabled = !this.selectionModeEnabled;
    if(!this.selectionModeEnabled) {
      this.deleteedge = false;
      this.copyNode = false;
      this.cy?.userPanningEnabled(false);
      this.cy?.userZoomingEnabled(false);
      this.renderer.setStyle(this.graphArea.nativeElement, 'cursor', 'grab');
    } else {
      this.deleteedge = true;
      this.copyNode = true;
      this.cy?.userPanningEnabled(true);
      this.cy?.userZoomingEnabled(true);
      this.renderer.setStyle(this.graphArea.nativeElement, 'cursor', 'pointer');
    }

  }


  toggleFullScreen() {
    const flowbuilderContainer = this.el.nativeElement.querySelector('#flowbuilder');
    if (!document.fullscreenElement) {
      if (flowbuilderContainer.requestFullscreen) {
        const cyContainer = this.el.nativeElement.querySelector('#cy');
        cyContainer.style.width = window.innerWidth + 'px';
        cyContainer.style.height = window.innerHeight + 'px';

        this.cy?.resize();
        this.cy?.fit();
        flowbuilderContainer.requestFullscreen();
        this.isFullScreen = true;
        document.addEventListener('fullscreenchange', this.onFullScreenChange.bind(this));
      } else {
        this.toastr.error('', 'Full screen mode is not supported in this browser.');
      }
    } else if (document.exitFullscreen)  {
        document.exitFullscreen();
        this.isFullScreen = false;
    }
  }

  onFullScreenChange() {
    if (!document.fullscreenElement) {
      this.isFullScreen = false;
    }
  }

  downloadImage() {
    const cy = this.cy;
    const imgBlob = cy.png({ full: true, output: 'blob' });

    const a = document.createElement('a');
    a.href = URL.createObjectURL(imgBlob);
    a.download = this.flowName + '.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  linePatternDropdown() {
    this.linePatternOpen = !this.linePatternOpen;
  }

  selectItem(item: any) {
    this.lineTypeItem = item;
    this.dropdownOpen = false;
    this.cy?.style()
      .selector('edge')
      .style({
        'curve-style': item.value,
      }).update();

    this.saveFlowbuilder();
  }
  selectPatternItem(item: any) {
    this.linePatternItem = item;
    this.linePatternOpen = false;
    this.cy?.style()
      .selector('edge')
      .style({
        'line-style': item.value,
      }).update();

    this.saveFlowbuilder();
  }

  downloadJPG() {
    const cy = this.cy;
    const padding = 40;

    cy.jpg({ full: true, output: 'blob-promise' }).then((imgBlob) => {
      const originalImage = new Image();
      originalImage.src = URL.createObjectURL(imgBlob);

      originalImage.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = originalImage.width + (2 * padding);
        canvas.height = originalImage.height + (2 * padding);
        const context = canvas.getContext('2d');
        context.fillStyle = '#ffffff';
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(originalImage, padding, padding);
        canvas.toBlob((blob) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = this.flowName + '.jpg';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }, 'image/jpeg');
      };
    });
  }

  downloadSVG() {
    const cy = this.cy;
    const svgContent = cy.svg({ scale: 1, full: true });
    const blob = new Blob([svgContent], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = this.flowName + '.svg';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  downloadCSV() {
    const encapsulateInQuotes = (val: any) => {
      let value = val || '';
      if (typeof value === 'string') {
        value = value.replace(/"/g, '""');
        return `"${value}"`;
      }
      return value;
    };

    let csvContent = 'ID, Node Type, Label, Type, Previous Message, Node Name, Next Message, Condition Check, Condition ID, Condition Operator, Condition Value, Condition Check Node Name,Condition Details, Group ID, Position,Variables,User Attributes,Submit Action,Sort Order,Hide Menu, Deleted Status\n';

    this.cy.nodes().forEach(node => {
      if (node.data('node_type') && node.data('node_type') !== null && node.data('node_type') !== '') {
        const deletedData = this.checkForDeletedData(node);
        const label = node.data('label'); // NOSONAR
          csvContent += `${node.id()},${encapsulateInQuotes(node.data('node_type'))},${encapsulateInQuotes(label)},${encapsulateInQuotes(node.data('type'))},${encapsulateInQuotes(JSON.stringify(node.data('prev_message')))},${encapsulateInQuotes(node.data('node_name'))},${encapsulateInQuotes(JSON.stringify(node.data('next_message')))},${encapsulateInQuotes(node.data('condition_check'))},${encapsulateInQuotes(node.data('condition_id'))},${encapsulateInQuotes(node.data('condition_operator'))},${encapsulateInQuotes(node.data('condition_value'))},${encapsulateInQuotes(node.data('conditionCheckNodeName'))},${encapsulateInQuotes(JSON.stringify(node.data('conditionDetails')))},${encapsulateInQuotes(node.data('groupId'))},${encapsulateInQuotes(JSON.stringify(node.position()))},${encapsulateInQuotes(JSON.stringify(node.data('variableArray')))},${encapsulateInQuotes(JSON.stringify(node.data('user_attribute')))},${encapsulateInQuotes(JSON.stringify(node.data('submit_action')))},${encapsulateInQuotes(node.data('sortNumber'))},${encapsulateInQuotes(node.data('hideMenu'))}`;
        if (Object.keys(deletedData).length) {
          csvContent += `,${encapsulateInQuotes(JSON.stringify(deletedData))}\n`;
        } else {
          csvContent += '\n';
        }
      }
    });
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', this.flowName + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  ngAfterViewInit() {
    this.childWidth = this.parentDiv.nativeElement.offsetWidth;
    this.childHeight = this.parentDiv.nativeElement.offsetHeight;
    this.cdRef.detectChanges();

    setTimeout(() => {
      this.simulateTapOnBlankSpace();
      this.setRootNodePosition();
      this.loaderShow = false;
    }, 1000);
  }


  childWidthMethod() {

    this.childWidth = this.parentDiv?.nativeElement?.offsetWidth;
    return this.childWidth;
  }
  childHeightMethod() {

    this.childHeight = this.parentDiv?.nativeElement?.offsetHeight;
    return this.childHeight;
  }

  changeLayout(orientation: string) {
    const layoutOptions = {
      name: 'dagre',
      nodeSep: 50,
      edgeSep: 10,
      rankSep: 100,
      rankDir: orientation === 'horizontal' ? 'LR' : 'TB',
      animate: true,
      animationDuration: 500,
    };

    this.cy?.layout(layoutOptions).run();
    this.saveFlowbuilder();
  }
  ngOnDestroy(): void {
    const elements = document.querySelectorAll('.popper-div');
    if (elements !== null) {
      elements.forEach((element) => {
          element.remove();
      });
    }
    this.removeMenuList();
  }
  navigateTov2() {
    this.router.navigate([environment.dashboardPrefix + '/flow-builderv2/' + this.flowbuilderId]);
  }
  deleteEdge() {
    this.deleteedge = true;
    let target = this.evtTarget;
    if (!this.evtTarget &&  !this.multipleNodeSelected?.length) {
      return;
    }
    let type = 'edge';
    if (this.multipleNodeSelected.length) {
      type = 'node';
      target = this.selectedNodes;
    }
    this.showDeleteMenu(target, type);
  }
  copyNodes() {
    this.copyNode = true;
    this.pasteNodeData = false;
    let target = this.evtTarget;
    if (!this.evtTarget && !this.multipleNodeSelected?.length) {
      return;
    }

    let type = 'edge';
    if (this.multipleNodeSelected.length) {
      type = 'node';
      target = this.selectedNodes;
    }

    // Option 1: Using map directly
    const selectedDataOriginal = target.map((node: any) => {
      const position = node.position();
      node.data({
        ...node.data(),
        position: { x: position.x + 200, y: position.y + 50 }
      });
      return node.data();
    });

    const hasRootNode = selectedDataOriginal.some(item => item.node_type === 'root');
    if (hasRootNode) {
      this.pasteNodeData = true;
      this.toastr.error('', this.translate.instant('toastr.root_node_cannot_be_copied'));
      return;
    }
    this.copiedNodeData = selectedDataOriginal.map(item => ({
      _id: item.id,
      node_type: item.node_type,
      node_name: `${item.node_name}_${this.getFormattedTimestamp()}`,
      text: item.label,
      flowNodeUsageCount: 0,
      dummyLabel: this.getLabelchanges(item.label),
      attachments: item.image_details,
      condition_check: item.condition_check,
      submit_action: item.submit_action,
      next_message: item.next_message,
      prev_message: item.prev_message,
      variableArray: item.variableArray,
      userAttribute: item.user_attribute,
      sortNumber: item.sortNumber,
      hideMenu: item.hideMenu,
      authentication: item.authentication,
      condition_id: item.condition_id,
      condition_operator: item.condition_operator,
      condition_value: item.condition_value,
      type: item.type,
      flow_id: item.flow_id,
      appid: this.sharedService.appId,
      groupId: item.groupId,
      conditionCheckNodeName: item.conditionCheckNodeName,
      removed_data: item.removed_data,
      nodePosition: item.position
    }));
    this.pasteNodes();
  }

  pasteNodes() {
    this.pasteNodeData = true;


    const arg = {
      'flow_id': this.flowbuilderId,
      'flowDetails': {
        'language': this.language,
        'flowNodes': this.copiedNodeData
      }
    };
    this.flowBuilderService.pasteNode(arg).subscribe({
      next: (data) => {
        if (data['status'] && data['status'] === 200) {
          this.toastr.info('', data['message']);
          this.flowbuilderData('update');
          this.pasteNode(data.copyFlow.data);
          this.copiedNodeData = [];
        } else {
          this.toastr.error('', data['message']);
        }

      },
      error: (error) => {
        this.toastr.error('', error.message);
      },
      complete: () => {

      },
    });
    //this.showDeleteMenu(target, type);
  }

  pasteNode(data) {

    const nodes = data.flows.map((flow) => ({
      group: 'nodes',
      data: {
        id: flow.id,
        node_type: flow.node_type,
        node_name: flow.node_name,
        label: flow.label,
        flowNodeUsageCount: flow.flowNodeUsageCount,
        dummyLabel: this.getLabelchanges(flow.label),
        image_details: flow.image_details,
        condition_check: flow.condition_check,
        submit_action: flow.submit_action,
        next_message: flow.next_message,
        prev_message: flow.prev_message,
        variableArray: flow.variableArray,
        user_attribute: flow.userAttribute,
        sortNumber: flow.sortNumber,
        hideMenu: flow.hideMenu,
        authentication: flow.authentication,
        condition_id: flow.condition_id,
        condition_operator: flow.condition_operator,
        condition_value: flow.condition_value,
        type: flow.type,
        flow_id: flow.flow_id,
        groupId: flow.groupId,
        conditionCheckNodeName: flow.conditionCheckNodeName,
        removed_data: flow.removed_data
      },
      position: flow.nodePosition, // Use the position from the data
    }));

    // Extract edges
    const edges = data.flowLinkData.map((link) => ({
      group: 'edges',
      data: {
        source: link.source,
        target: link.target,
      },
    }));
    this.cy.add([...nodes, ...edges]);
    this.cy.nodes().unselect();

    // Select nodes by IDs
    data.flows.forEach((flow) => {
      const node = this.cy.getElementById(flow.id);
      if (node && node.isNode()) {
        node.select();
      } else {
        console.warn(`Node with ID "${flow.id}" not found.`);
      }
    });
  }

  getFormattedTimestamp(): string {
    const date = new Date().getTime();
    return date.toString();

  }
  openDialog(): void {
    this.flowbuilderData('preview');
  }
  previewOpen() {
    const dialogRef = this.dialog.open(FlowbuilderPreviewComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        graph: this.graph,
        language: this.language,
        flowName: this.flowName,
        linepattern: this.linePatternItem,
        linetype: this.lineTypeItem,
        flowbuilderId: this.flowbuilderId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("result dialog", result)
    });
}
searchNode(query: string) {
  this.cy?.nodes().forEach((node) => {
    node.style({
      'text-background-color': '',
      'text-background-opacity': 0,
      'text-background-padding': 0,
      'text-background-shape': ''
    });
  });
  this.Query = query;
  this.foundNode = this.cy?.nodes()?.filter(node => {
    const value = query?.toLowerCase();
    let submitAction;
    let receiverEmail;
    if (node.data()?.submit_action) {
      submitAction = Object.values(node.data()?.submit_action);
      receiverEmail = node.data()?.submit_action?.receiver_email;
    }
    return node.data('dummyLabel')?.toLowerCase().includes(value) ||
      node.data('node_name')?.includes(value) ||
      (submitAction?.length && submitAction?.some(s => typeof s === 'string' && s?.toLowerCase().includes(value))) ||
      receiverEmail?.some(s => s.toLowerCase()?.includes(value));
  }
  );
  if (this.foundNode?.nonempty()) {
    if (query.length >= 1) {
    this.foundNode.forEach(node => {
      node.style({
        'text-background-color': 'yellow',
      'text-background-opacity': 1,
      'text-background-padding': 4,
      'text-background-shape': 'roundrectangle'
      });
    });
    this.numberOfNodes = true;
    this.cy?.animate({
      center: { eles: this.foundNode[0]  },
      zoom: 1
    }, {
      duration: 500
    });
  } else {
    this.numberOfNodes = false;


  }
}


}
searchup() {
  if (this.foundNode.length === 0) {
    return;
  }
  this.count = this.count + 1;
  if (this.count > this.foundNode.length - 1) {
    this.count = 0;
    }
  if (this.foundNode?.nonempty()) {
    if (this.Query.length >= 1) {

    this.cy?.animate({
      center: { eles: this.foundNode[this.count]  },
      zoom: 1
    }, {
      duration: 500
    });
  }
}
}
searchdown() {
  if (this.foundNode.length === 0) {
    return;
  }
  this.count = this.count - 1;
  if (this.count < 0) {
  this.count = this.foundNode.length - 1;
  }
  if (this.foundNode?.nonempty()) {
    if (this.Query.length >= 1 ) {
    this.cy?.animate({
      center: { eles: this.foundNode[this.count]  },
      zoom: 1
    }, {
      duration: 500
    });
  }
}
}

setConditionListForAPI(sourceNode) {
  if (sourceNode.data().node_type === 'condition' && sourceNode.data().condition_check === 'api_response') {
    const index = this.conditionList.findIndex(
      (item) => item.value === 'Is_not_media'
    );
    if (index !== -1) {
      this.conditionList.splice(index, 1);
    }
    const index2 = this.conditionList.findIndex(
      (item) => item.value === 'Is_media'
    );
    if (index2 !== -1) {
      this.conditionList.splice(index2, 1);
    }
  } else {
    this.conditionList = JSON.parse(JSON.stringify(this.conditionListCopy));
  }
}
FindParentForSort() {
  this.parentNode = [];
  this.totalChildArrays = [];
  this.parentNodeDetails = [];
  this.flatArrayOfTotal = [];
  if (this.checkingNodes !== 'menu_item') {
    return;
  }

    this.parentNode = this.selectedNodesId.prev_message;

  this.parentChild = [];

  this.cy.nodes().forEach(node => {

    if (this.parentNode.includes(node.data().id)) {
       this.parentNodeDetails.push(node.data());
    }
  });

  this.parentNodeDetails.forEach(element => {
    this.totalChildArrays.push(element.next_message);
    });

   this.flatArrayOfTotal = this.totalChildArrays.flat();
  this.totalDuplicates = this.findDuplicates(this.flatArrayOfTotal);
  this.uniqueArray = [...new Set(this.flatArrayOfTotal)];
}
findDuplicates = arr => {
  this.duplicates = [];
  const seen = {};
   this.duplicates = arr.filter(item => {
    return seen.hasOwnProperty(item) ? true : (seen[item] = false); // NOSONAR
  });
  return this.duplicates;
}


nlpResponseType(type): void {
  this.Node_status = type;
}
navigateLeft() {
  const currentPan = this.cy.pan();
  this.cy.pan({
    x: currentPan.x + 500,
    y: currentPan.y
  });
}
navigateRight() {
  const currentPan = this.cy.pan();
  this.cy.pan({
    x: currentPan.x - 500,
    y: currentPan.y
  });
}
navigateUp() {
  const currentPan = this.cy.pan();
  this.cy.pan({
    x: currentPan.x ,
    y: currentPan.y + 300
  });
}
navigateDown() {

    const currentPan = this.cy?.pan();
    this.cy?.pan({
      x: currentPan.x ,
      y: currentPan.y - 300
    });

}
removeOrphanOverlays() {
  const cy = this.cy; // Ensure you have access to your Cytoscape instance
  // Select all elements considered as node overlays
  const overlays = document.querySelectorAll('.node-overlay img.node-image');
  overlays.forEach(overlay => {
  const nodeId = overlay.getAttribute('data-node-id');
  const nodeExists = cy.getElementById(nodeId).length > 0;
  if (!nodeExists) {
  // If the node does not exist, remove the overlay's topmost parent
  overlay.closest('div[id^="overlay-"]').remove();
  }
  });
  }

  // openFileInNewWindow(url: string) {
  //   window.open(url, '_blank');
  // }

  openImageLayoutDialog(imageDetail: string) {
    if (this.node_type !== 'menu_item') {
      const dialogRef = this.dialog.open(ImageLayoutComponent, {
        data: imageDetail,
        maxWidth: '850px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result?.data) {
          imageDetail['lineImageMap'] = result.data;
          imageDetail['layoutId'] = result.layoutId;
          imageDetail['layoutAreaOptions'] = result.layoutAreaOptions;
        }
      });
    } else {
      window.open(imageDetail?.['image_url'], '_blank');
    }
  }

  flowbuilderList(arg) {
    this.flowBuilderService.listNewFlow(arg).subscribe(data => {
      if (data) {
        this.flowbuilderListData = data['flows'] ? data['flows'] : [];
        this.flowbuilderListDatalist = this.flowbuilderListData.filter(element =>
        element.name.toLowerCase() !== this.flowName.toLowerCase() && element.status !== false);
      }
      this.NameConfig = {
        displayKey: 'name' ,
        search: true,
        height: '175px',
        placeholder: 'Select',
        limitTo: this.flowbuilderListDatalist.length,
        moreText: 'more',
        noResultsFound: 'No results found!',
        searchPlaceholder: 'Search',
        searchOnKey: 'name',
      };
    });
  }
  simulateTapOnBlankSpace() {
    const container = document.getElementById('cy');
    if (!container) { return; } // Guard in case the container isn't found

    // Create a new mouse event
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: container.offsetWidth / 2, // Middle of the container
      clientY: container.offsetHeight / 2 // Middle of the container
    });

    // Dispatch the event to the container
    container.dispatchEvent(event);
  }
  checkingSort() {
  if (this.totalDuplicates.length > 0 ) {
  return;
  }
  if (!this.connectedEdgesForsort) {
  return;
  }
  this.totalArrayFromParentDetails = [];
    this.cy.nodes().forEach(node => {

     if (this.uniqueArray.includes(node.data().id)) {
    this.totalArrayFromParentDetails.push(node.data());
     }
    });
    this.sortCheckingIndicator = false;
    this.totalArrayFromParentDetails.forEach(element => {
    if (this.sortNo === element.sortNumber && this.sortNo !== null) {
      if (this.selectedNodesId.id !== element.id) {
        this.sortCheckingIndicator = true;
      }

    }

  });
}
checkingSortCommon() {
  if (!this.connectedEdgesForsort) {
    return;
  }
  if (this.totalDuplicates.length === 0) {
    return;
    }
    this.totalArrayCommonParentDetails = [];
    this.cy.nodes().forEach(node => {

      if (this.flatArrayOfTotal.includes(node.data().id)) {
     this.totalArrayCommonParentDetails.push(node.data());
      }
     });
     this.sortCheckingIndicatorCommon = false;
    this.totalArrayCommonParentDetails.forEach(element => {
    if (this.sortNo === element.sortNumber) {
      if (this.selectedNodesId.id !== element.id) {

     this.sortCheckingIndicatorCommon = true;
      }
    }

  });

}
ensureInteger(event: any): void {
  const value = parseInt(event.target.value, 10);

  if (!Number.isInteger(value) || value < 1) {
    event.target.value = value < 1 ? '' : Math.floor(value); // Reset to empty if less than 1, else round down
    this.sortNo = event.target.value; // Ensure model is updated
  }
}
CheckBoxvalue(res) {

  this.Node_auth = res.target.checked;
}
setRootNodePosition() {
  const query = 'root';
  this.cy?.nodes()?.forEach((node) => {
    node.style({
      'text-background-color': '',
      'text-background-opacity': 0,
      'text-background-padding': 0,
      'text-background-shape': ''
    });
  });
 this.Query = query;
 this.foundNode = this.cy?.nodes()?.filter(node => node.data('node_type')?.toLowerCase().includes(query.toLowerCase()));
  if (this.foundNode?.nonempty()) {
    if (query.length >= 1) {
    this.numberOfNodes = true;
    this.cy?.animate({
      center: { eles: this.foundNode[0]  },
      // zoom: 1
    }, {
      duration: 500
    });
  } else {
    this.numberOfNodes = false;
  }
}
}
  createChangeIndicator(node) {
    const valid = this.checkDeleteIndicator(node?.data());
    if (valid) {
      const changeId = `indicator-${node.id()}`;
      const renderedPosition = node.renderedPosition();
      const width = node.renderedWidth();
      let indicator = document.getElementById(changeId);
      if (!indicator && valid) {
        indicator = document.createElement('div');
        indicator.id = changeId;
        const labeldiv = document.createElement('div');
        indicator.appendChild(labeldiv);
        indicator.style.position = 'absolute';
        this.cy.container().appendChild(indicator);
        const imagediv = document.createElement('div');
        imagediv.style.margin = '10px 0px 0px 0px';
        imagediv.classList.add('node-indicator');
        indicator.appendChild(imagediv);
      }
      if (this.cy.zoom() < 0.55 && this.cy.zoom() > 0.46) {
        indicator.style.left = `${renderedPosition.x + (width - 50) / 2}px`;
        indicator.style.top = `${(renderedPosition.y - 12)}px`;
        indicator.style.transform = `scale(${this.cy.zoom()})`;
      } else if (this.cy.zoom() < 0.45) {
        indicator.style.left = `${renderedPosition.x + (width - 30) / 2}px`;
        indicator.style.top = `${(renderedPosition.y - 16)}px`;
        indicator.style.transform = `scale(${this.cy.zoom()})`;
      } else if (this.cy.zoom() > 1.25) {
        indicator.style.left = `${renderedPosition.x + (width - 110) / 2}px`;
        indicator.style.top = `${(renderedPosition.y - 20)}px`;
        indicator.style.transform = `scale(${this.cy.zoom()})`;
      } else {
        indicator.style.left = `${renderedPosition.x + (width - 80) / 2}px`;
        indicator.style.top = `${(renderedPosition.y - 15)}px`;
        indicator.style.transform = `scale(${this.cy.zoom()})`;
      }
    }
  }
  checkDeleteIndicator(node) {
    if (node?.removed_data) {
      const removedData = node?.removed_data;
      if (removedData?.hasAttachment || removedData?.hasSubmitAction || removedData?.hasVariable ||  removedData?.hasUserAttribute) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  setMouseHoverMessage(node) {
    const removedData = node?.data()?.removed_data ?? {};
    if (!removedData.hasAttachment &&  !removedData.hasSubmitAction && !removedData.hasVariable && !removedData.hasUserAttribute) {
      return '';
    }
    let message = '';
    const messageArray = {hasAttachment: 'an attachment', hasVariable: 'a variable', hasSubmitAction: 'a submit action', hasUserAttribute: 'a user attribute'};
    let i = 0;
    for (const data in removedData) {
      if (removedData[data]) {
      i++;
      if ((i === Object.keys(removedData).length) && Object.keys(removedData).length > 1) {
        message += ' and ' + messageArray[data];
      } else {
        if (message !== '') {
          message += ', ';
        }
        message += messageArray[data];
      }
    }
    }
    message = message.charAt(0).toUpperCase() + message.slice(1);
    message = message.replace(/(^,)|(,$)/g, '');
    const msg = `${message} was there in the exported flow`;
    return `<div style="color:red;">${msg}</div><br>`;
  }
  setExportDeleteDataNode() {
    const fn = this;
    this.flows.forEach(function(targetNode) {
      const valid = fn.checkDeleteIndicator(targetNode);
      if (!valid) {
      const indicator = document.getElementById(`indicator-${targetNode.id}`);
      if (indicator) {
        indicator.parentNode.removeChild(indicator);
      }
    }
    });
  }
  checkForDeletedData(node) {
    const deletedData = {};
    if (node?.data('image_details')?.length) {
      deletedData['hasAttachment'] = true;
    }
    if ((node?.data('variableArray')?.length && node?.data('label')?.match(/\{(.*?)\}/g)?.length > 0)  || (node?.data('condition_check') === 'api_response' && node?.data('condition_id'))) { // NOSONAR
      deletedData['hasVariable'] = true;
    }
    if (node?.data('submit_action') && Object.keys(node?.data('submit_action'))?.length) {
      deletedData['hasSubmitAction'] = true;
    }
    if (node?.data('user_attribute') && Object.keys(node?.data('user_attribute'))?.length) {
      deletedData['hasUserAttribute'] = true;
    }
    return deletedData;
  }
  handelMultipleChildDeletion(node) {
    if (node.length > 1) {
      node.forEach(item => {
        if (item.data().node_type !== 'root') {
          const nodeId = item.id();
          const overlay = document.getElementById(`overlay-${nodeId}`);
          if (overlay) {
            overlay.parentNode.removeChild(overlay);
          }
          const indicator = document.getElementById(`indicator-${nodeId}`);
          if (indicator) {
            indicator.parentNode.removeChild(indicator);
          }
          const menuhide = document.getElementById(`menuhide-${nodeId}`);
          if (menuhide) {
            menuhide.parentNode.removeChild(menuhide);
          }
        }
    });
    }
  }
  changeSelectedCurrency(country) {
    this.paymentSettings.currencyCode = country.currency;
  }
  handleCreatePayemnt(arg) {
    if (this.updateactiontype === 'payment') {
      if (this.paymentSettings) {
        arg['submit_action'] = {
          'action': this.updateactiontype,
          'paymentSettings': this.paymentSettings
        };
      }
    }
  }
  createMenuHideIcon(node) {
      const changeId = `menuhide-${node.id()}`;
      const renderedPosition = node.renderedPosition();
      const width = node.renderedWidth();
      let menuhide = document.getElementById(changeId);
      if (!menuhide) {
        menuhide = document.createElement('div');
        menuhide.id = changeId;
        const labeldiv = document.createElement('div');
        menuhide.appendChild(labeldiv);
        menuhide.style.position = 'absolute';
        this.cy.container().appendChild(menuhide);
        const image1 = new Image();
        image1.src = 'assets/images/eye-slash-solid.svg';
        image1.classList.add('node-image');
        image1.dataset.nodeId = node.id();
        image1.style.width = '14px';
        const imagediv = document.createElement('div');
        imagediv.style.margin = '10px 0px 0px 0px';
        imagediv.classList.add('node-menuhide');
        imagediv.appendChild(image1);
        menuhide.appendChild(imagediv);
      }
      menuhide.style.top = `${(renderedPosition.y - 17)}px`;
      menuhide.style.transform = `scale(${this.cy.zoom()})`;

      if (this.cy.zoom() < 0.39) {
        menuhide.style.left = `${renderedPosition.x + (width - 140) / 2}px`;
        menuhide.style.top = `${(renderedPosition.y - 16)}px`;
      } else if (this.cy.zoom() < 0.47) {
        menuhide.style.left = `${renderedPosition.x + (width - 160) / 2}px`;
        menuhide.style.top = `${(renderedPosition.y - 16)}px`;
      } else if (this.cy.zoom() < 0.56 && this.cy.zoom() > 0.47) {
        menuhide.style.left = `${renderedPosition.x + (width - 190) / 2}px`;
        menuhide.style.top = `${(renderedPosition.y - 16)}px`;
      } else if (this.cy.zoom() > 0.56 && this.cy.zoom() < 0.8) {
        menuhide.style.left = `${renderedPosition.x + (width - 220) / 2}px`;
      } else if (this.cy.zoom() === 0.8) {
        menuhide.style.left = `${renderedPosition.x + (width - 260) / 2}px`;
      } else if (this.cy.zoom() > 0.8 && this.cy.zoom() < 1) {
        menuhide.style.left = `${renderedPosition.x + (width - 310) / 2}px`;
      } else if (this.cy.zoom() > 1.00 && this.cy.zoom() < 1.33) {
        menuhide.style.left = `${renderedPosition.x + (width - 360) / 2}px`;
        menuhide.style.top = `${(renderedPosition.y - 20)}px`;
      } else if (this.cy.zoom() > 1.33 && this.cy.zoom() <= 1.44) {
        menuhide.style.left = `${renderedPosition.x + (width - 430) / 2}px`;
        menuhide.style.top = `${(renderedPosition.y - 20)}px`;
      }  else if (this.cy.zoom() > 1.44 && this.cy.zoom() < 1.67) {
        menuhide.style.left = `${renderedPosition.x + (width - 520) / 2}px`;
        menuhide.style.top = `${(renderedPosition.y - 20)}px`;
      } else {
        menuhide.style.left = `${renderedPosition.x + (width - 620) / 2}px`;
        menuhide.style.top = `${(renderedPosition.y - 20)}px`;
      }
  }
  setHidemenuDeleteData() {
    this.flows.forEach(function(targetNode) {
      const indicator = document.getElementById(`menuhide-${targetNode.id}`);
      if (indicator && !targetNode.hideMenu) {
        indicator.parentNode.removeChild(indicator);
      }
    });
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        document.getElementById('dropdownMenuLink')?.click();
    }
}
handleFileUpload(data: any, filename) {
  // Update data in the parent component
  data.filename = filename;
  this.flowBuilderService.updateData({file: data, type: 'response',});
}
}
