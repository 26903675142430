export const stylesheetPreviewObject = [
  {
    selector: 'node',
    style: {
      'shape': 'roundrectangle',

    }
  },



  {
    selector: 'node[node_type="root"]',
    style: {
      'background-color': '#333333',
      'background-opacity': 1,

      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '5%',
      'background-position-y': '50%',
      'color': '#ffffff'
    }
  },


  {
    selector: 'node[node_type="slot_question"]',
    style: {
      'background-opacity': 1,
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '5%',
      'background-position-y': '50%',
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },



  {
    selector: 'node[node_type="menu_question"]',
    style: {
      'background-image': 'url("assets/images/flow-chart1.png")',
      'background-opacity': 1,

      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '7%',
      'background-position-y': '50%',
      'text-margin-x': 5,
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },



  {
    selector: 'node[node_type="menu_item"]',
    style: {
      'background-image': 'url("assets/images/rect.png")',
      'background-opacity': 1,
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '7%',
      'background-position-y': '50%',
      'text-margin-x': 5,
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },

  {
    selector: 'node[node_type="conditional_response"]',
    style: {
      'background-image': 'url("assets/images/conditional1.png")',
      'background-opacity': 1,
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '7%',
      'background-position-y': '50%',
      'text-margin-x': 5,
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },

  {
    selector: 'node[node_type="condition"]',
    style: {
      'background-image': 'url("assets/images/condition1.png")',
      'background-opacity': 1,
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '7%',
      'background-position-y': '50%',
      'text-margin-x': 5,
      'background-color': '#777777',
      'color': '#ffffff'
    }
  },

  {
    selector: 'node[node_type="end_response"]',
    style: {
      'background-opacity': 1,
      'text-valign': 'center',
      'text-halign': 'center',
      'background-position-x': '5%',
      'background-position-y': '50%',
      'background-color': '#fb7171',
      'color': '#ffffff'
    }
  },

  {
    selector: ':active',
    style: {
      'overlay-color': 'green',
      'overlay-padding': 0,
      'overlay-opacity': 0.10
    }
  },

    {
        selector: 'node:selected',
        style: {
            'background-color': '#afafaf',
        }
    },

  {
    selector: 'node[type= "bendPoint"]',
    style: {
      'width': '1.00001px',
      'height': '1.00001px'
    }
  },
{

    selector: 'node[type="text"]',
    style: {
      'content': 'data(label)',
      'label': function(node) {
        const dummyLabel = node.data('dummyLabel') || ''; // Simplified fallback to empty string
        const flowNodeUsageCount = node.data('flowNodeUsageCount') || ''; // Default to empty string if undefined// Ensure that the spaces array length is not negative
        const spacesLength = Math.max(20 - dummyLabel.length, 0);
        const spaces = new Array(spacesLength).join(' ');
         if ( flowNodeUsageCount ) {
          return dummyLabel.length > 10 ? `${dummyLabel.substring(0, 10)}...\t${spaces}(${flowNodeUsageCount})` : `${dummyLabel}\t\t${spaces}(${flowNodeUsageCount})`;
         } else {
          return dummyLabel.length > 10 ? `${dummyLabel.substring(0, 10)}...\t${spaces}${flowNodeUsageCount}` : `${dummyLabel}\t\t${spaces}${flowNodeUsageCount}`;
        }
      },
      'width': '210px',
      'height': '50px',
      'text-wrap': 'wrap',
      'text-max-width': 150, // Adjust based on your needs
      'font-size': 16,
      'text-valign': 'center',
      'text-halign': 'center',
    }
  },
  {
  selector: 'edge[type = "bendPoint" ]',
    style: {
      'width': 1,
      'target-arrow-shape': 'none',
      'opacity': 1
    }
  },


  {
    selector: 'edge',
    style: {
      'curve-style': 'taxi',
       'segment-distances': [20, -20],
       'segment-weights': [0.25, 0.75],
      'control-point-distances': [30, -30],
      'control-point-weights': [0.5, 0.5],
      'taxi-direction': 'horizontal',
      'taxi-turn': 50,
      'taxi-turn-min-distance': 50,
      'target-arrow-shape': 'triangle',
      'target-arrow-color': '#6f6e6e',
      'line-style': 'dashed',
      'line-color': '#6f6e6e',
      'width': 2,
      'arrow-scale': 1.5,

    },
  },

  {
    selector: '.edge-highlighted',
    style: {
      'opacity': '1',
      'line-color': '#386BA7',
      'width': '4px'
    }
}
];
